import TireResult from './TireResult';
import './TireResultCombo.css';
import * as constants from '../../../../../../constants';

function TireResultCombo(props) {

    var front = constants.EMPTY;
    var back = constants.EMPTY;
    var isFirstLine = props.firstLine;
    var isCombo = (props.jsonData.back);

    var hasFront = false;
    var hasBack = false;

    if (props.jsonData.front) {
        front = (
            <TireResult isCombo={isCombo} isFront={true} key={props.keyValue + '_front'} maskPrice={props.maskPrice} hidePrice={props.hidePrice} firstLine={isFirstLine} jsonData={props.jsonData.front} onTextNotFound={props.onTextNotFound} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} securityInfo={props.securityInfo} />
        );
        isFirstLine = false;
        hasFront = true;
    }

    if (props.jsonData.back) {
        back = (
            <TireResult isCombo={isCombo} isFront={false} key={props.keyValue + '_back'} maskPrice={props.maskPrice} hidePrice={props.hidePrice} firstLine={isFirstLine} jsonData={props.jsonData.back} onTextNotFound={props.onTextNotFound} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} securityInfo={props.securityInfo} />
        );
        hasBack = true;
    }

    var className = constants.EMPTY;
    if (isCombo && hasFront && hasBack) {
        className = 'tire-result-combo-border';
    }

    return (
        <>
            <div className={className}>
                {front}
                {back}
            </div>
        </>
    );
}

export default TireResultCombo;