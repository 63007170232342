import * as constants from '../constants';

class BackendUser {
  userMssql = null;

  constructor() {
    this.userMssql = new User_mssql();
  }

  getUserMssql() {
    return this.userMssql;
  }

  update(mapOfFields) {
    if (mapOfFields.has(constants.MAPPING_USER_USER_CODE)) {
      this.userMssql.code = mapOfFields.get(constants.MAPPING_USER_USER_CODE);
    }

    if (mapOfFields.has(constants.MAPPING_USER_USER_NAME)) {
      this.userMssql.nom = mapOfFields.get(constants.MAPPING_USER_USER_NAME);
    }

    if (mapOfFields.has(constants.MAPPING_USER_EMAIL)) {
      this.userMssql.email = mapOfFields.get(constants.MAPPING_USER_EMAIL);
    }

    if (mapOfFields.has(constants.MAPPING_USER_USERTYPE)) {
      this.userMssql.user_type = Number(mapOfFields.get(constants.MAPPING_USER_USERTYPE));
    }

    if (mapOfFields.has(constants.MAPPING_USER_PASSWORD)) {
      this.userMssql.passe = mapOfFields.get(constants.MAPPING_USER_PASSWORD);
    }

    if (mapOfFields.has(constants.MAPPING_USER_CR0107)) {
      this.userMssql.cr0107 = mapOfFields.get(constants.MAPPING_USER_CR0107);
    }

    if (mapOfFields.has(constants.MAPPING_USER_AUTH_CANNOTPASSORDER)) {
      if (mapOfFields.get(constants.MAPPING_USER_AUTH_CANNOTPASSORDER)) {
        this.userMssql.acces_commander = 'N'
      } else {
        this.userMssql.acces_commander = 'O'
      }
    }

    if (mapOfFields.has(constants.MAPPING_USER_AUTH_CANSEECOST)) {
      if (mapOfFields.get(constants.MAPPING_USER_AUTH_CANSEECOST)) {
        this.userMssql.acces_estime_seul = 'N'
      } else {
        this.userMssql.acces_estime_seul = 'O'
      }
    }

    if (mapOfFields.has(constants.MAPPING_USER_AUTH_STORAGE_ONLY)) {
      if (mapOfFields.get(constants.MAPPING_USER_AUTH_STORAGE_ONLY)) {
        this.userMssql.acces_prodved_seul = 'O'
      } else {
        this.userMssql.acces_prodved_seul = 'N'
      }
    }

    if (mapOfFields.has(constants.MAPPING_USER_AUTH_TIRE_ONLY)) {
      if (mapOfFields.get(constants.MAPPING_USER_AUTH_TIRE_ONLY)) {
        this.userMssql.acces_multicompte = '0'
      } else {
        this.userMssql.acces_multicompte = '1'
      }
    }

  }

  updateFromJson(backendJsonObj) {
    this.userMssql = backendJsonObj;
  }

  getMap() {
    const allFields = new Map();

    allFields.set(constants.MAPPING_USER_USER_CODE, this.userMssql.code);
    allFields.set(constants.MAPPING_USER_USER_NAME, this.userMssql.nom);
    allFields.set(constants.MAPPING_USER_EMAIL, this.userMssql.email);
    allFields.set(constants.MAPPING_USER_PASSWORD, this.userMssql.passe);
    allFields.set(constants.MAPPING_USER_USERTYPE, this.userMssql.user_type);

    if (this.userMssql.acces_commander === 'O') {
      allFields.set(constants.MAPPING_USER_AUTH_CANNOTPASSORDER, false);
    } else {
      allFields.set(constants.MAPPING_USER_AUTH_CANNOTPASSORDER, true);
    }

    if (this.userMssql.acces_estime_seul === 'O') {
      allFields.set(constants.MAPPING_USER_AUTH_CANSEECOST, false);
    } else {
      allFields.set(constants.MAPPING_USER_AUTH_CANSEECOST, true);
    }

    if (this.userMssql.acces_prodved_seul === 'O') {
      allFields.set(constants.MAPPING_USER_AUTH_STORAGE_ONLY, true);
    } else {
      allFields.set(constants.MAPPING_USER_AUTH_STORAGE_ONLY, false);
    }

    if (this.userMssql.acces_multicompte === '0') {
      allFields.set(constants.MAPPING_USER_AUTH_TIRE_ONLY, true);
    } else {
      allFields.set(constants.MAPPING_USER_AUTH_TIRE_ONLY, false);
    }

    return allFields
  }

  #jsonUserToMap(jsonUser) {
    const allFields = new Map();
    //allFields.set(constants.MAPPING_USER_ACCOUNT_NUMBER, jsonUser.userInfo.noClient);
    allFields.set(constants.MAPPING_USER_USER_CODE, jsonUser.userMssql.code);
    allFields.set(constants.MAPPING_USER_USER_NAME, jsonUser.userMssql.nom);
    allFields.set(constants.MAPPING_USER_EMAIL, jsonUser.userMssql.email);
    allFields.set(constants.MAPPING_USER_PASSWORD, jsonUser.userMssql.passe);
    // allFields.set(constants.MAPPING_USER_AUTH_CANSEECOST, 'O');         //Need Olivier Info TODO
    //allFields.set(constants.MAPPING_USER_AUTH_CANNOTPASSORDER, 'N');    //Need Olivier Info TODO
    // allFields.set(constants.MAPPING_USER_AUTH_STORAGE_ONLY, 'N');       //Need Olivier Info TODO
    // allFields.set(constants.MAPPING_USER_AUTH_TIRE_ONLY, 'N');          //Need Olivier Info TODO
    allFields.set(constants.MAPPING_USER_USERTYPE, jsonUser.userMssql.user_type);      //Need Olivier Info TODO


    return allFields
  }

  #mapToJsonUser(theMap, jsonUser) {

    jsonUser.userInfo.noClient = theMap.get(constants.MAPPING_USER_ACCOUNT_NUMBER);
    jsonUser.userMssql.code = theMap.get(constants.MAPPING_USER_USER_CODE);
    jsonUser.userMssql.nom = theMap.get(constants.MAPPING_USER_USER_NAME);
    jsonUser.userMssql.email = theMap.get(constants.MAPPING_USER_EMAIL);
    jsonUser.userMssql.passe = theMap.get(constants.MAPPING_USER_PASSWORD);
    jsonUser.userMssql.user_type = theMap.get(constants.MAPPING_USER_USERTYPE);
    //allFields.get(constants.MAPPING_USER_AUTH_CANSEECOST, 'O');         //Need Olivier Info TODO
    //allFields.get(constants.MAPPING_USER_AUTH_CANNOTPASSORDER, 'N');    //Need Olivier Info TODO
    //allFields.get(constants.MAPPING_USER_AUTH_STORAGE_ONLY, 'N');       //Need Olivier Info TODO
    //allFields.get(constants.MAPPING_USER_AUTH_TIRE_ONLY, 'N');          //Need Olivier Info TODO
    //allFields.get(constants.MAPPING_USER_USERTYPE, 'Utilisateur');      //Need Olivier Info TODO

    return jsonUser;
  }

}

class User_mssql {
  trusager = null; //private Integer trusager; // User ID in Contal Database
  cr0107 = null; //private int cr0107; // Foreign key with User_Info
  nom = null; //private String nom;
  cie = null; //private int cie;
  user_type = 0;
  code = null; //private String code; // True Username in Contal Database
  //blocage = null; //private String blocage;
  acces_estime_seul = null; //private String acces_estime_seul;
  acces_prodved_seul = null; //private String acces_prodved_seul;
  cr0107reg = 0; //private String cr0107reg;
  acces_multicompte = 0; //private String acces_multicompte;
  inventaire = 'N'; //private String inventaire;
  langue = 'F'; //private String langue;
  email = null; //private String email;

  passe = null; //private String passe;
  databas1 = 0; //private int databas1 = 0;
  division = 0; //private int division = 0;

  date_motpasse = Date.now(); //private Date date_motpasse;
  acces_commander = 'N'; //private String acces_commander = "N";
}

export default BackendUser;
