import { JSON_SECTION_TIRES, loadSection, getTextByKey } from '../../../../services/languageService';
import * as constants from '../../../../constants';
import Publicity from './Publicity/Publicity';
import TabSpec from './Tabs/TabSpec/TabSpec';
import TabVeh from './Tabs/TabVeh/TabVeh';
import TabWheel from './Tabs/TabWheel/TabWheel';
import './TireSearchPage.css';
import { useState } from 'react';

function TireSearchPage(props) {

    const [showElements, setShowElements] = useState(props.showElements);

    var isMinimalDisplay = (props.displayType === constants.TIRE_SEARCH_DISPLAY_MINIMAL);

    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    function tabChange(tabIndex) {
        if (tabIndex !== props.tabSelected) {
            props.tabChange(tabIndex);
            setShowElements(true);
        }
    }

    if (props.texts) {
        var txtSection = loadSection(JSON_SECTION_TIRES, props.texts, props.securityInfo);
        var tabToShow;
        var specGridCss = ["tiresearch-grid-item-not-selected tk-cursor-pointer", "tiresearch-grid-item-centerized tiresearch-gridNotSelected", "tabSpecImgNotSel"];
        var vehGridCss = ["tiresearch-grid-item-not-selected tk-cursor-pointer", "tiresearch-grid-item-centerized tiresearch-gridNotSelected", "tabVehImgNotSel"];
        var wheelGridCss = ["tiresearch-grid-item-not-selected tk-cursor-pointer", "tiresearch-grid-item-centerized tiresearch-gridNotSelected", "tabWheelImgNotSel"];

        if (props.tabSelected === constants.TAB_TIRE_SEARCH_SPEC) {
            specGridCss = ["tiresearch-grid-item-selected", "tiresearch-grid-item-centerized tiresearch-gridSelected", "tabSpecImgSel"];
            tabToShow = <TabSpec jest={props.jest} moveToTop={props.moveToTop} partnerList={props.partnerList} tireSearchOptions={props.tireSearchOptions} tireSearchFunction={props.tireSearchFunction} goToRoute={props.goToRoute} displayType={props.displayType} securityInfo={props.securityInfo} selectedType={props.selectedType} displayErrorMessage={props.displayErrorMessage} onTextNotFound={props.onTextNotFound} texts={props.texts} />;
        } else if (props.tabSelected === constants.TAB_TIRE_SEARCH_VEH) {
            vehGridCss = ["tiresearch-grid-item-selected", "tiresearch-grid-item-centerized tiresearch-gridSelected", "tabVehImgSel"];
            tabToShow = <TabVeh moveToTop={props.moveToTop} tireSearchOptions={props.tireSearchOptions} tireSearchFunction={props.tireSearchFunction} goToRoute={props.goToRoute} displayType={props.displayType} securityInfo={props.securityInfo} selectedType={props.selectedType} displayErrorMessage={props.displayErrorMessage} texts={props.texts} onTextNotFound={props.onTextNotFound} />;
        } else if (props.tabSelected === constants.TAB_TIRE_SEARCH_WHEEL) {
            wheelGridCss = ["tiresearch-grid-item-selected", "tiresearch-grid-item-centerized tiresearch-gridSelected", "tabWheelImgSel"];
            tabToShow = <TabWheel />;
        }

        if (isMinimalDisplay) {
            return (
                <>
                    <div className='section no-title tiresearch-no-padding-bottom'>
                        <div className="tk-grid tk-bgcolor-white tiresearch-grid-1-column">
                            <div>
                                <div className="tk-grid tk-bgcolor-white tiresearch-grid-1-column">
                                    <div className='frb-bgcolor-gray'>
                                        <div className="tk-grid tk-bgcolor-white tiresearch-grid-4-columns">

                                            <div className={specGridCss[0]} onClick={() => tabChange(constants.TAB_TIRE_SEARCH_SPEC)}>
                                                <div className="tk-grid tk-bgcolor-white tk-height-50 tiresearch-grid-2-columns">
                                                    <div className={specGridCss[1]}>
                                                        <div className='tiresearch-grid-item-image-element'>
                                                            <img width='30px' alt="" src={getText(specGridCss[2])} />
                                                        </div>
                                                    </div>
                                                    <div className={specGridCss[1]}>
                                                        <div className='tiresearch-grid-item-image-element'>{getText('tabSpec')}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={vehGridCss[0]} onClick={() => tabChange(constants.TAB_TIRE_SEARCH_VEH)}>
                                                <div className="tk-grid tk-bgcolor-white tk-height-50 tiresearch-grid-2-columns_2">
                                                    <div className={vehGridCss[1]}>
                                                        <div className='tiresearch-grid-item-image-element'>
                                                            <img width='30px' alt="" src={getText(vehGridCss[2])} />
                                                        </div>
                                                    </div>
                                                    <div className={vehGridCss[1]}>
                                                        <div className='tiresearch-grid-item-image-element'>{getText('tabVeh')}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={wheelGridCss[0]} onClick={() => tabChange(constants.TAB_TIRE_SEARCH_WHEEL)}>
                                                <div className="tk-grid tk-bgcolor-white tk-height-50 tiresearch-grid-2-columns_3">
                                                    <div className={wheelGridCss[1]}>
                                                        <div className='tiresearch-grid-item-image-element'>
                                                            <img width='30px' alt="" src={getText(wheelGridCss[2])} />
                                                        </div>
                                                    </div>
                                                    <div className={wheelGridCss[1]}>
                                                        <div className='tiresearch-grid-item-image-element'>{getText('tabWheel')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='tiresearch-grid-item-search-body'>
                                        <div className='tiresearch-padding-section'>
                                            {showElements &&
                                                tabToShow
                                            }
                                            {!showElements &&
                                                <div onClick={() => setShowElements(true)} className='tk-cursor-pointer'><span className="button tiresearch-button-edit">{getText('buttonEditSearch')}</span></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div id="spinner" className="tk-display-none">
                        <div className="tk-min-height-20 tk-height-20" />
                        <div className="tk-text-center tk-pad-top-50  tk-pad-bot-10"><img width="30px" alt="" src='/frb/images/common/loading.gif' /><br />
                            <label className="rl-label">Recherche en cours...</label>
                        </div>
                        <div id='results'></div>
                    </div>
                    <div className='section no-title'>
                        <div className="tk-grid tk-bgcolor-white tiresearch-grid-3-columns">
                            <div>
                                <div className="tk-grid tk-bgcolor-white tiresearch-grid-1-column_2">
                                    <div className='frb-bgcolor-gray'>
                                        <div className="tk-grid tk-bgcolor-white tiresearch-grid-4-columns">

                                            <div className={specGridCss[0]} onClick={() => tabChange(constants.TAB_TIRE_SEARCH_SPEC)}>
                                                <div className="tk-grid tk-bgcolor-white tk-height-50 tiresearch-grid-2-columns">
                                                    <div className={specGridCss[1]}>
                                                        <div className='tiresearch-grid-item-image-element'>
                                                            <img width='30px' alt="" src={getText(specGridCss[2])} />
                                                        </div>
                                                    </div>
                                                    <div className={specGridCss[1]}>
                                                        <div className='tiresearch-grid-item-image-element'>{getText('tabSpec')}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={vehGridCss[0]} onClick={() => tabChange(constants.TAB_TIRE_SEARCH_VEH)}>
                                                <div className="tk-grid tk-bgcolor-white tk-height-50 tiresearch-grid-2-columns_2">
                                                    <div className={vehGridCss[1]}>
                                                        <div className='tiresearch-grid-item-image-element'>
                                                            <img width='30px' alt="" src={getText(vehGridCss[2])} />
                                                        </div>
                                                    </div>
                                                    <div className={vehGridCss[1]}>
                                                        <div className='tiresearch-grid-item-image-element'>{getText('tabVeh')}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={wheelGridCss[0]} onClick={() => tabChange(constants.TAB_TIRE_SEARCH_WHEEL)}>
                                                <div className="tk-grid tk-bgcolor-white tk-height-50 tiresearch-grid-2-columns_3">
                                                    <div className={wheelGridCss[1]}>
                                                        <div className='tiresearch-grid-item-image-element'>
                                                            <img width='30px' alt="" src={getText(wheelGridCss[2])} />
                                                        </div>
                                                    </div>
                                                    <div className={wheelGridCss[1]}>
                                                        <div className='tiresearch-grid-item-image-element'>{getText('tabWheel')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='tiresearch-grid-item-search-body'>
                                        <div className='tiresearch-padding-section'>
                                            {tabToShow}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tiresearch-spacer"></div>
                            <div className="tk-pad-top-50">
                                <Publicity securityInfo={props.securityInfo} texts={props.texts} />
                            </div>

                        </div>
                    </div>
                </>
            );
        }
    } else {
        return (<></>);
    }
}

export default TireSearchPage;