import { JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_RESULT_POPUP, loadSubSection, getTextByKey } from '../../../../../services/languageService';
import './ResultPopup.css';

function ResultPopup(props) {

    var theTexts = props.texts;

    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    if (theTexts) {

        var txtSection = loadSubSection(JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_RESULT_POPUP, theTexts, props.securityInfo);
        return (
            <div className="tire-result-popup">
                <div className="tire-result-popup-box frb-bgcolor-orange frb-font-medium-large frb-color-white frb-font-light-bold tk-display-block">
                    <div className='tk-grid tire-result-popup-detail-content frb-font-medium frb-font-no-bold'>
                        <div className='tk-grid-col-span-4'>1 unité: </div>

                        <div className='tk-grid-col-span-2'></div>
                        <div className='tk-pad-bot-6 tk-text-center'>Acier</div>
                        <div className='tk-pad-bot-6 tk-text-center'>Alliage</div>

                        <div className='tk-pad-bot-6 tk-grid-col-span-2 tk-text-right tk-pad-r-10'> Montage/équilibrage: </div>
                        <div className='tk-pad-bot-6 tk-text-right frb-color-gray'>10,00$</div>
                        <div className='tk-pad-bot-6 tk-text-right frb-color-gray'>5,00$</div>

                        <div className='tk-pad-bot-6 tk-grid-col-span-2 tk-text-right tk-pad-r-10'> Valves régulières:</div>
                        <div className='tk-pad-bot-6 tk-text-right frb-color-gray'>0,00$</div>
                        <div className='tk-pad-bot-6 tk-text-right frb-color-gray'>0,00$</div>

                        <div className='tk-pad-bot-6 tk-grid-col-span-2 tk-text-right tk-pad-r-10'> Azote:</div>
                        <div className='tk-pad-bot-6 tk-text-right frb-color-gray'>0,00$</div>
                        <div className='tk-pad-bot-6 tk-text-right frb-color-gray'>0,00$</div>

                        <div className='tk-pad-bot-6 tk-grid-col-span-2 tk-text-right tk-pad-r-10'> Taxe recyclage:</div>
                        <div className='tk-pad-bot-6 tk-text-right frb-color-gray'>10,00$</div>
                        <div className='tk-pad-bot-6 tk-text-right frb-color-gray'>5,00$</div>

                        <div className='frb-color-orange tk-grid-col-span-2 tk-text-right tk-pad-r-10'> Total (incluant les taxes): </div>
                        <div className='frb-color-orange tk-text-right'>20,00$</div>
                        <div className='frb-color-orange tk-text-right'>10,00$</div>

                        <div className='frb-color-orange tk-grid-col-span-4 tire-result-white-line'></div>

                        <div className='tk-grid-col-span-4'>4 unités: </div>

                        <div className='tk-grid-col-span-2'></div>
                        <div className='tk-pad-bot-6 tk-text-center'>Acier</div>
                        <div className='tk-pad-bot-6 tk-text-center'>Alliage</div>

                        <div className='tk-pad-bot-6 tk-grid-col-span-2 tk-text-right tk-pad-r-10'> Montage/équilibrage: </div>
                        <div className='tk-pad-bot-6 tk-text-right frb-color-gray'>10,00$</div>
                        <div className='tk-pad-bot-6 tk-text-right frb-color-gray'>5,00$</div>

                        <div className='tk-pad-bot-6 tk-grid-col-span-2 tk-text-right tk-pad-r-10'> Valves régulières:</div>
                        <div className='tk-pad-bot-6 tk-text-right frb-color-gray'>0,00$</div>
                        <div className='tk-pad-bot-6 tk-text-right frb-color-gray'>0,00$</div>

                        <div className='tk-pad-bot-6 tk-grid-col-span-2 tk-text-right tk-pad-r-10'> Azote:</div>
                        <div className='tk-pad-bot-6 tk-text-right frb-color-gray'>0,00$</div>
                        <div className='tk-pad-bot-6 tk-text-right frb-color-gray'>0,00$</div>

                        <div className='tk-pad-bot-6 tk-grid-col-span-2 tk-text-right tk-pad-r-10'> Taxe recyclage:</div>
                        <div className='tk-pad-bot-6 tk-text-right frb-color-gray'>10,00$</div>
                        <div className='tk-pad-bot-6 tk-text-right frb-color-gray'>5,00$</div>

                        <div className='frb-color-orange tk-grid-col-span-2 tk-text-right tk-pad-r-10'> Total (incluant les taxes): </div>
                        <div className='frb-color-orange tk-text-right'>20,00$</div>
                        <div className='frb-color-orange tk-text-right'>10,00$</div>


                    </div>
                    <div className="tire-result-popup-triangle" />
                </div>
            </div>
        );
    }
}

export default ResultPopup;