import './Publicity.css'
import { JSON_SECTION_TIRE_SEARCH_PAGE, loadSection } from '../../../../../services/languageService';
function Publicity(props) {

    if (props.texts) {
        var txtSection = loadSection(JSON_SECTION_TIRE_SEARCH_PAGE, props.texts, props.securityInfo);
        var pubs = [];

        if (txtSection.publicity) {

            for (let i = 0; i < txtSection.publicity.length; i++) {
                var key='pub_'+i;
                pubs.push(
                    <div key={key}>
                        <img width="500px" alt='' src={txtSection.publicity[i].image} />
                    </div>
                );
            }

            return (
                <>
                    <div className="tk-grid publicity-grid-1-column">
                        {pubs}
                    </div>
                </>
            );
        }
    }
}

export default Publicity;