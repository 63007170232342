import * as validationService from './validationService'
import * as constants from '../constants';

export const JSON_SECTION_MESSAGE_ZONE = 'MessageZone';
export const JSON_SECTION_S12_FOOTER = 'S12Footer';
export const JSON_SECTION_S13_COPYRIGHT= 'S13CopyRight';
export const JSON_SECTION_S1_MAIN_MENU='S1MainMenu';
export const JSON_SECTION_S1_TOP_MENU='S1TopMenu';
export const JSON_SECTION_S10_FEATURE_PRODUCTS='S10FeatureProducts';

export const JSON_SECTION_S11_NEWSLETTER= 'S11NewsLetter';
export const JSON_SECTION_S2_IMAGE_SLIDER= 'S2ImageSlider';
export const JSON_SECTION_S3_THREE_PRODUCTS_BAR= 'S3ThreeProductsBar';
export const JSON_SECTION_S4_PUBLICITY= 'S4Publicity';
export const JSON_SECTION_S5_CAREER= 'S5Career';
export const JSON_SECTION_S6_FOUR_FEATURES= 'S6FourFeatures';
export const JSON_SECTION_S7_THREE_COLOR_BOX= 'S7ThreeColorBox';
export const JSON_SECTION_S8_THREE_ELEMENTS= 'S8ThreeElements';
export const JSON_SECTION_S9_PROMO= 'S9Promo';
export const JSON_SECTION_JOIN_US= 'JoinUs';
export const JSON_SECTION_LOGIN_PAGE= 'LoginPage';
export const JSON_SECTION_OLDSITE_PAGE= 'OldSite';
export const JSON_SECTION_CONVERTER= 'Converter';
export const JSON_SECTION_TIRES= 'Tires';
export const JSON_SECTION_TIRE_FILTERS= 'TireFilters';
export const JSON_SECTION_TIRE_SEARCH_PAGE= 'TireSearchPage';
export const JSON_SECTION_BASE= 'Base';
export const JSON_SECTION_SEARCH_RESULT= 'SearchResult';
export const JSON_SECTION_MANAGE_USERS= 'ManageUsers';


export const JSON_SUB_SECTION_RESULT_POPUP='ResultPopup';
export const JSON_SUB_SECTION_RESULT='Result';
export const JSON_SUB_SECTION_HEADERS='Headers';
export const JSON_SUB_SECTION_BANNERS='Banners';

export const JSON_SUB_SECTION_SORT='Sort';
export const JSON_SUB_SECTION_TAB_SPEC='TabSpec';
export const JSON_SUB_SECTION_TAB_VEH='TabVeh';
export const JSON_SUB_SECTION_PROFILE='profile';
export const JSON_SUB_SECTION_ADD='add';
export const JSON_SUB_SECTION_EDIT='edit';
export const JSON_SUB_SECTION_LIST='list';

function keyNotFound(key, methodToCall){
    if (methodToCall !== undefined){
        methodToCall(key);
    }
}

export function getTextByKey(tree, key, keyNotFoundFunction) {
    var toReturn;

    if (validationService.isArray(key)) {
        var curNode = tree;
        for (let i = 0; i < key.length; i++) {
            curNode = curNode[key[i]]
            if (curNode === undefined){
                keyNotFound(key, keyNotFoundFunction);
                return constants.EMPTY;
            }
            toReturn = curNode;
        }


        if ( !(validationService.isArray(toReturn)) && !(validationService.validateStringNotEmpty(toReturn))) {

            if (validationService.isFunction(keyNotFoundFunction)) {
                keyNotFound(key, keyNotFoundFunction);
                return constants.EMPTY;
            }
        }
        return toReturn;
    }
    else {
        toReturn = tree[key];
        if (!(validationService.validateStringNotEmpty(toReturn))) {
            if (validationService.isFunction(keyNotFoundFunction)) {
                keyNotFound(key, keyNotFoundFunction);
                return constants.EMPTY;
            }
        }

        return toReturn;
    }
}

export function getGeneralTextByKey(tree, key, keyNotFoundFunction) {
    return getTextByKey(tree.CommonTexts, key, keyNotFoundFunction);
}

export function loadSection(section, texts, securityInfo) {
    return loadSubSection(section, null, texts, securityInfo);
}

export function loadSubSection(section, subSection, texts, securityInfo) {
    var jsObj = {};

    var theTexts = null;

    if (subSection) {
        theTexts = texts[section][subSection];
    } else {
        theTexts = texts[section];
    }

    //Not specific section for logged or not
    if (theTexts.all) {
        jsObj = theTexts.all;
        jsObj.CommonTexts = texts.General;
        return jsObj;
    }

    //Logged
    if (validationService.isLogged(securityInfo)) {
        jsObj = theTexts.logged;
        jsObj.CommonTexts = texts.General;
        return jsObj;
        //No Logged
    } else {
        jsObj = theTexts.notLogged;
        jsObj.CommonTexts = texts.General;
        return jsObj;
    }
}
