import { JSON_SECTION_S1_MAIN_MENU, loadSection, getTextByKey } from '../../../services/languageService';
import * as constants from '../../../constants';
import MenuItem from './MenuItem/MenuItem';
import './S1MainMenu.css';

//var logged = false;

/* Card will be add Later (We keep the code)
function renderCart() {
  return (
    <div className="cart-dropdown-wrap">
      <div className="cart-dropdown-control">
        <svg className="svg-cart">
          <use xlinkHref="#svg-cart"></use>
        </svg>
        <svg className="svg-cross medium hidden">
          <use xlinkHref="#svg-cross"></use>
        </svg>
        <div className="cart-item-quantity">
          <p>8</p>
        </div>
      </div>
      <div className="cart-dropdown">
        <div className="cart-dropdown-items">
          <div className="cart-dropdown-item">
            <a href="product-page-v1.html">
              <figure className="product-img small">
                <img src="img/products/02.jpg" alt="02" />
              </figure>
            </a>
            <div className="cart-dropdown-item-info">
              <a href="product-page-v1.html"><h6 className="title small">Poisonous Frog</h6></a>
              <p className="product-description small">For Outdoor Sports</p>
              <ul className="rating small">
                <li className="rating-item filled">
                  <svg className="svg-star">
                    <use xlinkHref="#svg-star"></use>
                  </svg>
                </li>
                <li className="rating-item filled">
                  <svg className="svg-star">
                    <use xlinkHref="#svg-star"></use>
                  </svg>
                </li>
                <li className="rating-item filled">
                  <svg className="svg-star">
                    <use xlinkHref="#svg-star"></use>
                  </svg>
                </li>
                <li className="rating-item filled">
                  <svg className="svg-star">
                    <use xlinkHref="#svg-star"></use>
                  </svg>
                </li>
                <li className="rating-item">
                  <svg className="svg-star">
                    <use xlinkHref="#svg-star"></use>
                  </svg>
                </li>
              </ul>
              <p className="product-quantity">x4</p>
              <p className="product-price">$600</p>
            </div>
            <svg className="svg-cross medium">
              <use xlinkHref="#svg-cross"></use>
            </svg>
          </div>
          <div className="cart-dropdown-item">
            <a href="product-page-v1.html">
              <figure className="product-img small">
                <img src="img/products/06.jpg" alt="06" />
              </figure>
            </a>
            <div className="cart-dropdown-item-info">
              <a href="product-page-v1.html"><h6 className="title small">Crashing Wave</h6></a>
              <p className="product-description small">Water Resistant</p>
              <ul className="rating small">
                <li className="rating-item filled">
                  <svg className="svg-star">
                    <use xlinkHref="#svg-star"></use>
                  </svg>
                </li>
                <li className="rating-item filled">
                  <svg className="svg-star">
                    <use xlinkHref="#svg-star"></use>
                  </svg>
                </li>
                <li className="rating-item filled">
                  <svg className="svg-star">
                    <use xlinkHref="#svg-star"></use>
                  </svg>
                </li>
                <li className="rating-item">
                  <svg className="svg-star">
                    <use xlinkHref="#svg-star"></use>
                  </svg>
                </li>
                <li className="rating-item">
                  <svg className="svg-star">
                    <use xlinkHref="#svg-star"></use>
                  </svg>
                </li>
              </ul>
              <p className="product-quantity">x2</p>
              <p className="product-price">$360</p>
            </div>
            <svg className="svg-cross medium">
              <use xlinkHref="#svg-cross"></use>
            </svg>
          </div>
          <div className="cart-dropdown-item">
            <a href="product-page-v1.html">
              <figure className="product-img small">
                <img src="img/products/05.jpg" alt="05" />
              </figure>
            </a>
            <div className="cart-dropdown-item-info">
              <a href="product-page-v1.html"><h6 className="title small">The Patriot</h6></a>
              <p className="product-description small">By Spiegel</p>
              <ul className="rating small">
                <li className="rating-item filled">
                  <svg className="svg-star">
                    <use xlinkHref="#svg-star"></use>
                  </svg>
                </li>
                <li className="rating-item filled">
                  <svg className="svg-star">
                    <use xlinkHref="#svg-star"></use>
                  </svg>
                </li>
                <li className="rating-item filled">
                  <svg className="svg-star">
                    <use xlinkHref="#svg-star"></use>
                  </svg>
                </li>
                <li className="rating-item filled">
                  <svg className="svg-star">
                    <use xlinkHref="#svg-star"></use>
                  </svg>
                </li>
                <li className="rating-item">
                  <svg className="svg-star">
                    <use xlinkHref="#svg-star"></use>
                  </svg>
                </li>
              </ul>
              <p className="product-quantity">x1</p>
              <p className="product-price">$185</p>
            </div>
            <svg className="svg-cross medium">
              <use xlinkHref="#svg-cross"></use>
            </svg>
          </div>
        </div>
        <div className="cart-dropdown-subtotal">
          <p className="title small">Cart Subtotal</p>
          <p className="product-price">$1145</p>
        </div>
        <div className="cart-dropdown-actions">
          <a href="cart.html" className="button dark large">Go to the Cart</a>
          <a href="identification.html" className="button large">Go to Checkout</a>
        </div>
      </div>
    </div>
  );
}
*/


function S1MainMenu(props) {

  function internGoToRoute(url) {
    props.goToRoute(url);
  }

  function renderMenu(menulist) {
    var menu = [];
    for (let i = 0; i < menulist.length; i++) {
      menu.push(<MenuItem goToRoute={props.goToRoute} key={i} bgimage={menulist[i].bgimage} name={menulist[i].name} menus={menulist[i].menus} href={menulist[i].href}  click={menulist[i].javascript} route={menulist[i].route}/>);
    }

    return (
      <ul className="main-menu">
        {menu}
      </ul>
    );
  }

  function getText(keyFields) {
    return getTextByKey(theTexts, keyFields, props.onTextNotFound)
  }

  if (props.texts) {
    var theTexts = loadSection(JSON_SECTION_S1_MAIN_MENU, props.texts, props.securityInfo);
    var cart = constants.EMPTY;
    var menu = constants.EMPTY;

    /* Card will be add Later
    if (logged) {
      cart = renderCart();
    }
    */
    if (theTexts.menus) {
      menu = renderMenu(theTexts.menus);
    }

    return (
      <div className="navigation-wrap">
        <nav className="navigation">
          <figure className="logo s1-mainmenu-logo">
            <div className='menuItemFirstLevelJSNoPadLeft tk-cursor-pointer' onClick={() => internGoToRoute(getText('logoLinks'))}><img width='170px' src={getText('logo')} alt='' /></div>
            <figcaption>Pneus FRB Inc.</figcaption>
          </figure>
          {cart}
          {menu}
        </nav>
      </div>
    );
  }
}

export default S1MainMenu;