import { JSON_SECTION_S8_THREE_ELEMENTS, loadSection, getTextByKey } from '../../../services/languageService';
import * as constants from '../../../constants';
import './S8ThreeElements.css'

function S8ThreeElements(props) {

  function getText(keyFields) {
    return getTextByKey(theTexts, keyFields, props.onTextNotFound)
  }

  var videoUrl = constants.EMPTY;
  function playVideo() {
    document.getElementById("playOff").className = 'tk-display-none';
    document.getElementById("playOn").className = 'tk-display-block';
    document.getElementById("playOn").src = videoUrl + '?autoplay=1';
  }

  if (props.texts) {
    var theTexts = loadSection(JSON_SECTION_S8_THREE_ELEMENTS, props.texts, props.securityInfo);

    videoUrl = getText(['list',1,'youtube']);
    return (
      <div className="section-wrap">
        <div className="section">
          <div className="step-list">
            <div className="step-list-item left">
              <div className="step-list-item-info">
                <h6 className="title large">{getText(['list',0,'title_line1'])}<br />{getText(['list',0,'title_line2'])}</h6>
                <h6 className="title small">{getText(['list',0,'subTitle'])}</h6>
                <p>{getText(['list',0,'desc'])}</p>
              </div>
              <img src={getText(['list',0,'image'])} alt="step01" className="step-list-item-img s8-three-elements-max-size-image" />
            </div>

            <div className="step-list-item right">
              <div className="step-list-item-info">
                <h6 className="title large">{getText(['list',1,'title_line1'])}<br />{getText(['list',1,'title_line2'])}</h6>
                <h6 className="title small">{getText(['list',1,'subTitle'])}</h6>
                <p>{getText(['list',1,'desc'])}</p>
              </div>
              <img id="playOff" src={getText(['list',1,'image'])} onClick={playVideo} alt="step01" className="step-list-item-img s8-three-elements-video-size-image tk-display-block tk-cursor-pointer" />
              <iframe id="playOn" className="tk-display-none" width="560" height="315" src={videoUrl} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>

            <div className="step-list-item left">
              <div className="step-list-item-info">
                <h6 className="title large">{getText(['list',2,'title_line1'])}<br />{getText(['list',2,'title_line2'])}</h6>
                <h6 className="title small">{getText(['list',2,'subTitle'])}</h6>
                <p>{getText(['list',2,'desc'])}<br /></p>
                <a className="frb-color-orange" href={getText(['list',2,'linkUrl'])} target="_blank" rel="noreferrer">{getText(['list',2,'linkTitle'])}</a>
              </div>
              <a href={getText(['list',2,'imageLinkUrl'])} target="_blank" rel="noreferrer"><img src={getText(['list',2,'image'])} alt="step01" className="step-list-item-img s8-three-elements-max-size-image tk-cursor-pointer" /></a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default S8ThreeElements;
