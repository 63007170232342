import { JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_RESULT, loadSubSection, getTextByKey } from '../../../../../../../services/languageService';
import * as constants from '../../../../../../../constants';
import './TireDescription.css';

function TireDescription(props) {

    var theTexts = props.texts;

    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    function checkSizePercent(value) {
        if (value) {
            return "(" + value + ")";
        }
    }

    function yesNo(theBoolean) {
        if (theBoolean) {
            return getText('descYes');
        }

        return getText('descNo');
    }

    if (theTexts) {
        var txtSection = loadSubSection(JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_RESULT, theTexts, props.securityInfo);

        var isDiscountCss = 'tire-result-desc-discount-tag frb-font-lato frb-bgcolor-orange-sale frb-font-medium-large frb-color-white frb-font-light-bold tk-display-none';

        if (props.jsonData.discount) {
            isDiscountCss = 'tire-result-desc-discount-tag frb-font-lato frb-bgcolor-orange-sale frb-font-medium-large frb-color-white frb-font-light-bold tk-display-block';
        }

        return (
            <>
                <div className="tk-grid tk-bgcolor-white tire-result-desc-grid-2-colons">
                    <div>
                        <div className={isDiscountCss}>{getText('descDiscount')}
                            <div className="tire-result-desc-discount-tag-triangle" />
                        </div>
                        <div className="tk-div-align-content-vh-center tire-result-desc-grid-column2">
                            <img alt={constants.EMPTY} width='140px' src={props.jsonData.tireImageUrl}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = getText('descNoTireImageURL');
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="tk-grid tk-bgcolor-white tire-result-desc-grid-2-colons22 tk-pad-top-5">
                            <div>
                                <img alt={constants.EMPTY} width='150px' src={props.jsonData.brandImageUrl} />
                            </div>
                            <div>
                                <div className="tk-grid tk-bgcolor-white tire-result-desc-type-grid-2-colons">
                                    <div>
                                        <img alt={constants.EMPTY} className='tk-height-20' src={props.jsonData.typeImageUrl} />
                                    </div>
                                    <div className="frb-font-lato frb-font-medium-large frb-font-light-bold tk-pad-top-3">{props.jsonData.typeName}</div>
                                </div>
                            </div>
                            <div className="tk-grid-col-span-2 frb-font-lato frb-font-medium-large tk-pad-bot-8 frb-font-bold tk-pad-top-5">{props.jsonData.name}</div>
                            <div className="frb-color-white tk-lh-25 tk-bgcolor-black">&nbsp;&nbsp;<span className="frb-font-medium-large frb-font-lato frb-color-white tk-lh-25 tk-bgcolor-black">#:</span><span className="frb-font-medium-large frb-font-lato frb-color-white tk-lh-25 tk-bgcolor-black frb-font-bold">&nbsp;{props.jsonData.number}</span></div>
                            <div>
                                <div className="tk-pad-l-30 tk-cursor-pointer"><span className="button v2 tire-result-desc-button-spec frb-font-lato">{getText('descSpec')}</span></div>
                            </div>
                            <div>
                                <div className="tk-pad-top-18">
                                    <span className="frb-font-small frb-font-bold frb-font-lato frb-color-black">{getText('descSize')}: </span>
                                    <span className="frb-font-small frb-font-lato frb-color-black">{props.jsonData.size}</span>
                                    <span className="frb-font-small frb-font-lato frb-color-orange">&nbsp;{checkSizePercent(props.jsonData.sizePercent)}</span>
                                </div>
                                <div>
                                    <span className="frb-font-small frb-font-bold frb-font-lato frb-color-black">{getText('descCharge')}:</span>
                                    <span className="frb-font-small frb-font-lato frb-color-black">&nbsp;{props.jsonData.performance}</span>
                                </div>
                                <div>
                                    <span className="frb-font-small frb-font-bold frb-font-lato frb-color-black">{getText('descColor')}:</span>
                                    <span className="frb-font-small frb-font-lato frb-color-black">&nbsp;{props.jsonData.color}</span>
                                </div>
                            </div>
                            <div>
                                <div className="tk-pad-top-18">
                                    <span className="frb-font-small frb-font-bold frb-font-lato frb-color-black">{getText('descRunFlat')}</span>
                                    <span className="frb-font-xsmall frb-font-lato frb-color-dark-gray tk-pad-top-5">{getText('descRunFlat2')}:</span>
                                    <span className="frb-font-small frb-font-lato frb-color-black">&nbsp;{yesNo(props.jsonData.runflat)}</span>
                                </div>
                                <div>
                                    <span className="frb-font-small frb-font-bold frb-font-lato frb-color-black">{getText('descNail')}:</span>
                                    <span className="frb-font-small frb-font-lato frb-color-black">&nbsp;{yesNo(props.jsonData.nailable)}</span>
                                </div>
                                <div>
                                    <span className="frb-font-small frb-font-lato frb-font-bold frb-color-black">{getText('descUTQG')}:</span>
                                    <span className="frb-font-small frb-font-lato frb-color-black">&nbsp;{props.jsonData.utqg}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default TireDescription;