import ResultPagination from './TireResult/ResultPagination/ResultPagination';
import TireResultCombo from './TireResult/TireResultCombo';
import TireResultHeader from './TireResultHeader/TireResultHeader';
import './TireResults.css';

function TireResults(props) {

    var results = [];

    if (props.jsonData && props.jsonData.results) {

        var firstElement = ((props.jsonData.summary.pageToDisplay - 1) * props.jsonData.summary.productDisplay)
        var nbrElementToShow = props.jsonData.summary.productDisplay;

        var toDisplayFound = 0;
        var showNowCount = 0;

        for (let i = 0; i < props.jsonData.results.length; i++) {
            var current = props.jsonData.results[i];
            var key = 'tireResult_' + i;
            if (current.display) {
                toDisplayFound++;
                if (toDisplayFound > firstElement){
                    results.push(<TireResultCombo key={key} keyValue={key} maskPrice={props.maskPrice} hidePrice={props.hidePrice} firstLine={0 === toDisplayFound} jsonData={current} onTextNotFound={props.onTextNotFound} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} securityInfo={props.securityInfo} />);                    
                    showNowCount++;
                }

                if (showNowCount >= nbrElementToShow){
                    break;
                }
            }
        }
    }

    return (
        <>
            <div className="tk-height-all">
                <TireResultHeader hidePrice={props.hidePrice} onTextNotFound={props.onTextNotFound} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} securityInfo={props.securityInfo} />
                <div className='tk-grid tk-bgcolor-white tire-result-main-grid-2-columns'>
                    {results}

                    <ResultPagination changePageToDisplay={props.changePageToDisplay} jsonData={props.jsonData} onTextNotFound={props.onTextNotFound} texts={props.texts} securityInfo={props.securityInfo} />
                </div>
            </div >
        </>
    );

}

export default TireResults;