import { useEffect, useState } from 'react';
import '../../TireSearchPage.css'
import './TabSpec.css'
import * as backEndService from '../../../../../../services/backend/backEndService';
import { JSON_SECTION_TIRE_SEARCH_PAGE, JSON_SUB_SECTION_TAB_SPEC, loadSubSection, getTextByKey, getGeneralTextByKey } from '../../../../../../services/languageService';
import * as validationService from '../../../../../../services/validationService';
import * as constants from '../../../../../../constants';
import Partners from '../Partners/Partners';

function TabSpec(props) {

    const DEFAULT_FRONT_TIRE = constants.EMPTY;
    const DEFAULT_BACK_TIRE = constants.EMPTY;
    const DEFAULT_PRODUCT = constants.EMPTY;
    const DEFAULT_BRAND = '-1';
    const DEFAULT_DESC = constants.EMPTY;

    const DEFAULT_DISCOUNT_ONLY = false;
    const DEFAULT_BACK_TIRE_SHOWED = false;

    const CSS_INVISIBLE = 'tk-display-none';
    const CSS_VISIBLE = constants.EMPTY;

    const [frontTireSize, setFrontTireSize] = useState(DEFAULT_FRONT_TIRE);
    const [backTireSize, setBackTireSize] = useState(DEFAULT_BACK_TIRE);
    const [productCode, setProductCode] = useState(DEFAULT_PRODUCT);
    const [brand, setBrand] = useState(DEFAULT_BRAND);
    const [description, setDescription] = useState(DEFAULT_DESC);
    const [type, setType] = useState(props.selectedType);
    const [discountOnly, setDiscountOnly] = useState(DEFAULT_DISCOUNT_ONLY);

    const [backTireShowed, setBackTireShowed] = useState(DEFAULT_BACK_TIRE_SHOWED);
    const [backTireCss, setBackTireCss] = useState(CSS_INVISIBLE);

    const [brandList, setBrandList] = useState(<option value="-1">Chargement en cours...</option>);

    const [partnerCSS, setPartnerCSS] = useState(CSS_INVISIBLE);

    const [selectedPartnerList, setSelectedPartnerList] = useState([]);
    const [resetCheckbox, setResetCheckbox] = useState('-1');

    var isMinimalDisplay = (props.displayType === constants.TIRE_SEARCH_DISPLAY_MINIMAL);

    useEffect(() => {
        loadBrands();
    }, []);



    useEffect(() => {
        if (!props.jest) {
            if (props.partnerList) {
                if (props.partnerList.length > 0) {
                    setPartnerCSS(CSS_VISIBLE);
                }
            }
        }
    }, [props.partnerList]);

    useEffect(() => {
        if (!props.jest) {
            setType(props.selectedType);
        }
    }, [props.selectedType]);

    useEffect(() => {
        if (props.tireSearchOptions) {
            var search = props.tireSearchOptions
            if (search.get(constants.MAPPING_TIRESEARCH_SEARCHTYPE) === constants.TIRESEARCH_TYPE_SPEC) {
                setFrontTireSize(search.get(constants.MAPPING_TIRESEARCH_SIZE_FRONT));
                setProductCode(search.get(constants.MAPPING_TIRESEARCH_PRODUCT_CODE));
                setBrand(search.get(constants.MAPPING_TIRESEARCH_BRAND));
                setDescription(search.get(constants.MAPPING_TIRESEARCH_DESCR));
                setType(search.get(constants.MAPPING_TIRESEARCH_TYPE));
                setDiscountOnly(search.get(constants.MAPPING_TIRESEARCH_DISCOUNT_ONLY));
                if (search.get(constants.MAPPING_TIRESEARCH_SIZE_BACK)) {
                    setBackTireSize(search.get(constants.MAPPING_TIRESEARCH_SIZE_BACK));
                    forceBackTireOption(true);
                }
            }
        }
    }, [brandList]);


    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    function getGeneralText(keyFields) {
        return getGeneralTextByKey(txtSection, keyFields, props.onTextNotFound)
    }


    function removePartner(id) {

        const newArray = [];

        for (let i = 0; i < selectedPartnerList.length; i++) {
            if (selectedPartnerList[i] !== id) {
                newArray.push(selectedPartnerList[i]);
            }
        }

        setSelectedPartnerList(newArray);
    }

    function addPartner(id) {
        selectedPartnerList.push(id);
    }

    function doSearch() {

        if (validationService.validateStringEmpty(frontTireSize) && validationService.validateStringEmpty(productCode)) {
            props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_TIRE_SEARCH_SIZE_OR_PRODUCT_NEEDED']));
            return;
        }

        if (backTireShowed && validationService.validateStringEmpty(backTireSize)) {
            props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_TIRE_SEARCH_BACK_SIZE_NEEDED']));
            return;
        }

        const allFields = new Map();
        allFields.set(constants.MAPPING_TIRESEARCH_SEARCHTYPE, constants.TIRESEARCH_TYPE_SPEC);
        allFields.set(constants.MAPPING_TIRESEARCH_SIZE_FRONT, frontTireSize);

        if (backTireShowed) {
            allFields.set(constants.MAPPING_TIRESEARCH_SIZE_BACK, backTireSize);
        }
        allFields.set(constants.MAPPING_TIRESEARCH_PRODUCT_CODE, productCode);

        if (brand !== '-1') {
            allFields.set(constants.MAPPING_TIRESEARCH_BRAND, brand);
        }
        allFields.set(constants.MAPPING_TIRESEARCH_DESCR, description);
        allFields.set(constants.MAPPING_TIRESEARCH_TYPE, type);
        allFields.set(constants.MAPPING_TIRESEARCH_DISCOUNT_ONLY, discountOnly);

        if (selectedPartnerList.length > 0) {
            allFields.set(constants.MAPPING_TIRESEARCH_PARTNERS, selectedPartnerList);
        }

        props.tireSearchFunction(allFields, props.moveToTop);
    }

    function loadBrands() {
        backEndService.loadTireBrands(props.securityInfo.token, handleBrandLoaded, handleBrandLoadedFailed);
    }

    function handleBrandLoaded(values) {
        var theList = [];

        if (values) {
            theList.push(<option key='all' value="-1">Toutes</option>);
            for (let i = 0; i < values.length; i++) {
                var current = values[i];
                theList.push(<option key={'brand' + i} value={current.description}>{current.description}</option>);
            }
            setBrandList(theList);
        }
    }

    function handleBrandLoadedFailed() {
        props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_TIRE_BRAND_LOAD_FAILED']));
    }

    function handleFrontTireSizeChange(event) {
        setFrontTireSize(event.target.value);
    }

    function handleBackTireSizeChange(event) {
        setBackTireSize(event.target.value);
    }

    function handleProductCodeChange(event) {
        setProductCode(event.target.value);
    }

    function handleBrandChange(event) {
        setBrand(event.target.value)
    }

    function handleDescriptionChange(event) {
        setDescription(event.target.value);
    }

    function handleTypeChange(event) {
        setType(event.target.value);
    }

    function handleDiscountChange() {
        setDiscountOnly(!discountOnly);
    }

    function forceBackTireOption(value) {
        if (value) {
            setBackTireCss(constants.EMPTY);
        } else {
            setBackTireCss(CSS_INVISIBLE)
        }
        setBackTireShowed(value);
    }

    function toggleBackTire() {
        forceBackTireOption(!backTireShowed);
        /*
        if (backTireShowed) {
            setBackTireCss(CSS_INVISIBLE)
        } else {
            setBackTireCss(constants.EMPTY);
        }
        setBackTireShowed(!backTireShowed);
        */
    }

    function clearScreen() {
        setFrontTireSize(DEFAULT_FRONT_TIRE);
        setBackTireSize(DEFAULT_BACK_TIRE);
        setProductCode(DEFAULT_PRODUCT);
        setBrand(DEFAULT_BRAND);
        setDescription(DEFAULT_DESC);
        setType(props.selectedType);
        setDiscountOnly(DEFAULT_DISCOUNT_ONLY);
        setBackTireShowed(DEFAULT_BACK_TIRE_SHOWED);
        setBackTireCss(CSS_INVISIBLE);
        clearPartners();
    }

    function clearPartners() {
        setSelectedPartnerList([]);
        setResetCheckbox(Date.now());
    }

    if (props.texts) {
        var txtSection = loadSubSection(JSON_SECTION_TIRE_SEARCH_PAGE, JSON_SUB_SECTION_TAB_SPEC, props.texts, props.securityInfo);



        if (isMinimalDisplay) {
            return (
                <>
                    <div className="tk-grid tk-bgcolor-white tabspec-grid-1-column">
                        <div>
                            <div className="form-row">
                                <div className="tk-grid tk-bgcolor-white tabspec-grid-4-columns">
                                    <div className="tabspec-width-tire-pixel">
                                        <label htmlFor="sizeFront" className="rl-label">{getText('sizeFront')}</label>
                                        <input onChange={handleFrontTireSizeChange} type="text" id="sizeFront" name="sizeFront" placeholder='' value={frontTireSize} />
                                    </div>
                                    <div className="tabspec-width-tire-pixel">
                                        <label htmlFor="productCode" className="rl-label">{getText('productCode')}</label>
                                        <input onChange={handleProductCodeChange} type="text" id="productCode" name="productCode" placeholder='' value={productCode} />
                                    </div>
                                    <div className="tabspec-width-tire-pixel">
                                        <label className="rl-label">{getText('brand')}</label>
                                        <label htmlFor="brand" className="select-block medium">
                                            <select name="brand" id="brand" onChange={handleBrandChange} value={brand}>
                                                {brandList}
                                            </select>
                                            <svg className="svg-arrow face-down">
                                                <use xlinkHref="#svg-arrow"></use>
                                            </svg>
                                        </label>
                                    </div>
                                    <div className="tabspec-width-tire-pixel">
                                        <label htmlFor="description" className="rl-label">{getText('description')}</label>
                                        <input onChange={handleDescriptionChange} type="text" id="description" name="description" placeholder='' value={description} />
                                    </div>
                                    <div className="tk-grid-col-span-4">
                                        <div className={backTireCss}>
                                            <div className="tabspec-width-tire-pixel tk-pad-top-20">
                                                <label htmlFor="sizeBack" className="rl-label">{getText('sizeBack')}</label>
                                                <input onChange={handleBackTireSizeChange} type="text" id="sizeBack" name="sizeBack" placeholder='' value={backTireSize} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tk-grid-col-span-4">
                                        <input onChange={toggleBackTire} type="checkbox" id="backTireShowed" name="backTireShowed" checked={backTireShowed} />
                                        <label className="checkbox" htmlFor="backTireShowed">
                                            <span className="box">
                                                <svg className="svg-check">
                                                    <use xlinkHref="#svg-check"></use>
                                                </svg>
                                            </span>
                                            <span className='tabspec-label-font-normal'>{getText('optionBackTire')}</span>
                                        </label>
                                    </div>
                                </div>


                                <div className="tk-grid tk-bgcolor-white tabspec-grid-5-columns tk-pad-top-20">
                                    <div>
                                        <input type="radio" id="pb_warranty1" name="pb_warranty1" value={constants.TIRE_TYPE_SUMMER} checked={type === constants.TIRE_TYPE_SUMMER} onChange={handleTypeChange} />
                                        <label className="radio rl-label" htmlFor="pb_warranty1">
                                            <span className="circle tk-bgcolor-white"><span className="inner-circle"></span></span>
                                            {getText('radioSummerTire')}
                                        </label>
                                    </div>
                                    <div>
                                        <input type="radio" id="pb_warranty3" name="pb_warranty3" value={constants.TIRE_TYPE_WINTER} checked={type === constants.TIRE_TYPE_WINTER} onChange={handleTypeChange} />
                                        <label className="radio rl-label" htmlFor="pb_warranty3">
                                            <span className="circle tk-bgcolor-white"><span className="inner-circle"></span></span>
                                            {getText('radioWinterTire')}
                                        </label>
                                    </div>
                                    <div>
                                        <input type="radio" id="pb_warranty2" name="pb_warranty2" value={constants.TIRE_TYPE_HOMOLOGUED} checked={type === constants.TIRE_TYPE_HOMOLOGUED} onChange={handleTypeChange} />
                                        <label className="radio rl-label" htmlFor="pb_warranty2">
                                            <span className="circle tk-bgcolor-white"><span className="inner-circle"></span></span>
                                            {getText('radioHomologued')}
                                        </label>
                                    </div>
                                    <div>
                                        <input type="radio" id="pb_warranty4" name="pb_warranty4" value={constants.TIRE_TYPE_4SEASONS} checked={type === constants.TIRE_TYPE_4SEASONS} onChange={handleTypeChange} />
                                        <label className="radio rl-label" htmlFor="pb_warranty4">
                                            <span className="circle tk-bgcolor-white"><span className="inner-circle"></span></span>
                                            {getText('radio4Seasons')}
                                        </label>
                                    </div>
                                    <div>
                                        <div className="tk-grid tk-bgcolor-white tabspec-grid-2-columns">
                                            <div onClick={clearScreen} className='tk-cursor-pointer'><span className="button v2 tabspec-button-erase">{getText('buttonClear')}</span></div>
                                            <div onClick={doSearch} className='tk-cursor-pointer'><span className="button tabspec-button-search">{getText('buttonSearch')}</span></div>
                                        </div>
                                    </div>
                                    <div className="tk-grid-col-span-4">
                                        <input type="checkbox" id="checkbox99" name="checkbox99" onChange={handleDiscountChange} checked={discountOnly} />
                                        <label className="checkbox" htmlFor="checkbox99">
                                            <span className="box">
                                                <svg className="svg-check">
                                                    <use xlinkHref="#svg-check"></use>
                                                </svg>
                                            </span>
                                            <span className='tabspec-label-font-bold'>{getText('checkboxDiscountOnly')}</span>
                                        </label>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className="tk-grid tk-bgcolor-white tabspec-grid-2-columns-2">
                        <div>
                            <div className="form-row">
                                <div className="tabspec-width90">
                                    <label htmlFor="fname1" className="rl-label">{getText('sizeFront')}</label>
                                    <input onChange={handleFrontTireSizeChange} type="text" id="fname1" name="fname1" placeholder='' value={frontTireSize} />
                                </div>
                                <div className="">
                                    <input onChange={toggleBackTire} type="checkbox" id="checkbox3" name="checkbox3" checked={backTireShowed} />
                                    <label className="checkbox" htmlFor="checkbox3">
                                        <span className="box">
                                            <svg className="svg-check">
                                                <use xlinkHref="#svg-check"></use>
                                            </svg>
                                        </span>
                                        <span className='tabspec-label-font-normal'>{getText('optionBackTire')}</span>
                                    </label>
                                </div>
                                <div className={backTireCss}>
                                    <div className="tabspec-width90 tk-pad-top-20">
                                        <label htmlFor="fname1" className="rl-label">{getText('sizeBack')}</label>
                                        <input onChange={handleBackTireSizeChange} type="text" id="fname1" name="fname1" placeholder='' value={backTireSize} />
                                    </div>
                                </div>
                                <div className="tabspec-width90 tk-pad-top-20">
                                    <label htmlFor="fname1" className="rl-label">{getText('productCode')}</label>
                                    <input onChange={handleProductCodeChange} type="text" id="fname1" name="fname1" placeholder='' value={productCode} />
                                </div>
                                <div className="tabspec-width90 tk-pad-top-20">
                                    <label className="rl-label">{getText('brand')}</label>
                                    <label htmlFor="user_type" className="select-block medium">
                                        <select name="user_type2" id="user_type2" onChange={handleBrandChange} value={brand}>
                                            {brandList}
                                        </select>
                                        <svg className="svg-arrow face-down">
                                            <use xlinkHref="#svg-arrow"></use>
                                        </svg>
                                    </label>
                                </div>
                                <div className="tabspec-width90 tk-pad-top-20">
                                    <label htmlFor="fname1" className="rl-label">{getText('description')}</label>
                                    <input onChange={handleDescriptionChange} type="text" id="fname1" name="fname1" placeholder='' value={description} />
                                </div>
                                <div className="tk-grid tk-bgcolor-white tabspec-grid-2-columns-3 tk-pad-top-20">
                                    <div>
                                        <input key={Math.random()} type="radio" id="pb_warranty1" name="pb_warranty1" value={constants.TIRE_TYPE_SUMMER} checked={type === constants.TIRE_TYPE_SUMMER} onChange={handleTypeChange} />
                                        <label className="radio rl-label" htmlFor="pb_warranty1">
                                            <span className="circle tk-bgcolor-white"><span className="inner-circle"></span></span>
                                            {getText('radioSummerTire')}
                                        </label>
                                    </div>

                                    <div>
                                        <input key={Math.random()} type="radio" id="pb_warranty2" name="pb_warranty2" value={constants.TIRE_TYPE_HOMOLOGUED} checked={type === constants.TIRE_TYPE_HOMOLOGUED} onChange={handleTypeChange} />
                                        <label className="radio rl-label" htmlFor="pb_warranty2">
                                            <span className="circle tk-bgcolor-white"><span className="inner-circle"></span></span>
                                            {getText('radioHomologued')}
                                        </label>
                                    </div>
                                    <div>
                                        <input key={Math.random()} type="radio" id="pb_warranty3" name="pb_warranty3" value={constants.TIRE_TYPE_WINTER} checked={type === constants.TIRE_TYPE_WINTER} onChange={handleTypeChange} />
                                        <label className="radio rl-label" htmlFor="pb_warranty3">
                                            <span className="circle tk-bgcolor-white"><span className="inner-circle"></span></span>
                                            {getText('radioWinterTire')}
                                        </label>
                                    </div>
                                    <div>
                                        <input key={Math.random()} type="radio" id="pb_warranty4" name="pb_warranty4" value={constants.TIRE_TYPE_4SEASONS} checked={type === constants.TIRE_TYPE_4SEASONS} onChange={handleTypeChange} />
                                        <label className="radio rl-label" htmlFor="pb_warranty4">
                                            <span className="circle tk-bgcolor-white"><span className="inner-circle"></span></span>
                                            {getText('radio4Seasons')}
                                        </label>
                                    </div>
                                </div>
                                <div className="">
                                    <input type="checkbox" id="checkbox99" name="checkbox99" onChange={handleDiscountChange} checked={discountOnly} />
                                    <label className="checkbox" htmlFor="checkbox99">
                                        <span className="box">
                                            <svg className="svg-check">
                                                <use xlinkHref="#svg-check"></use>
                                            </svg>
                                        </span>
                                        <span className='tabspec-label-font-bold'>{getText('checkboxDiscountOnly')}</span>
                                    </label>
                                </div>
                                <div className="tk-grid tk-bgcolor-white tabspec-grid-2-columns-3 tk-pad-top-20">
                                    <div onClick={clearScreen} className='tk-cursor-pointer'><span className="button v2 tabspec-button-erase">{getText('buttonClear')}</span></div>
                                    <div onClick={doSearch} className='tk-cursor-pointer'><span className="button tabspec-button-search">{getText('buttonSearch')}</span></div>
                                </div>
                            </div>
                        </div>
                        <div className={partnerCSS}><Partners partnerList={props.partnerList} resetAsked={resetCheckbox} addPartner={addPartner} removePartner={removePartner} securityInfo={props.securityInfo} onTextNotFound={props.onTextNotFound} texts={props.texts} /></div>
                    </div>
                </>
            );
        }
    }
}

export default TabSpec;