import { JSON_SECTION_S9_PROMO, loadSection } from '../../../services/languageService';

function S9Promo(props) {

  function callLoginRedirect(url){
    props.loginRedirect(url);
  }

  if (props.texts) {
    var theTexts = loadSection(JSON_SECTION_S9_PROMO, props.texts, props.securityInfo);

    return (
      <div className="promo-banners">
        <div className="promo-banner main">
          <div className="promo-banner-title-wrap">
            <h3 className="title title-extra">{theTexts.left_title1_1}</h3>
          </div>

          <div className="promo-banner-title-wrap">
            <h3 className="title title-promo composed">{theTexts.left_title2_1}</h3>
            <h3 className="title title-percent">{theTexts.left_title2_2}<br /><span className="title-unit"></span></h3>
            <h3 className="title title-extra">{theTexts.left_title2_3} <br />{theTexts.left_title2_4}</h3>
          </div>

          <h6 className="title title-description">{theTexts.left_subtitle}</h6>
          <span onClick={() => callLoginRedirect(theTexts.left_button_link)} className="button long dark tk-cursor-pointer">{theTexts.left_button}</span>
        </div>

        <div className="promo-banner dark">
          <div className="promo-banner-title-wrapsss">
            <h3 className="title title-promo"><span className="bold">{theTexts.right_title1_1}</span></h3>
          </div>

          <h6 className="title title-description">{theTexts.right_subtitle}</h6>
          <p>{theTexts.right_subtitle2}</p>
          <a href={theTexts.right_button_link} target="_blank" rel="noreferrer" className="button long with-icon">
            {theTexts.right_button}
            <svg className="svg-arrow">
              <use xlinkHref="#svg-arrow"></use>
            </svg>
          </a>
        </div>
      </div>
    );
  }
}
export default S9Promo;
