import { JSON_SECTION_S5_CAREER, loadSection, getTextByKey } from '../../../services/languageService';

function S5Career(props) {
  function getText(keyFields) {
    return getTextByKey(theTexts, keyFields, props.onTextNotFound)
  }

  if (props.texts) {
    var theTexts = loadSection(JSON_SECTION_S5_CAREER, props.texts, props.securityInfo);

    return (
      <div className="action-poster-wrap frb-bgcolor-light-black-imp">
        <div className="action-poster">
          <div className="action-poster-info">
            <h3 className="title">{getText('title_left')} <span className="bold">{getText('title_right')}</span></h3>
            <p>{getText('subtitle')}</p>
          </div>
          <a href={getText('href')} className="button medium with-icon">
            {getText('button')}
            <svg className="svg-arrow">
              <use xlinkHref="#svg-arrow"></use>
            </svg>
          </a>
        </div>
      </div>
    );
  }
}
export default S5Career;
