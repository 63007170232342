import { useEffect, useState } from 'react';
import './SliderFilter.css';

function SliderFilter(props) {
    const [minValue, setMinValue] = useState(props.jsonData.minValue);
    const [maxValue, setMaxValue] = useState(props.jsonData.maxValue);
    const [lastSearchTime, setLastSearchTime] = useState(props.tireSearchTime);

    useEffect(() => {
        eventOnSliderMove();
        if (lastSearchTime !== props.tireSearchTime){
            setLastSearchTime(props.tireSearchTime);
            setMinValue(props.jsonData.minValue);
            setMaxValue(props.jsonData.maxValue);
        }
    });

    var absoluteMin = 0;
    var absoluteMax = props.jsonData.maxValue;

    function priceSlicerButtonClicked() {
        props.action(props.jsonData.uniqueName, props.jsonData.type, props.jsonData.dataSource, minValue, maxValue);
    }

    function onChangeMin(event) {
        setMinValue(parseInt(event.target.value));
    }

    function onChangeMax(event) {
        setMaxValue(parseInt(event.target.value));
    }

    function eventOnSliderMove(e) {

        let rangeMin = 10;
        const range = document.querySelector(".slider-filter-range-selected");
        const rangeInput = document.querySelectorAll(".slider-filter-range-input input");
        const rangeOutput = document.querySelectorAll(".slider-filter-result-output span");

        let minRange = parseInt(rangeInput[0].value);
        let maxRange = parseInt(rangeInput[1].value);
        if (maxRange - minRange < rangeMin) {
            if (e && e.target.className === "min") {
                rangeInput[0].value = maxRange - rangeMin;
            } else {
                rangeInput[1].value = minRange + rangeMin;
            }
        } else {
            rangeOutput[0].textContent = ('$' + minRange);
            rangeOutput[1].textContent = ('$' + maxRange);

            range.style.left = (minRange / rangeInput[0].max) * 100 + "%";
            range.style.right = 100 - (maxRange / rangeInput[1].max) * 100 + "%";
        }
    }

    return (
        <>
            <div>
                <div>
                    <div className="tk-bgcolor-white">
                        <div>
                            <label className="slider-filter-title">{props.jsonData.title}</label>
                            <hr className="line-separator slider-filter-line-separator" />
                        </div>
                        <div>
                            <div className="range tk-pad-top-10">
                                <div className="slider-filter-range-slider">
                                    <span className="slider-filter-range-selected"></span>
                                </div>

                                <div className="slider-filter-range-input">
                                    <input type="range" className="min" min={absoluteMin} max={absoluteMax} value={minValue} step="10" onChange={onChangeMin} onInput={(event) => eventOnSliderMove(event)} />
                                    <input type="range" className="max" min={absoluteMin} max={absoluteMax} value={maxValue} step="10" onChange={onChangeMax} onInput={(event) => eventOnSliderMove(event)} />
                                </div>

                                <div className="slider-filter-result-output tk-pad-top-10 frb-font-large">
                                    Prix: <span className="frb-font-bold">${minValue}</span> à <span className="frb-font-bold">${maxValue}</span>
                                </div>

                                <div className='tk-cursor-pointer tk-text-center tk-pad-bot-10 tk-pad-top-10'>
                                    <span onClick={priceSlicerButtonClicked} className="button slider-filter-button">Filtrer</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default SliderFilter;