import { useState } from 'react';
import TireSearchPage from './TireSearchPage';
import * as constants from '../../../../constants';

function TireSearch(props) {
    var tabToDisplay = constants.TAB_TIRE_SEARCH_SPEC;

    if (props.tireSearchOptions) {
        if (props.tireSearchOptions.get(constants.MAPPING_TIRESEARCH_SEARCHTYPE) === constants.TIRESEARCH_TYPE_VEH) {
            tabToDisplay = constants.TAB_TIRE_SEARCH_VEH;
        }
    }

    const [tabToShow, setTabToShow] = useState(tabToDisplay);

    function tabChange(index) {
        setTabToShow(index);
    }

    if (props.texts) {
        return (
            <>
                <TireSearchPage jest={props.jest} showElements={false} moveToTop={props.moveToTop} partnerList={props.partnerList} tireSearchOptions={props.tireSearchOptions} tireSearchFunction={props.tireSearchFunction} goToRoute={props.goToRoute} displayType={props.displayType} tabSelected={tabToShow} selectedType={props.selectedType} onTextNotFound={props.onTextNotFound} texts={props.texts} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} tabChange={tabChange} securityInfo={props.securityInfo} />
            </>
        );
    }
}

export default TireSearch;