import { useState } from 'react';
import { JSON_SECTION_JOIN_US, loadSection, getTextByKey, getGeneralTextByKey } from '../../../services/languageService';
import './JoinUs.css'
import { validateEmail, validateStringNotEmpty } from '../../../services/validationService';
import { sendContactUsMessage } from '../../../services/backend/backEndService';

function JoinUs(props) {
  function getText(keyFields) {
    return getTextByKey(txtSection, keyFields, props.onTextNotFound)
  }

  function getGeneralText(keyFields) {
    return getGeneralTextByKey(txtSection, keyFields, props.onTextNotFound)
  }

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');

  function sendMsg() {
    if (!validateStringNotEmpty(name)) {
      props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_JOINUS_INVALID_NAME']));
    } else if (!validateEmail(email)) {
      props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_JOINUS_INVALID_EMAIL']));
    } else if (!validateStringNotEmpty(msg)) {
      props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_JOINUS_INVALID_MSG']));
    } else {
      sendContactUsMessage(name, email, msg, sendMsgOk, sendMsgFailed)
    }
  }

  function sendMsgOk() {
    props.displaySuccessMessage(getGeneralText(['Messages', 'SUCCESS_JOINUS_MSG_SEND']));
  }

  function sendMsgFailed() {
    props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_JOINUS_MSG_SEND_FAILED']));
  }

  if (props.texts) {
    var txtSection = loadSection(JSON_SECTION_JOIN_US, props.texts, props.securityInfo);

    return (
      <>
        <div className="section-wrap">
          <div className="section no-title bottom-void">
            <div className="feature-enum full">
              <div className="feature-enum-item">
                <i className="s-icon icon-location-pin"></i>
                <h6 className="title">{getText(['top', 0, 'title'])}</h6>
                <p>{getText(['top', 0, 'line1'])}</p>
                <p>{getText(['top', 0, 'line2'])}</p>
              </div>

              <div className="feature-enum-item">
                <i className="s-icon icon-envelope"></i>
                <h6 className="title">{getText(['top', 1, 'title'])}</h6>
                <p>
                  <a href={getText(['top', 1, 'href'])} className="link medium">{getText(['top', 1, 'line1'])}</a>
                </p>
              </div>

              <div className="feature-enum-item">
                <i className="s-icon icon-phone"></i>
                <h6 className="title">{getText(['top', 2, 'title'])}</h6>
                <p>{getText(['top', 2, 'line1'])}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="section-wrap gmap">
          <div className="section void joinus-bottom">

            <div className="contact-actions">
              <div className='tk-grid joinsus-grid'>
                <div className="contact-form joinus-contact-form-map">
                  <div id="map" className='joinus-map-div'>
                    <iframe title="googleMap" src={getText(['map', 'url'])} width="100%" height="100%" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                </div>
                <div className='joinus-contact-div-main'>
                  <div className="contact-form joinus-contact-div">
                    <h2 className="subsection-title">{getText(['contact', 'title'])}</h2>
                    <hr className="line-separator" />
                    <form>
                      <div className="form-row">
                        <div className="half">
                          <label htmlFor="c_name" className="rl-label">{getText(['contact', 'name'])}</label>
                          <input type="text" id="c_name" name="c_name" value={name} onChange={(e) => setName(e.target.value)} placeholder={getText(['contact', 'nameHint'])} />
                        </div>
                        <div className="half">
                          <label htmlFor="c_email" className="rl-label">{getText(['contact', 'email'])}</label>
                          <input type="text" id="c_email" name="c_email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={getText(['contact', 'emailHint'])} />
                        </div>
                      </div>

                      <div className="form-row">
                        <label htmlFor="c_comment" className="rl-label">{getText(['contact', 'message'])}</label>
                        <textarea id="c_comment" name="c_comment" value={msg} onChange={(e) => setMsg(e.target.value)} placeholder={getText(['contact', 'messageHint'])}></textarea>
                      </div>

                      <div className="form-row separated">
                        <div className="button frb-font-lato" onClick={sendMsg}>{getText(['contact', 'button'])}</div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default JoinUs;
