import { JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_HEADERS, loadSubSection, getTextByKey } from '../../../../../../services/languageService';
import './TireResultHeader.css';

function TireResultHeader(props) {

    var theTexts = props.texts;

    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    if (theTexts) {
        var txtSection = loadSubSection(JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_HEADERS, theTexts, props.securityInfo);

        if (props.hidePrice) {
            return (
                <>
                    <div className='tire-result-headers-sticky'>
                        <div className="tk-grid tk-bgcolor-white tire-result-grid-sizes tire-result-headers">
                            <div className='frb-font-lato frb-font-bold frb-font-medium-large'>{getText('column1')}</div>
                            <div className='frb-font-lato frb-font-bold frb-font-medium-large tk-grid-col-span-2'>{getText('column3')}</div>
                            <div className='frb-font-lato frb-font-bold frb-font-medium-large'>{getText('column4')}</div>
                            <div className='frb-font-lato frb-font-bold frb-font-medium-large'>{getText('column5')}</div>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className='tire-result-headers-sticky'>
                        <div className="tk-grid tk-bgcolor-white tire-result-grid-sizes tire-result-headers">
                            <div className='frb-font-lato frb-font-bold frb-font-medium-large'>{getText('column1')}</div>
                            <div className='frb-font-lato frb-font-bold frb-font-medium-large'>{getText('column2')}</div>
                            <div className='frb-font-lato frb-font-bold frb-font-medium-large'>{getText('column3')}</div>
                            <div className='frb-font-lato frb-font-bold frb-font-medium-large'>{getText('column4')}</div>
                            <div className='frb-font-lato frb-font-bold frb-font-medium-large'>{getText('column5')}</div>
                        </div>
                    </div>
                </>
            );
        }


    }
}

export default TireResultHeader;