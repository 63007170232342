export function validateEmail(emailToValidate) {

    var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!validateStringNotEmpty(emailToValidate)) {
        return false;
    }

    if (!emailToValidate.match(validRegex)) {
        return false;
    }

    return true;
}

export function isBetweenOrEquals(theValue, min, max) {
    var toReturn = false;
    if (isNumber(theValue) && isNumber(min) && isNumber(max)) {
        if (theValue >= min && theValue <= max) {
            toReturn = true;
        }
    }

    return toReturn;
}

export function validatePassword(pass1, pass2) {

    if (!(validateStringNotEmpty(pass1) && validateStringNotEmpty(pass2))) {
        return false;
    }

    if (pass1 !== pass2) {
        return false;
    }

    return true;
}

export function validateStringEquals(reel, expected) {
    return (reel === expected);
}

export function validateStringEmpty(text) {
    return !validateStringNotEmpty(text);
}

export function validateStringNotEmpty(text) {

    if (!isString(text)) {
        return false;
    }

    if (text.trim().length === 0) {
        return false;
    }

    return true;
}

export function isLogged(securityInfo) {

    if (!isObject(securityInfo)) {
        return false;
    }
    if (securityInfo != null && securityInfo !== 'null' && securityInfo.token !== null && securityInfo.token !== 'null') {
        return validateStringNotEmpty(securityInfo.token);
    }
    return false;

}

export function isAdmin(securityInfo) {
    var toReturn = false;
    if (isLogged(securityInfo)) {
        toReturn = (('' + securityInfo.admin).toLowerCase() === 'true');
    }
    return toReturn;
}

export function isArray(obj) {
    return obj !== undefined && obj !== null && obj.constructor === Array;
}

export function isBoolean(obj) {
    return obj !== undefined && obj !== null && obj.constructor === Boolean;
}

export function isFunction(obj) {
    return obj !== undefined && obj !== null && obj.constructor === Function;
}

export function isNumber(obj) {
    return obj !== undefined && obj !== null && obj.constructor === Number;
}

export function isString(obj) {
    return obj !== undefined && obj !== null && obj.constructor === String;
}

export function isObject(obj) {
    return obj !== undefined && obj !== null && obj.constructor === Object;
}