import { useEffect, useState } from 'react';
import './UserAddEdit.css';
import * as constants from '../../../../constants'
import * as backEndService from '../../../../services/backend/backEndService';
import * as validationService from '../../../../services/validationService';
import { getTextByKey, getGeneralTextByKey } from '../../../../services/languageService';

function UserAddEdit(props) {

  const HTML_NAME_PASSWORD_2 = 'password2';
  const HTML_NAME_PASSWORD_1 = 'password1';

  //Fields of the page.
  const [noAccount, setNoAccount] = useState(constants.EMPTY);
  const [userCode, setUserCode] = useState(constants.EMPTY);
  const [userName, setUserName] = useState(constants.EMPTY);
  const [email, setEmail] = useState(constants.EMPTY);
  const [password1, setPassword1] = useState(null);
  const [password2, setPassword2] = useState(null);
  const [canSeeCost, setCanSeeCost] = useState(false);
  const [cannotPassOrder, setCannotPassOrder] = useState(false);
  const [storageOnly, setStorageOnly] = useState(false);
  const [tireOnly, setTireOnly] = useState(false);
  const [userType, setUserType] = useState(constants.USER_TYPE_USER);
  const [loadedUser, setLoadedUser] = useState(null);

  //Texts and images for this component
  var theTexts = props.theTexts;

  //To know is it's a new User or an existing one
  var isUpdateUser = (props.update === true);

  //Called after render of the component to call the server to get the user if it's an update
  useEffect(() => {
    if (isUpdateUser) {
      loadUser(props.userId);
    }
  }, []);

  /*
  Load User from Backend
  */
  function loadUser(userId) {
    backEndService.getUser(props.securityInfo.token, userId, handleUserLoaded, handleUserLoadedFailed);
  }

  function getText(keyFields) {
    return getTextByKey(theTexts, keyFields, props.onTextNotFound)
  }

  function getGeneralText(keyFields) {
    return getGeneralTextByKey(theTexts, keyFields, props.onTextNotFound)
  }

  function clearScreen() {
    setUserCode(constants.EMPTY);
    setUserName(constants.EMPTY);
    setEmail(constants.EMPTY);
    setPassword1(constants.EMPTY);
    setPassword2(constants.EMPTY);
    document.getElementById(HTML_NAME_PASSWORD_2).value = constants.EMPTY;
    document.getElementById(HTML_NAME_PASSWORD_1).value = constants.EMPTY;
    setCanSeeCost(false);
    setCannotPassOrder(false);
    setStorageOnly(false);
    setTireOnly(false);
    setUserType(constants.USER_TYPE_USER);
    document.getElementById('user_type').value = constants.USER_TYPE_USER;

  }
  /************************************************
  Function: handleUserLoaded
  Called by the backend when to user is retreived
  *************************************************/
  function handleUserLoaded(responseJsonObj) {
    setLoadedUser(responseJsonObj);
    var mapData = backEndService.getFieldsMapFromJsonUser(responseJsonObj);
    populateUserOnUI(mapData);
  }

  /************************************************
  Function: handleUserLoadedFailed
  Called by the backend when to user is retreived failed
  *************************************************/
  function handleUserLoadedFailed(responseJsonObj) {
    props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_REQUEST_FOR_LOAD_USER']));
    props.goToRoute(constants.ROUTE_HOME);
  }

  /************************************************
  Function: populateUserOnUI
  Populate UI fields with appropriate data from the backend.
  *************************************************/
  function populateUserOnUI(allFields) {
    setNoAccount(allFields.get(constants.MAPPING_USER_ACCOUNT_NUMBER));
    setUserCode(allFields.get(constants.MAPPING_USER_USER_CODE));
    setUserName(allFields.get(constants.MAPPING_USER_USER_NAME));
    setEmail(allFields.get(constants.MAPPING_USER_EMAIL));
    setCanSeeCost(allFields.get(constants.MAPPING_USER_AUTH_CANSEECOST));
    setCannotPassOrder(allFields.get(constants.MAPPING_USER_AUTH_CANNOTPASSORDER));
    setStorageOnly(allFields.get(constants.MAPPING_USER_AUTH_STORAGE_ONLY));
    setTireOnly(allFields.get(constants.MAPPING_USER_AUTH_TIRE_ONLY));
    setUserType(allFields.get(constants.MAPPING_USER_USERTYPE));
  }

  function saveExistingUser(mapOfAllFields, originalJsonUser) {
    backEndService.modifyUser(props.securityInfo.token, mapOfAllFields, originalJsonUser, handleSaveExistingUser, handleSaveExistingUserFailed);
  }

  function handleSaveExistingUser() {
    props.displaySuccessMessage(getGeneralText(['Messages', 'SUCCESS_REQUEST_FOR_MODIFY_USER']));
  }

  function handleSaveExistingUserFailed() {

    props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_MANAGE_USER_SAVE_FAILED']));
  }


  function saveNewUser(mapOfAllFields) {
    backEndService.addUser(props.securityInfo.token, mapOfAllFields, handleAddUser, handleAddUserFailed);
  }

  function handleAddUser(data) {
    props.displaySuccessMessage(getGeneralText(['Messages', 'SUCCESS_REQUEST_FOR_ADD_USER']));
    clearScreen();
    props.goToRoute(constants.ROUTE_ADDUSER);
  }

  function handleAddUserFailed() {
    props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_MANAGE_USER_ADD_FAILED']));
  }

  function handleUIButtonSave() {
    if (!validationService.validateStringNotEmpty(userCode)) {
      props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_USER_CODE_CANNOT_BE_EMPTY']));
      return;
    }

    if (!validationService.validateStringNotEmpty(userName)) {
      props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_USER_NAME_CANNOT_BE_EMPTY']));
      return;
    }

    //The email adresse can be empty. If not we check it's a valid email
    if (validationService.validateStringNotEmpty(email)) {
      if (!validationService.validateEmail(email)) {
        props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_INVALID_EMAIL']));
        return;
      }
    }

    var passwordChange = false;
    if (validationService.validateStringNotEmpty(password1)) {
      passwordChange = true
      if (!validationService.validatePassword(password1, password2)) {
        if (isUpdateUser && password1 !== constants.EMPTY && password2 !== constants.EMPTY) {
          props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_NEW_PASSWORD_CONFIRMATION_NOT_SAME']));
          return;
        } else if (!isUpdateUser) {
          props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_NEW_PASSWORD_CONFIRMATION_NOT_SAME']));
          return;
        }
      }
    } else {
      passwordChange = false;
    }

    props.clearMessage();

    const allFields = new Map();
    allFields.set(constants.MAPPING_USER_ACCOUNT_NUMBER, noAccount);
    allFields.set(constants.MAPPING_USER_USER_CODE, userCode);
    allFields.set(constants.MAPPING_USER_USER_NAME, userName);
    allFields.set(constants.MAPPING_USER_EMAIL, email);
    if (passwordChange) {
      allFields.set(constants.MAPPING_USER_PASSWORD, password1);
    }

    allFields.set(constants.MAPPING_USER_AUTH_CANSEECOST, canSeeCost);
    allFields.set(constants.MAPPING_USER_AUTH_CANNOTPASSORDER, cannotPassOrder);
    allFields.set(constants.MAPPING_USER_AUTH_STORAGE_ONLY, storageOnly);
    allFields.set(constants.MAPPING_USER_AUTH_TIRE_ONLY, tireOnly);

    allFields.set(constants.MAPPING_USER_USERTYPE, userType);
    allFields.set(constants.MAPPING_USER_CR0107, props.loggedUser.userMssql.cr0107);

    if (isUpdateUser) {
      saveExistingUser(allFields, loadedUser);
    } else {
      saveNewUser(allFields);
    }
  }

  /*
  Handle Text Field change in the UI
  */
  function handleUIChangeNoAccount(event) {
    setNoAccount(event.target.value);
  }

  function handleUIChangeUserCode(event) {
    setUserCode(event.target.value);
  }

  function handleUIChangeUserName(event) {
    setUserName(event.target.value);
  }

  function handleUIChangeEmail(event) {
    setEmail(event.target.value);
  }

  function handleUIChangePassword1(event) {
    setPassword1(event.target.value);
  }

  function handleUIChangePassword2(event) {
    setPassword2(event.target.value);
  }

  function handleUIChangeCanSeeCost(event) {
    setCanSeeCost(!canSeeCost);
  }

  function handleUIChangeCannotPassOrder(event) {
    setCannotPassOrder(!cannotPassOrder);
  }

  function handleUIChangeStorageOnly(event) {
    setStorageOnly(!storageOnly);
  }

  function handleUIChangeTireOnly(event) {
    setTireOnly(!tireOnly);
  }

  function handleUIChangeUserType(event) {
    setUserType(event.target.value);
  }

  return (
    <>
      <div className="section-wrap">
        <div className="section no-title">
          <div className="content useraddedit-width left">
            <h2 className="subsection-title">{getText('accountTitle')}</h2>
            <hr className="line-separator" />
            <div className="form-row tk-mar-top-35">
              <div className="third">
                <label htmlFor="fname1" className="rl-label">{getText(['account', 0, 'name'])}</label>
                <input readOnly type="text" onChange={handleUIChangeNoAccount} id="fname1" name="fname1" placeholder={getText(['account', 0, 'hint'])} value={props.noAccount} />
              </div>
            </div>
            <div className="form-row">
              <div className="third">
                <label htmlFor="fname2" className="rl-label">{getText(['account', 1, 'name'])}</label>
                <input type="text" onChange={handleUIChangeUserCode} autoFocus id="fname2" name="fname2" placeholder={getText(['account', 1, 'hint'])} value={userCode} />
              </div>
            </div>
            <div className="form-row">
              <div className="third">
                <label htmlFor="fname3" className="rl-label">{getText(['account', 2, 'name'])}</label>
                <input type="text" onChange={handleUIChangeUserName} id="fname3" name="fname3" placeholder={getText(['account', 2, 'hint'])} value={userName} />
              </div>
            </div>
            <div className="form-row">
              <div className="third">
                <label htmlFor="fname4" className="rl-label">{getText(['account', 3, 'name'])}</label>
                <input type="text" onChange={handleUIChangeEmail} id="fname4" name="fname4" placeholder={getText(['account', 3, 'hint'])} value={email} />
              </div>
            </div>
            <br /><br />
            <h2 className="subsection-title">{getText('passwordTitle')}</h2>
            <hr className="line-separator" />
            <div className="form-row tk-mar-top-35">
              <div className="third">
                <label htmlFor={HTML_NAME_PASSWORD_1} className="rl-label required">{getText(['password', 1, 'name'])}</label>
                <input type="password" onChange={handleUIChangePassword1} id={HTML_NAME_PASSWORD_1} name={HTML_NAME_PASSWORD_1} placeholder={getText(['password', 1, 'hint'])} />
              </div>
            </div>
            <div className="form-row">
              <div className="third">
                <label htmlFor={HTML_NAME_PASSWORD_2} className="rl-label required">{getText(['password', 2, 'name'])}</label>
                <input type="password" onChange={handleUIChangePassword2} id={HTML_NAME_PASSWORD_2} name={HTML_NAME_PASSWORD_2} placeholder={getText(['password', 2, 'hint'])} />
              </div>
            </div>
          </div>
          <div className="content useraddedit-width left">
            <h2 className="subsection-title">{getText(['authorization', 'title'])}</h2>
            <hr className="line-separator" />
            <div className="form-row tk-mar-top-35">
              <div>
                <input type="checkbox" id="checkbox1" name="checkbox1" onChange={handleUIChangeCanSeeCost} checked={canSeeCost} />
                <label className="checkbox" htmlFor="checkbox1">
                  <span className="box">
                    <svg className="svg-check">
                      <use xlinkHref="#svg-check"></use>
                    </svg>
                  </span>
                  {getText(['authorization', 'auth_can_see_cost'])}
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="">
                <input type="checkbox" id="checkbox2" name="checkbox2" onChange={handleUIChangeCannotPassOrder} checked={cannotPassOrder} />
                <label className="checkbox" htmlFor="checkbox2">
                  <span className="box">
                    <svg className="svg-check">
                      <use xlinkHref="#svg-check"></use>
                    </svg>
                  </span>
                  {getText(['authorization', 'auth_cant_order'])}
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="">
                <input type="checkbox" id="checkbox3" name="checkbox3" onChange={handleUIChangeStorageOnly} checked={storageOnly} />
                <label className="checkbox" htmlFor="checkbox3">
                  <span className="box">
                    <svg className="svg-check">
                      <use xlinkHref="#svg-check"></use>
                    </svg>
                  </span>
                  {getText(['authorization', 'auth_ar_storage'])}
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="">
                <input type="checkbox" id="checkbox4" name="checkbox4" onChange={handleUIChangeTireOnly} checked={tireOnly} />
                <label className="checkbox" htmlFor="checkbox4">
                  <span className="box">
                    <svg className="svg-check">
                      <use xlinkHref="#svg-check"></use>
                    </svg>
                  </span>
                  {getText(['authorization', 'auth_ar_tire'])}
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="half">
                <label className="rl-label required">{getText(['account', 4, 'name'])}</label>
                <label htmlFor="user_type" className="select-block medium">
                  <select onChange={handleUIChangeUserType} name="user_type" id="user_type" value={userType}>
                    <option value={constants.USER_TYPE_USER}>{getText(['authorization', 'type_user'])}</option>
                    <option value={constants.USER_TYPE_ADMIN}>{getText(['authorization', 'type_admin'])}</option>
                    <option value={constants.USER_TYPE_COMPT}>{getText(['authorization', 'type_compt'])}</option>
                  </select>
                  <svg className="svg-arrow face-down">
                    <use xlinkHref="#svg-arrow"></use>
                  </svg>
                </label>
              </div>
            </div>
            <div className="form-row">
              <button onClick={handleUIButtonSave} className="button">{getText('saveButton')}</button>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default UserAddEdit;