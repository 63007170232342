import { useEffect, useState } from 'react';
import { JSON_SECTION_LOGIN_PAGE, loadSection, getTextByKey, getGeneralTextByKey } from '../../../services/languageService';
import * as validationService from '../../../services/validationService';
import * as constants from '../../../constants';
import './LoginPage.css';

function LoginPage(props) {

    const queryParameters = new URLSearchParams(window.location.search)
    const auto = queryParameters.get("auto");

    useEffect(() => {
        if (props.texts && auto){
            props.displayErrorMessage(getGeneralText(['Messages','ERROR_LOGIN_AUTO']));
        }
      }, [auto, props.texts]);

    
    

    var initValueAccount = constants.EMPTY;
    var initValueUser = constants.EMPTY;
    var initValuePassword = constants.EMPTY;
    var initValueRememberMe = false;
    
    if (props.loginInfo != null && props.loginInfo.loginRememberMe) {
        initValueAccount = props.loginInfo.loginAccount;
        initValueUser = props.loginInfo.loginUser;
        initValuePassword = props.loginInfo.loginPassword;
        initValueRememberMe = props.loginInfo.loginRememberMe;
    }

    const [noClient, setNoClient] = useState(initValueAccount);
    const [userCode, setUserCode] = useState(initValueUser);
    const [password, setPassword] = useState(initValuePassword);
    const [rememberMe, setRememberMe] = useState(initValueRememberMe);

    function getText(keyFields) {
        return getTextByKey(theTexts, keyFields, props.onTextNotFound)
    }

    function getGeneralText(keyFields) {
        return getGeneralTextByKey(theTexts, keyFields, props.onTextNotFound)
    }

    const handleChangeNoClient = (event) => {
        setNoClient(event.target.value);
    };

    function handleChangeRememberMe(event) {
        setRememberMe(!rememberMe);
    }

    const handleChangeUserCode = (event) => {
        setUserCode(event.target.value);
    };

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };


    function onKeyDown(event){
        if (event.key === "Enter") {
            doLogin();
        }
    }

    const doLogin = (e) => {
        if (
            validationService.validateStringNotEmpty(noClient) &&
            validationService.validateStringNotEmpty(userCode) &&
            validationService.validateStringNotEmpty(password)
        ) {
            props.performLogin(noClient, userCode, password, rememberMe);
        } else {
            props.displayErrorMessage(getGeneralText(['Messages','ERROR_LOGIN_ALL']));
        }
    }

    if (props.texts) {
        var theTexts = loadSection(JSON_SECTION_LOGIN_PAGE, props.texts, props.securityInfo);

        return (
            <>
                <div>
                    <div id="login-popup" className="form-popup medium">
                        <div className="form-popup-column small">
                            <h2 className="subsection-title medium">{getText('pubTitle')}</h2>
                            <hr className="line-separator" />

                            <form>
                                <div className="form-row tk-text-center">
                                    <img className="login-page-brand-image" src={getText('pubTopImage')} alt="start01" />
                                </div>

                                <div className="form-row tk-text-center">
                                    <img className="login-page-tire-image" src={getText('pubBottomImage')} alt="start03" />
                                </div>

                                <div className="form-row separated">
                                    <button className="button dark full">{getText('pubButton')}</button>
                                </div>
                            </form>
                        </div>

                        <div className="form-popup-column-separator"></div>

                        <div className="form-popup-column small">
                            <h2 className="subsection-title medium">{getText('loginTitle')}</h2>
                            <hr className="line-separator" /><br />

                            <div className="form-row">
                                <label htmlFor="noClient" className="rl-label">{getText('loginNoClient')}</label>
                                <input type="text" id="noClient" name="noClient" onChange={handleChangeNoClient} defaultValue={initValueAccount} placeholder={getText('loginNoClientHint')} />
                            </div>

                            <div className="form-row">
                                <label htmlFor="userCode" className="rl-label">{getText('loginUserCode')}</label>
                                <input type="text" id="userCode" name="userCode" onChange={handleChangeUserCode} defaultValue={initValueUser}placeholder={getText('loginUserCodeHint')} />
                            </div>

                            <div className="form-row">
                                <label htmlFor="password" className="rl-label">{getText('loginPassword')}</label>
                                <input type="password" id="password" name="password" onChange={handleChangePassword} onKeyDown={onKeyDown} defaultValue={initValuePassword} placeholder={getText('loginPasswordHint')} />
                            </div>

                            <div className="form-row">
                                <input type="checkbox" id="keep_logged" name="keep_logged" onChange={handleChangeRememberMe} checked={rememberMe} />
                                <label className="checkbox" htmlFor="keep_logged">
                                    <span className="box">
                                        <svg className="svg-check">
                                            <use xlinkHref="#svg-check"></use>
                                        </svg>
                                    </span>
                                    {getText('loginRememberMe')}
                                </label>
                            </div>

                            <div className="form-row short-space separated">
                                <button className="button full" onClick={doLogin}>{getText('loginButton')}</button>
                            </div>

                        </div>
                    </div>

                </div>

            </>
        );
    } else {
        return (<></>)
    }
}

export default LoginPage;