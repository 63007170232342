import * as constants from '../../constants';

function manageErrorCode(err, errorHandler) {

    if (err.statusCode === 401) {
        window.location = constants.ROUTE_LOGOUT_LOGIN;
    } else {
        errorHandler("Error");
    }
}

function setInCache(key, data, cache) {
    if (cache) {
        cache.setInCache(key, data);
    }

    return data;
}

export function asyncGetSecured(endPoint, jsonToken, successCallback, errorCallback, cacheInstance) {

    if (cacheInstance && cacheInstance.isInCache(endPoint)) {
        successCallback(cacheInstance.getFromCache(endPoint));
    } else {
        fetch(endPoint, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + jsonToken,
            }
        })
            .then(response => {
                if (!response.ok) {
                    var error = new Error("Bad Return Code");
                    error.statusCode = response.status;
                    throw error;
                }
                return response;
            })
            .then(response => response.json())
            .then(data => setInCache(endPoint, data, cacheInstance))
            .then(data => successCallback(data))
            .catch((err) => {
                manageErrorCode(err, errorCallback);
            });
    }
}

export function asyncDeleteSecured(endPoint, jsonToken, successCallback, errorCallback) {
    fetch(endPoint, {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + jsonToken,
        }
    })
        .then(response => {
            if (!response.ok) {
                var error = new Error("Bad Return Code");
                error.statusCode = response.status;
                throw error;
            }
            return response;
        })
        .then(data => successCallback(data))
        .catch((err) => {
            manageErrorCode(err, errorCallback);
        });
}

export function asyncGet(endPoint, successCallback, errorCallback) {
    fetch(endPoint, {
        method: 'GET'
    })
        .then(response => {
            if (!response.ok) {
                var error = new Error("Bad Return Code");
                error.statusCode = response.status;
                throw error;
            }
            return response;
        })
        .then(response => response.json())
        .then(data => successCallback(data))
        .catch((err) => {
            manageErrorCode(err, errorCallback);
        });
}

export function asyncPatchSecured(endPoint, jsonToken, jsonData, successCallback, errorCallback) {
    fetch(endPoint, {
        method: 'PATCH',
        body: JSON.stringify(jsonData),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + jsonToken,
        },
    })
        .then(response => {
            if (!response.ok) {
                var error = new Error("Bad Return Code");
                error.statusCode = response.status;
                throw error;
            }
            return response;
        })
        .then((response) => response.json())
        .then(data => successCallback(data))
        .catch((err) => {
            manageErrorCode(err, errorCallback);
        });
}

export function asyncPostSecured(endPoint, jsonToken, jsonData, successCallback, errorCallback) {
    fetch(endPoint, {
        method: 'POST',
        body: JSON.stringify(jsonData),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + jsonToken,
        },
    })
        .then(response => {
            if (!response.ok) {
                var error = new Error("Bad Return Code");
                error.statusCode = response.status;
                throw error;
            }
            return response;
        })
        .then((response) => response.json())
        .then(data => successCallback(data))
        .catch((err) => {
            manageErrorCode(err, errorCallback);
        });
}

export function asyncPost(endPoint, jsonData, successCallback, errorCallback) {
    fetch(endPoint, {
        method: 'POST',
        body: JSON.stringify(jsonData),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
    })
        .then(response => {
            if (!response.ok) {
                var error = new Error("Bad Return Code");
                error.statusCode = response.status;
                throw error;
            }
            return response;
        })
        .then((response) => response.json())
        .then(data => successCallback(data))
        .catch((err) => {
            manageErrorCode(err, errorCallback);
        });
}