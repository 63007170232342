import { useState } from 'react';
import { JSON_SECTION_CONVERTER, loadSection, getTextByKey } from '../../../../services/languageService';
import './Converter.css';
import ComparePage from './ComparePage';
import ConverterPage from './ConverterPage';

function Converter(props) {

    const TAB_CONVERT = '0';
    const TAB_COMPARE = '1';

    const [tabShowed, setTabShowed] = useState(TAB_CONVERT);

    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    function changeTab(tabToShow) {
        setTabShowed(tabToShow);
    }

    var tab = null;
    var tab1Css = 'button long tk-cursor-pointer';
    var tab2Css = 'button converter-width-330 dark tk-cursor-pointer';

    if (tabShowed === TAB_CONVERT) {
        tab = <ConverterPage securityInfo={props.securityInfo} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} onTextNotFound={props.onTextNotFound} />
    } else {
        tab = <ComparePage securityInfo={props.securityInfo} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} onTextNotFound={props.onTextNotFound} />
        tab1Css = 'button long dark tk-cursor-pointer';
        tab2Css = 'button converter-width-330 tk-cursor-pointer';
    }

    if (props.texts) {
        var txtSection = loadSection(JSON_SECTION_CONVERTER, props.texts, props.securityInfo);

        return (
            <>
                <div className="tk-grid tk-bgcolor-white converter-column">
                    <div className="converter-base-main-div">
                        <div className="converter-base-submain-div">
                            <div className="tk-grid tk-bgcolor-white converter-tab-row">
                                <div onClick={() => changeTab(TAB_CONVERT)}><a className={tab1Css}>{getText('button1')}</a></div>
                                <div className="tk-pad-l-20" onClick={() => changeTab(TAB_COMPARE)}> <a className={tab2Css}>{getText('button2')}</a> </div>
                                <div className="converter-top-right"><i onClick={() => props.closeMethod()} className="fa fa-times tk-cursor-pointer" aria-hidden="true"></i></div>
                            </div>
                            <div className="tk-min-height-20 tk-height-20" />
                            <div className="converter-gray-line" />
                            <div className="tk-min-height-20 tk-height-20" />
                            {tab}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Converter;
