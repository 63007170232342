import * as constants from '../../../constants';

function Localization(props) {
    if (props.level1 != null && props.level1 !== 'undefined' && props.level1 !== constants.EMPTY) {
      return (
        <>
          <div className="section-navigation-wrap">
            <div className="section-navigation">
              <p className="section-navigation-path">
                <span className="path">{props.level1}</span>
                <span className="path bold">
                  <svg className="svg-arrow tiny">
                    <use xlinkHref="#svg-arrow"></use>
                  </svg>
                </span>
                <span className="path current">{props.level2}</span>
              </p>
            </div>
          </div>
        </>
      );

    } else {
      return (<></>);
    }
}
export default Localization;
