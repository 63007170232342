import ComplexSubMenuItem from "../ComplexSubMenuItem/ComplexSubMenuItem";
import SubMenuItem from "../SubMenuItem/SubMenuItem";
import './MenuItem.css';

function MenuItem(props) {

  function internGoToRoute(url) {
    props.goToRoute(url);
  }

  var menu = [];

  if (props.menus) {
    if (props.bgimage) {
      return (
        <li className="main-menu-item sub">
          <a href={props.href}>{props.name}
            <svg className="svg-arrow face-down">
              <use xlinkHref="#svg-arrow"></use>
            </svg>
          </a>
          <ComplexSubMenuItem goToRoute={props.goToRoute} key="ComplexSubMenuItem" menus={props.menus} />
        </li>
      );
    } else {
      for (let i = 0; i < props.menus.length; i++) {
        menu.push(<SubMenuItem goToRoute={props.goToRoute} key={i} name={props.menus[i].name} route={props.menus[i].route} href={props.menus[i].href} click={props.menus[i].javascript} />);
      }
      return (
        <li className="main-menu-item sub">
          <a href={props.href}>{props.name}
            <svg className="svg-arrow face-down">
              <use xlinkHref="#svg-arrow"></use>
            </svg>
          </a>
          <ul className="menu-dropdown">
            {menu}
          </ul>
        </li>
      );
    }


  } else {
    if (props.href) {
      return (
        <li className="main-menu-item">
          <a href={props.href}>{props.name}</a>
        </li>
      );
    } else if (props.route) {
      return (
        <li className="main-menu-item">
          <div className='menuItemFirstLevelJS tk-cursor-pointer' onClick={() => internGoToRoute(props.route)}>{props.name}</div>
        </li>
      );
    } else if (props.javascript) {
      return (
        <li className="main-menu-item">
          <div className='menuItemFirstLevelJS tk-cursor-pointer' onClick={() => window[props.click]()}>{props.name}</div>
        </li>
      );
    }

  }

}

export default MenuItem;