import { JSON_SECTION_S1_TOP_MENU, getTextByKey, loadSection } from '../../../services/languageService';
import * as validationService from '../../../services/validationService';
import * as constants from '../../../constants';
import S1TopMenuItem from './S1TopMenuItem/S1TopMenuItem';
import './S1TopMenu.css';

function S1TopMenu(props) {
  function getText(keyFields) {
    return getTextByKey(theTexts, keyFields, props.onTextNotFound)
  }

  function internGoToRoute(url) {
    props.goToRoute(url);
  }

  if (props.texts) {
    var theTexts = loadSection(JSON_SECTION_S1_TOP_MENU, props.texts, props.securityInfo);

    var menu = [];
    var menuSmall = [];

    if (validationService.isLogged(props.securityInfo)) {
      menu.push(<S1TopMenuItem goToRoute={props.goToRoute} key='welcomeMenu' small={false} securityInfo={props.securityInfo} menuType='welcomeMenu' menu={theTexts} />);
    }

    for (let i = 0; i < theTexts.menus.length; i++) {

      var show = true;
      if (theTexts.menus[i].role === constants.ROLE_ADMIN  && !(validationService.isAdmin(props.securityInfo))) {
        show = false;
      }

      if (show){
        menu.push(<S1TopMenuItem goToRoute={props.goToRoute} small={false} key={i} securityInfo={props.securityInfo} menuType='normalMenu' menu={theTexts.menus[i]} />);
        menuSmall.push(<S1TopMenuItem goToRoute={props.goToRoute} small={true} key={i} securityInfo={props.securityInfo} menuType='normalMenu' menu={theTexts.menus[i]} />);
      }
    }

    return (
      <>
        <div className="widget-line-wrap widget-line-2 S1-topmenu-topMenuColor">
          <div className="widget-line">
            <ul className="actions-list left">
              <li className="actions-list-item">
                <div className="social-link S1-topmenu-topMenuColor">
                  <a target="_blank" rel="noreferrer" href={theTexts.CommonTexts.Linkedin}>
                    <i className="fa fa-linkedin"></i>
                  </a>
                </div>
              </li>
            </ul>
            <ul className="actions-list right S1-topmenu-vertcenter">
              {menuSmall}
            </ul>
          </div>
        </div>
        <div className="widget-line-wrap widget-line-1 S1-topmenu-topMenuColor">
          <div className="widget-line">
            <ul className="actions-list left">
              <li className="actions-list-item">
                <div className="social-link S1-topmenu-topMenuColor">
                  <a target="_blank" rel="noreferrer" href={theTexts.CommonTexts.Linkedin}>
                    <i className="fa fa-linkedin"></i>
                  </a>
                </div>
              </li>
            </ul>

            <ul className="actions-list left S1-topmenu-vertcenter S1-topmenu-topMenuColor">
              <li className="actions-list-item S1-topmenu-vertcenter S1-topmenu-noborder tk-mar-l-40">
                <img className="S1-topmenu-phone-img" alt="" src={getText('phoneImageUrl')}/>
              </li>
              <li className="actions-list-item S1-topmenu-noborder S1-topmenu-topMenuColor">
                <i className="action-button">{getText('help')}</i>
              </li>
            </ul>

            <ul className="actions-list right S1-topmenu-vertcenter">
              {menu}
            </ul>
          </div>
        </div>
      </>
    );
  } else {
    return (<></>);
  }
}
export default S1TopMenu;
