import TireAvailability from './TireAvailability/TireAvailability';
import TireCounter from './TireCounter/TireCounter';
import TireDescription from './TireDescription/TireDescription';
import TirePrice from './TirePrice/TirePrice';
import './TireResult.css';
import TireSubmition from './TireSubmition/TireSubmition';
import TireNail from './TireNail/TireNail';
import { JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_BANNERS, loadSubSection, getTextByKey } from '../../../../../../services/languageService';
import * as constants from '../../../../../../constants';
import React, { useState } from 'react';

function TireResult(props) {

    var theTexts = props.texts;

    const [removePopup, setRemovePopup] = useState(false);

    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    function todo() {

    }

    function removePopups() {
        setRemovePopup(Math.random());
    }

    function buildExternalAvailabilityTable() {
        if (props.jsonData.delays)
            return (
                <div className="tire-result-external-availability frb-font-medium-large">
                    <div className='tire-result-external-availability-head frb-font-bold tk-grid tire-result-external-availability-grid'>
                        <div className="tk-text-center tk-pad-top-7">QTE</div>
                        <div className="tk-pad-top-7">Délai de livraison</div>
                        <div className="tk-pad-top-7">Frais d'expédition/Unité</div>
                        <div className="tk-pad-top-7">Frais de livraison totaux</div>
                        <div className="tk-pad-top-7">Commander</div>
                    </div>
                    <div className='tire-result-external-availability-detail tk-grid tire-result-external-availability-grid'>
                        <div className="tk-text-center tk-pad-top-12">4</div>
                        <div className="tk-pad-top-12">Demain avant 17:00, de: Montréal</div>
                        <div className="tk-pad-top-12">15.00$/pneu</div>
                        <div className="tk-pad-top-12">60.00$</div>
                        <div className="tk-pad-top-8 tk-cursor-pointer" onClick={todo}><span className="button tire-result-button-search frb-font-light-bold">Commander</span></div>

                        <div className="tk-text-center tk-pad-top-12">4</div>
                        <div className="tk-pad-top-12">Demain avant 17:00, de: Montréal</div>
                        <div className="tk-pad-top-12">15.00$/pneu</div>
                        <div className="tk-pad-top-12">60.00$</div>
                        <div className="tk-pad-top-8 tk-cursor-pointer" onClick={todo}><span className="button tire-result-button-search frb-font-light-bold">Commander</span></div>
                    </div>
                </div>
            );
    }

    if (theTexts) {

        var isCombo = props.isCombo;
        var isFront = props.isFront;

        var cssCombo = 'tire-result-right-column';

        if (isCombo) {
            cssCombo = 'tire-result-right-column-no-margin';
        }

        var txtSection = loadSubSection(JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_BANNERS, theTexts, props.securityInfo);

        var infoLines = [];
        var borderColorCss = cssCombo + constants.SPACE + 'frb-border-3-gray';

        if (isCombo) {
            borderColorCss = cssCombo;
        }

        if (isCombo && isFront) {
            borderColorCss = cssCombo + constants.SPACE + 'tire-result-combo-bottom';
        }

        if (props.jsonData.isEndProduction) {
            borderColorCss = cssCombo + constants.SPACE + 'frb-border-3-yellow';
            infoLines.push(
                <div key='eop' className={cssCombo + constants.SPACE + 'tk-pad-top-10'}>
                    <table className='tire-result-yellow-header-table' cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td className='tire-result-yellow-header-column-1 frb-bgcolor-yellow'>
                                    <i className="fa fa-exclamation" aria-hidden="true"></i>
                                </td>
                                <td className='tire-result-yellow-header-column-2 frb-bgcolor-yellow frb-font-large frb-font-light-bold tk-uppercase'>
                                    {getText('endOfProduction')}
                                </td>
                                <td className='tire-result-yellow-header-column-3 frb-bgcolor-yellow frb-font-small'>
                                    {getText('endOfProductionMsg')}
                                </td>
                                <td className='tire-result-yellow-header-column-4 frb-bgcolor-yellow tk-color-white tk-cursor-pointer'>
                                    {getText('endOfProductionMsg2')}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }

        if (props.jsonData.isEquivalent) {
            borderColorCss = cssCombo + constants.SPACE + 'frb-border-3-orange frb-bgcolor-orange';
            infoLines.push(
                <div key='equivalent' className={cssCombo + constants.SPACE + 'tk-pad-top-10'}>
                    <table className='tire-result-orange-header-table' cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td className='tire-result-orange-header-column-1 frb-bgcolor-orange'>
                                    <i className="fa fa-exclamation" aria-hidden="true"></i>
                                </td>
                                <td className='tire-result-orange-header-column-2 frb-bgcolor-orange frb-font-large frb-font-light-bold tk-uppercase'>
                                    {getText('equivalence')}
                                </td>
                                <td className='tire-result-orange-header-column-3 frb-bgcolor-orange frb-font-small'>
                                    {getText('equivalenceMsg')}
                                </td>
                                <td className='tire-result-orange-header-column-4 tk-bgcolor-black tk-color-white tk-cursor-pointer' onClick={window['frb_js_toggleConverter']}>
                                    {getText('equivalenceMsg2')}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );

        }


        //if (props.jsonData.isMultipleTires) {
        //borderColorCss = 'tire-result-right-column frb-border-3-black';
        //}

        var nailable = constants.EMPTY;

        if (props.jsonData.nailable) {
            nailable = (
                <div className="tire-result-nail-section">
                    <TireNail isCombo={isCombo} onTextNotFound={props.onTextNotFound} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} securityInfo={props.securityInfo} />
                </div>
            );
        }

        var dispoAndCommSection = (
            <div className="tire-result-span-2-columns frb-border-left-1-gray">
                <div className="tire-result-dispo-and-comm-section">
                    <div className="tk-grid tire-result-grid-disp-soum-2-columns">
                        <div className="tk-div-align-content-vh-center"><TireAvailability jsonData={props.jsonData.availability} onTextNotFound={props.onTextNotFound} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} securityInfo={props.securityInfo} /></div>
                        <div className="frb-border-left-1-gray tk-div-align-content-vh-center"><TireSubmition jsonData={props.jsonData} onTextNotFound={props.onTextNotFound} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} securityInfo={props.securityInfo} /></div>
                    </div>
                </div>
                {nailable}
            </div>
        );

        if (props.hidePrice) {
            return (
                <>
                    {infoLines}
                    <div className={borderColorCss} onMouseLeave={removePopups}>
                        <div className="tk-grid tk-bgcolor-white tire-result-grid-sizes-no-price">
                            <div><TireDescription jsonData={props.jsonData} onTextNotFound={props.onTextNotFound} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} securityInfo={props.securityInfo} /></div>
                            <div className="tk-div-align-content-vh-center frb-border-left-1-gray tk-grid-col-span-2"><TireCounter removePopup={removePopup} jsonData={props.jsonData.counter} onTextNotFound={props.onTextNotFound} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} securityInfo={props.securityInfo} /></div>
                            {dispoAndCommSection}
                        </div>
                        {buildExternalAvailabilityTable()}
                    </div>
                </>
            );

        } else {
            return (
                <>
                    {infoLines}
                    <div className={borderColorCss} onMouseLeave={removePopups}>
                        <div className="tk-grid tk-bgcolor-white tire-result-grid-sizes">
                            <div><TireDescription jsonData={props.jsonData} onTextNotFound={props.onTextNotFound} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} securityInfo={props.securityInfo} /></div>
                            <div className="tk-div-align-content-vh-center frb-border-left-1-gray"><TirePrice maskPrice={props.maskPrice} jsonData={props.jsonData.prices} onTextNotFound={props.onTextNotFound} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} securityInfo={props.securityInfo} /></div>
                            <div className="tk-div-align-content-vh-center frb-border-left-1-gray"><TireCounter removePopup={removePopup} jsonData={props.jsonData.counter} onTextNotFound={props.onTextNotFound} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} securityInfo={props.securityInfo} /></div>
                            {dispoAndCommSection}
                        </div>
                        {buildExternalAvailabilityTable()}
                    </div>
                </>
            );
        }
    }
}

export default TireResult;