import { JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_RESULT, loadSubSection, getTextByKey } from '../../../../../../../services/languageService';
import './TireNail.css';

function TireNail(props) {

    var theTexts = props.texts;
    var isCombo = props.isCombo;

    var className = isCombo ? 'form-row frb-border-top-1-gray tirenail-width-combo' : 'form-row frb-border-top-1-gray tirenail-width'
    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    var checkBoxName = Math.random();

    if (theTexts) {
        var txtSection = loadSubSection(JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_RESULT, theTexts, props.securityInfo);
        return (
            <>
                <div className={className}>
                    <div className="tk-pad-l-30">
                        <input type="checkbox" id={checkBoxName} name={checkBoxName} />
                        <label className="checkbox" htmlFor={checkBoxName}>
                            <span className="box">
                                <svg className="svg-check">
                                    <use xlinkHref="#svg-check">
                                    </use>
                                </svg>
                            </span>
                            <span className='frb-font-lato tk-color-black frb-font-small frb-font-bold'>{getText('nailTitle')}</span>
                        </label>
                    </div>
                    <div className="tk-pad-l-30">
                        <p className='frb-font-lato frb-font-xsmall'>{getText('nailNote')}</p>
                    </div>
                </div>
            </>
        );
    }
}

export default TireNail;