import * as constants from '../constants';
import * as validationService from './validationService';
import * as stringService from './stringService';

export function getOtherQuantity(backEndObject) {
    var toReturn = 0;

    if (validationService.isNumber(backEndObject.oneDayQty)) {
        toReturn = backEndObject.oneDayQty;
    }

    if (validationService.isNumber(backEndObject.twoDayQty)) {
        toReturn = toReturn + backEndObject.twoDayQty;
    }

    return toReturn;
}

export function convertTire(backEndObject, partnerList) {

    var partner = null;
    var brand = backEndObject.brand; //OK

    if (partnerList && validationService.isString(brand)) {
        for (let i = 0; i < partnerList.length; i++) {
            var cur = partnerList[i].brand.toUpperCase();

            if (cur === brand.toUpperCase()) {
                partner = brand;
                break;
            }
        }
    }

    var discount = false;
    var isEndProduction = backEndObject.discontinued === true || backEndObject.discontinued === "true"; //OK
    var equivalence = backEndObject.equivalence === true || backEndObject.equivalence === "true"; //OK
    var productNumber = backEndObject.productNumber; //OK
    var tireLoadType = backEndObject.tireLoadType; //OK
    var productSize = backEndObject.size; //OK
    var color = backEndObject.wall_color; //OK
    var performance = backEndObject.performance; //OK
    var runflat = backEndObject.runflat === true || backEndObject.runflat === "true"; //OK
    var nailable = backEndObject.nailable === true || backEndObject.nailable === "true"; //OK
    var season = backEndObject.season; //OK

    var pDiscountPdf = null;
    var pDiscountStart = null;
    var pDiscountEnd = null

    if (backEndObject.brandDiscountPdf) {
        pDiscountPdf = constants.PDF_DIR_URL + backEndObject.brandDiscountPdf;
    }

    if (backEndObject.brandDiscountEnd) {
        try {
            pDiscountEnd = backEndObject.brandDiscountEnd.substring(0, 10);
        } catch (error) {
            pDiscountEnd = '?';
        }
    }

    if (backEndObject.brandDiscountStart) {
        try {
            pDiscountStart = backEndObject.brandDiscountStart.substring(0, 10);
        } catch (error) {
            pDiscountStart = '?';
        }

    }

    var seasonImg = ''; //OK
    var seasonTxt = ''; //Should Not be hardcoded
    if ((season + constants.EMPTY) === constants.TIRE_TYPE_WINTER) {
        seasonImg = "/frb/images/common/tiretypes/winter.png";
        seasonTxt = 'Pneu d\'hiver';
    }
    else if ((season + constants.EMPTY) === constants.TIRE_TYPE_SUMMER) {
        seasonImg = "/frb/images/common/tiretypes/summer.png";
        seasonTxt = 'Pneu d\'été';
    }
    else if ((season + constants.EMPTY) === constants.TIRE_TYPE_HOMOLOGUED) {
        seasonImg = "/frb/images/common/tiretypes/homologued.png";
        seasonTxt = 'Pneu homologué';
    }
    else if ((season + constants.EMPTY) === constants.TIRE_TYPE_4SEASONS) {
        seasonImg = "/frb/images/common/tiretypes/4seasons.png";
        seasonTxt = 'Pneu 4 saisons';
    }
    var utqg = backEndObject.utqg //OK


    var counterPrice = stringService.numberToNumberWith2Decimals(backEndObject.counterPrice); //OK
    var counterPriceOriginal = null;

    if (backEndObject.struckCounterPrice) {
        counterPriceOriginal = stringService.numberToNumberWith2Decimals(backEndObject.struckCounterPrice); //OK
        discount = true;
    }

    var p = stringService.numberToNumberWith2Decimals(backEndObject.costPrice); //OK
    var pDiscount = null;

    if (backEndObject.struckCostPrice) {
        pDiscount = p;
        p = stringService.numberToNumberWith2Decimals(backEndObject.struckCostPrice); //OK
    }

    //TODO - e change si pDiscount
    var e = stringService.numberToNumberWith2Decimals(100 - ((backEndObject.costPrice / backEndObject.basePrice) * 100));
    var m = stringService.numberToNumberWith2Decimals(backEndObject.counterPrice - backEndObject.costPrice);
    var pDiscountHide = stringService.getFirst2LettersUppercase(brand) + '' + Math.round(pDiscount) + stringService.getLast2LettersUppercase(brand);
    var pHide = stringService.getFirst2LettersUppercase(brand) + '' + Math.round(p) + stringService.getLast2LettersUppercase(brand);
    var eHide = stringService.getFirst2LettersUppercase(brand) + '' + Math.round(e) + stringService.getLast2LettersUppercase(brand);
    var mHide = stringService.getFirst2LettersUppercase(brand) + '' + Math.round(m) + stringService.getLast2LettersUppercase(brand);

    var brandDiscount = backEndObject.brandDiscount; //OK

    var localQty = backEndObject.localQty; //OK
    var otherQty = getOtherQuantity(backEndObject);

    var toReturn = {
        "raw": backEndObject,  //OK
        "display": true,       //OK
        "discount": discount,   //OK
        "isEndProduction": isEndProduction, //OK
        "isEquivalent": equivalence, //OK
        "tireImageUrl": constants.TIRE_IMAGE_DIR_URL + backEndObject.imageSide, //OK
        "brandImageUrl": constants.BRAND_IMAGE_DIR_URL + backEndObject.imageBrand, //OK
        "typeImageUrl": seasonImg, //OK
        "type": season, //OK
        "typeName": seasonTxt, //OK
        "name": backEndObject.name, //OK
        "number": productNumber,
        "size": productSize,
        "sizePercent": "",
        "charge": tireLoadType,
        "performance": performance,
        "color": color,
        "runflat": runflat,
        "nailable": nailable,
        "utqg": utqg,
        "prices": {
            "p": p,
            "pdsf": counterPrice,
            "pDiscount": pDiscount,
            "e": e,
            "m": m,
            "pHide": pHide,
            "eHide": eHide,
            "mHide": mHide,
            "pDiscountHide": pDiscountHide,
            "partner": partner // OK
        },
        "counter": {
            "price": counterPrice,
            "priceOriginal": counterPriceOriginal,
            "dealerDiscount": brandDiscount,
            "pDiscountStartDate": pDiscountStart,
            "pDiscountEndDate": pDiscountEnd,
            "pDiscountPdf": pDiscountPdf
        },
        "availability": {
            "local": localQty,
            "external": otherQty
        }

    }

    return toReturn;
}

export function updatePriceRange(jsonData) {
    var max = 0;
    for (let i = 0; i < jsonData.results.length; i++) {
        var current = jsonData.results[i];

        if (current.front && current.front.prices.pdsf > max) {
            max = current.front.prices.pdsf;
        }

        if (current.back && current.back.prices.pdsf > max) {
            max = current.back.prices.pdsf;
        }
    }

    var newValue = Math.ceil(max);

    if (newValue % 10 !== 0) {
        newValue = newValue + (10 - (newValue % 10));
    }

    jsonData.summary.priceRangeMax = newValue;
}

export function convertTireSearch(backEndObject, itemPerPage, partnerList) {

    var resultArray = [];

    if (backEndObject.tireComboList) {
        for (let i = 0; i < backEndObject.tireComboList.length; i++) {
            var current = backEndObject.tireComboList[i];

            var frontTire = null;
            var backTire = null;

            var filter_checkbox_runflat = null;
            var filter_checkbox_category = null;
            var filter_checkbox_brand = null;
            var filter_checkbox_nail_option = null;
            var filter_checkbox_charge_min = null;
            var filter_checkbox_charge = null;
            var filter_checkbox_speed_min = null;
            var filter_checkbox_color = null;
            var filter_price_slider = null;
            var combineDispoLocal = null;
            var combineDispoTotal = null;
            var averageCounterPrice = null;
            var averageCostPrice = null;

            if (current.front && !current.back) {
                frontTire = convertTire(current.front, partnerList);
                filter_checkbox_runflat = frontTire.raw.runflat;
                filter_checkbox_category = frontTire.raw.season;
                filter_checkbox_brand = frontTire.raw.brand;
                filter_checkbox_nail_option = frontTire.raw.nailable;
                filter_checkbox_charge_min = frontTire.raw.minLoad;
                filter_checkbox_charge = frontTire.raw.tireLoadType;
                filter_checkbox_speed_min = frontTire.raw.speedIndex;
                filter_checkbox_color = frontTire.raw.wall_color;
                combineDispoLocal = frontTire.availability.local;
                combineDispoTotal = frontTire.availability.local + frontTire.availability.external;
                averageCounterPrice = frontTire.counter.price;
                averageCostPrice = frontTire.prices.p;
                filter_price_slider = frontTire.counter.price;
            }

            else if (!current.front && current.back) {
                backTire = convertTire(current.back, partnerList);
                filter_checkbox_runflat = backTire.raw.runflat;
                filter_checkbox_category = backTire.raw.season;
                filter_checkbox_brand = backTire.raw.brand;
                filter_checkbox_nail_option = backTire.raw.nailable;
                filter_checkbox_charge_min = backTire.raw.minLoad;
                filter_checkbox_charge = backTire.raw.tireLoadType;
                filter_checkbox_speed_min = backTire.raw.speedIndex;
                filter_checkbox_color = backTire.raw.wall_color;
                combineDispoLocal = backTire.availability.local;
                combineDispoTotal = backTire.availability.local + backTire.availability.external;
                averageCounterPrice = backTire.counter.price;
                averageCostPrice = backTire.prices.p;
                filter_price_slider = backTire.counter.price;
            }
            else if (current.front && current.back) {
                frontTire = convertTire(current.front, partnerList);
                backTire = convertTire(current.back, partnerList);
                filter_checkbox_runflat = (frontTire.raw.runflat === backTire.raw.runflat) ? frontTire.raw.runflat : null;
                filter_checkbox_category = (frontTire.raw.season === backTire.raw.season) ? frontTire.raw.season : null;
                filter_checkbox_brand = (frontTire.raw.brand === backTire.raw.brand) ? frontTire.raw.brand : null;
                filter_checkbox_nail_option = (frontTire.raw.nailable === backTire.raw.nailable) ? frontTire.raw.nailable : null;
                filter_checkbox_charge_min = (frontTire.raw.minLoad === backTire.raw.minLoad) ? frontTire.raw.minLoad : null;
                filter_checkbox_charge = (frontTire.raw.loadType === backTire.raw.loadType) ? frontTire.raw.loadType : null;
                filter_checkbox_speed_min = (frontTire.raw.speedIndex === backTire.raw.speedIndex) ? frontTire.raw.speedIndex : null;
                filter_checkbox_color = (frontTire.raw.wall_color === backTire.raw.wall_color) ? frontTire.raw.wall_color : null;
                combineDispoLocal = frontTire.availability.local + backTire.availability.local;
                combineDispoTotal = frontTire.availability.local + frontTire.availability.external + backTire.availability.local + backTire.availability.external;
                averageCounterPrice = (frontTire.counter.price + backTire.counter.price) / 2;
                averageCostPrice = (frontTire.prices.p + backTire.prices.p) / 2;
                filter_price_slider = [frontTire.counter.price, backTire.counter.price];
            }

            var toPush = {
                "front": frontTire,
                "back": backTire,
                "originalOrder": (i + 1),
                "sort": {
                    "combineDispoLocal": combineDispoLocal,
                    "combineDispoTotal": combineDispoTotal,
                    "averageCounterPrice": averageCounterPrice,
                    "averageCostPrice": averageCostPrice
                },
                "filters": {
                    "filter_price_slider": filter_price_slider,
                    "filter_checkbox_runflat": filter_checkbox_runflat,
                    "filter_checkbox_category": filter_checkbox_category,
                    "filter_checkbox_brand": filter_checkbox_brand,
                    "filter_checkbox_nail_option": filter_checkbox_nail_option,
                    "filter_checkbox_charge_min": filter_checkbox_charge_min,
                    "filter_checkbox_charge": filter_checkbox_charge,
                    "filter_checkbox_speed_min": filter_checkbox_speed_min,
                    "filter_checkbox_color": filter_checkbox_color
                },
                "display": true
            }
            resultArray.push(toPush);
        }
    }



    var jsonData = {
        "summary": {
            "productTotal": (backEndObject.tireComboList.length),
            "productDisplay": itemPerPage,
            "pageToDisplay": 1,
            "priceRangeMin": 0,
            "priceRangeMax": 0
        },
        "results": resultArray
    }

    updatePriceRange(jsonData);

    return jsonData;
}

export function cleanFilters(filterValues) {
    var toReturn = new Map();

    var keys = filterValues.keys();

    let result = keys.next();
    while (!result.done) {
        var currentCat = result.value;
        var selectedValues = [];

        var filterType = filterValues.get(currentCat).filterType;

        if (filterType === constants.FILTER_TYPE_SLIDER) {
            toReturn.set(currentCat, filterValues.get(currentCat));
        } else {
            var values = filterValues.get(currentCat).selection;

            for (let i = 0; i < values.length; i++) {
                var curValue = values[i];
                if (curValue.checked) {
                    selectedValues.push(curValue);
                }
            }

            if (selectedValues.length > 0) {
                var tempo = filterValues.get(currentCat)
                tempo.selection = selectedValues;
                toReturn.set(currentCat, tempo);
            }
        }
        result = keys.next();
    }

    return toReturn;
}

export function checkTireMustShow(filter, tire) {
    var filterType = filter.filterType;
    var toReturn = false;
    var current = tire;
    var attribute = '';
    if (Array.isArray(filter.dataSource)) {
        for (let j = 0; j < filter.dataSource.length - 1; j++) {
            current = current[filter.dataSource[j]];
        }
        attribute = filter.dataSource[filter.dataSource.length - 1];
    } else {
        attribute = filter.dataSource;
    }

    var theValue = current[attribute];

    if (validationService.isString(theValue) || validationService.isNumber(theValue) || validationService.isBoolean(theValue) || ((filterType === constants.FILTER_TYPE_SLIDER) && validationService.isArray(theValue))) {

        if (filterType === constants.FILTER_TYPE_SLIDER) {
            var min = filter.selection[0];
            var max = filter.selection[1];


            if (validationService.isArray(theValue)) {
                var allTrue = true;
                for (let v = 0; v < theValue.length; v++) {

                    current = theValue[v];
                    if (!validationService.isBetweenOrEquals(current, min, max)) {
                        allTrue = false;
                        break;
                    }
                }
                toReturn = allTrue;
            } else {
                toReturn = validationService.isBetweenOrEquals(theValue, min, max);
            }
        } else {
            theValue = theValue + constants.EMPTY;
            //Must be one of the value
            var valueList = filter.selection;

            var found = false;
            for (let i = 0; i < valueList.length; i++) {
                var result = valueList[i];
                if (result.value === (theValue + constants.EMPTY)) {
                    found = true;
                    break;
                }
            }
            toReturn = found;
        }
    } else {
        console.log('FILTER ERROR: FIELD NOT FOUND: ' + filter.dataSource);
    }

    return toReturn;
}

export function filterTireResult(tireSearchResult, filterValues) {
    var clone = JSON.parse(JSON.stringify(tireSearchResult));
    var filters = cleanFilters(filterValues);
    var totalDisplay = 0;
    for (let i = 0; i < clone.results.length; i++) {
        var current = clone.results[i]
        var displayIt = true;
        var keys = filters.keys();

        let result = keys.next();
        while (!result.done) {
            if (!checkTireMustShow(filters.get(result.value), current)) {
                displayIt = false;
                break;
            }
            result = keys.next();
        }

        if (displayIt) {
            totalDisplay++;
        }
        current.display = displayIt;
    }

    clone.summary.pageToDisplay = 1;
    clone.summary.productTotal = totalDisplay

    return clone;
}

export function sortTireResult(tireSearchResult, sortMethod) {
    var clone = JSON.parse(JSON.stringify(tireSearchResult));

    if (sortMethod === constants.TIRE_SEARCH_SORT_NO_SORT) {
        //No sort
        clone.results.sort(compareByOriginalOrder);
    }
    else if (sortMethod === constants.TIRE_SEARCH_SORT_DISPTOTAL_AND_PDSF_ASC) {
        //1- Disponibilité + prix ascendant (on prend la disponibilité dans Disponible localement et le prix c'est le PDS)
        clone.results.sort(compareByTotalDispoAndPdsfAsc);
    }
    else if (sortMethod === constants.TIRE_SEARCH_SORT_DISPTOTAL_AND_PDSF_DESC) {
        //2- Disponibilité + prix descendant (on prend la disponibilité dans Disponible localement et le prix c'est le PDS)
        clone.results.sort(compareByTotalDispoAndPdsfDesc);
    }
    else if (sortMethod === constants.TIRE_SEARCH_SORT_DISPTOTAL_AND_P_ASC) {
        //3- Disponibilité + cost ascendant (on prend la disponibilité dans Disponible localement et le prix c'est le P)
        clone.results.sort(compareByTotalDispoAndPAsc);
    }
    else if (sortMethod === constants.TIRE_SEARCH_SORT_DISPTOTAL_AND_P_DESC) {
        //4- Disponibilité + cost descendant (on prend la disponibilité dans Disponible localement et le prix c'est le P)
        clone.results.sort(compareByTotalDispoAndPDesc);
    }
    else if (sortMethod === constants.TIRE_SEARCH_SORT_PDSF_ASC) {
        //5- Prix ascendant (le PDSF)
        clone.results.sort(compareByPDSFAsc);
    }
    else if (sortMethod === constants.TIRE_SEARCH_SORT_PDSF_DESC) {
        //6- Prix descendant (le PDSF)
        clone.results.sort(compareByPDSFDesc);
    }
    else if (sortMethod === constants.TIRE_SEARCH_SORT_P_ASC) {
        //7- Cost ascendant (le P)
        clone.results.sort(compareByPAsc);
    }
    else if (sortMethod === constants.TIRE_SEARCH_SORT_P_DESC) {
        //8- Cost descendant (le P)
        clone.results.sort(compareByPDesc);
    }

    return clone;
}

export function compareByOriginalOrder(a, b) {
    return a.originalOrder - b.originalOrder;
}
/*
export function compareByLocalDispoAsc(a, b) {
    return a.sort.combineDispoLocal - b.sort.combineDispoLocal;
}

export function compareByLocalDispoDesc(a, b) {
    return b.sort.combineDispoLocal - a.sort.combineDispoLocal;
}
*/
export function compareByTotalDispoAsc(a, b) {
    return a.sort.combineDispoTotal - b.sort.combineDispoTotal;
}

export function compareByTotalDispoDesc(a, b) {
    return b.sort.combineDispoTotal - a.sort.combineDispoTotal;
}

export function compareByPDSFAsc(a, b) {
    return a.sort.averageCounterPrice - b.sort.averageCounterPrice;
}

export function compareByPDSFDesc(a, b) {
    return b.sort.averageCounterPrice - a.sort.averageCounterPrice;
}

export function compareByPAsc(a, b) {
    return a.sort.averageCostPrice - b.sort.averageCostPrice;
}

export function compareByPDesc(a, b) {
    return b.sort.averageCostPrice - a.sort.averageCostPrice;
}
/*
export function compareByLocalDispoAndPAsc(a, b) {
    var toReturn = compareByLocalDispoAsc(a, b);
    if (toReturn === 0) {
        toReturn = compareByPAsc(a, b);
    }
    return toReturn;
}

export function compareByLocalDispoAndPDesc(a, b) {
    var toReturn = compareByLocalDispoDesc(a, b);
    if (toReturn === 0) {
        toReturn = compareByPDesc(a, b);
    }
    return toReturn;
}

export function compareByLocalDispoAndPdsfAsc(a, b) {
    var toReturn = compareByLocalDispoAsc(a, b);
    if (toReturn === 0) {
        toReturn = compareByPDSFAsc(a, b);
    }
    return toReturn;
}

export function compareByLocalDispoAndPdsfDesc(a, b) {
    var toReturn = compareByLocalDispoDesc(a, b);
    if (toReturn === 0) {
        toReturn = compareByPDSFDesc(a, b);
    }
    return toReturn;
}
*/

export function compareByTotalDispoAndPAsc(a, b) {
    var toReturn = compareByTotalDispoDesc(a, b);
    if (toReturn === 0) {
        toReturn = compareByPAsc(a, b);
    }
    return toReturn;
}

export function compareByTotalDispoAndPDesc(a, b) {
    var toReturn = compareByTotalDispoDesc(a, b);
    if (toReturn === 0) {
        toReturn = compareByPDesc(a, b);
    }
    return toReturn;
}

export function compareByTotalDispoAndPdsfAsc(a, b) {
    var toReturn = compareByTotalDispoDesc(a, b);
    if (toReturn === 0) {
        toReturn = compareByPDSFAsc(a, b);
    }
    return toReturn;
}

export function compareByTotalDispoAndPdsfDesc(a, b) {
    var toReturn = compareByTotalDispoDesc(a, b);
    if (toReturn === 0) {
        toReturn = compareByPDSFDesc(a, b);
    }
    return toReturn;
}


