import { useEffect, useState } from 'react';
import './CheckBoxFilter.css';
import * as constants from '../../../../../../constants';

function CheckBoxFilter(props) {

    const [isOpen, setIsOpen] = useState(props.isOpen);
    const [showAll, setShowAll] = useState(false);
    const [mustDisplay, setMustDisplay] = useState(false);
    const [lastSearchTime, setLastSearchTime] = useState(props.tireSearchTime);

    var elementsArray = [];

    if (props.jsonData && props.jsonData.elements) {
        elementsArray = props.jsonData.elements
    }

    const [elements, setElements] = useState(elementsArray);

    useEffect(() => {
        if (lastSearchTime != props.tireSearchTime) {
            setIsOpen(true);
            setShowAll(false);
            setLastSearchTime(props.tireSearchTime);

            for (let i = 0; i < elements.length; i++) {
                var chk = elements[i];
                chk.checked = false;
            }
        }
    }, [props.tireSearchTime]);

    function toggleMenu() {
        setIsOpen(!isOpen);
    }

    function toggleShowAll() {
        setShowAll(!showAll);
    }

    function checked(id) {
        var newElements = JSON.parse(JSON.stringify(elements));
        newElements[id].checked = (!newElements[id].checked);
        setElements(newElements);

        props.action(props.jsonData.uniqueName, props.jsonData.type, props.jsonData.dataSource, newElements);
    }

    function createPrefix(checkBoxName, id, jsonData, maxDisplay, maxDisplayText, minDisplayText) {
        var prefix = constants.EMPTY;


        if (id === 0 && showAll && maxDisplay && maxDisplay > -1) {
            prefix = (
                <div className="tk-cursor-pointer frb-color-orange frb-font-medium" onClick={toggleShowAll}>
                    {minDisplayText}
                </div>
            );
        }
        return prefix;
    }

    function createCheckBox(checkBoxUniqueName, id, jsonData, maxDisplay, maxDisplayText, minDisplayText) {

        if (!showAll && maxDisplay && !(id === 0 && showAll && maxDisplay)) {
            if (id === maxDisplay) {
                return (
                    <div className="tk-cursor-pointer frb-color-orange frb-font-medium tk-pad-bot-5" onClick={toggleShowAll}>
                        {maxDisplayText}
                    </div>
                );
            } else if (maxDisplay != -1 && id > maxDisplay) {
                return constants.EMPTY;
            }
        }

        var checkBoxName = checkBoxUniqueName + constants.UNDERSCORE + id;
        var quantity = constants.EMPTY;
        if (jsonData.quantity) {
            quantity = '(' + jsonData.quantity + ')';
        }

        return (<>

            <input type="checkbox" checked={elements[id].checked} onChange={() => checked(id)} id={checkBoxName} name={checkBoxName} />
            <label className="checkbox checkbox-filter-checkbox" htmlFor={checkBoxName}>
                <div className="tk-grid tk-bgcolor-white checkbox-filter-2-checkbox-section">
                    <div>
                        <span className="box">
                            <svg className="svg-check">
                                <use xlinkHref="#svg-check"></use>
                            </svg>
                        </span>
                    </div>
                    <div>
                        <span className='checkbox-filter-checkbox'>{jsonData.name} </span>
                        <span className='checkbox-filter-checkbox-quantity'>{quantity}</span>
                    </div>
                </div>
            </label>
        </>
        );
    }

    var arrowCSS = "svg-arrow face-down checkbox-filter-arrow";
    var arrowPadding = "tk-pad-top-10";
    var divWrapper = "checkbox-filter-so-accordion-wrapper";
    var divSoTab = "checkbox-filter-so-tab checkbox-filter-so-tab-no-padding-top";

    if (isOpen) {
        arrowCSS = "svg-arrow face-up checkbox-filter-arrow-up";
        arrowPadding = "tk-pad-top-0"
    }

    if (!props.firstLine) {
        divWrapper = "tk-pad-top-0";
        divSoTab = "checkbox-filter-so-tab";
    }

    var checkBoxes = [];

    if (props.jsonData && props.jsonData.elements && props.jsonData.elements.length > 1) {
    //if (props.jsonData && props.jsonData.elements) {
        for (let i = 0; i < props.jsonData.elements.length; i++) {
            var current = props.jsonData.elements[i];
            var prefix = createPrefix(props.jsonData.uniqueName, i, current, props.jsonData.maxDisplay, props.jsonData.maxDisplayText, props.jsonData.minDisplayText);
            var filter = createCheckBox(props.jsonData.uniqueName, i, current, props.jsonData.maxDisplay, props.jsonData.maxDisplayText, props.jsonData.minDisplayText);
            checkBoxes.push(<div key={Math.random()} className="checkbox-filter-so-tab-content">{prefix}</div>);
            checkBoxes.push(<div key={Math.random()} className="checkbox-filter-so-tab-content">{filter}</div>);
        }

        return (
            <>
                <div key={props.jsonData.uniqueName} className={divWrapper}>
                    <div className={divSoTab}>
                        <div className="tk-grid tk-bgcolor-white checkbox-filter-2-section">
                            <div>
                                <input id={props.jsonData.uniqueName} type="checkbox" name="tabs" defaultChecked={isOpen} />
                                <label onClick={toggleMenu} className="checkbox-filter-label" htmlFor={props.jsonData.uniqueName}>{props.jsonData.title}</label>
                                <hr className="line-separator checkbox-filter-line-separator" />
                                {checkBoxes}
                            </div>
                            <div className={arrowPadding}>
                                <label onClick={toggleMenu} className="checkbox-filter-label" htmlFor={props.jsonData.uniqueName}>
                                    <svg className={arrowCSS}>
                                        <use xlinkHref='#svg-arrow'></use>
                                    </svg>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default CheckBoxFilter;