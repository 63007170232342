import { JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_SORT, loadSubSection, getTextByKey } from '../../../../../services/languageService';
import * as stringService from '../../../../../services/stringService';
import './TireResultSort.css';
import * as constants from '../../../../../constants';
import InfoPopup from '../InfoPopup/InfoPopup';
import { useState } from 'react';

function TireResultSort(props) {

    const [showInfoDollars, setShowInfoDollars] = useState(false);
    const [showInfoEye, setShowInfoEye] = useState(false);

    var theTexts = props.texts;

    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, props.onTextNotFound)
    }



    function toggleInfoDollars(display) {
        setShowInfoDollars(display)
    }

    function toggleInfoEye(display) {
        setShowInfoEye(display)
    }

    function buildQuantityLine() {
        var pattern = getText('quantity');
        var productTotal = props.jsonData.summary.productTotal;
        var productDisplay = props.jsonData.summary.productDisplay;
        var pageToDisplay = props.jsonData.summary.pageToDisplay;

        if ((pageToDisplay * productDisplay) > productTotal) {
            productDisplay = productTotal % productDisplay;
        }

        var plurial = constants.EMPTY;

        if (productDisplay > 1) {
            plurial = 's'
        }

        return stringService.stringParameters(pattern, [productDisplay, plurial, plurial, productTotal]);

    }
    function buildOptions(theArray) {
        var theList = [];

        theList.push(<option key='result-sort-by-option-none' value={-1}>{getText('chooseSort')}</option>);

        if (theArray) {
            for (let i = 0; i < theArray.length; i++) {
                var current = theArray[i]
                theList.push(<option key={'result-sort-by-option-' + i} value={current.id}>{current.value}</option>);
            }
        }
        return theList;
    }

    if (theTexts && props.jsonData && props.jsonData.summary) {

        var txtSection = loadSubSection(JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_SORT, theTexts, props.securityInfo);

        var dollarCss = 'tire-result-sort-button-dollar tk-cursor-pointer';
        var eyeCss = 'tire-result-sort-button tk-cursor-pointer';

        if (props.showDollar) {
            dollarCss = dollarCss + constants.SPACE + 'tire-result-sort-button-selected';
        }

        if (props.showEye) {
            eyeCss = eyeCss + constants.SPACE + 'tire-result-sort-button-selected';
        }

        return (
            <>
                <div className="tk-grid tk-bgcolor-white tire-result-sort-grid-5-columns">
                    <div className="tire-result-sort-grid-sizes-left">
                        <span className="tire-result-sort-font">{buildQuantityLine()} </span>
                    </div>
                    <div className="tire-result-sort-grid-sizes-right">
                        <span className="tire-result-sort-font">{getText('sortBy')} </span>
                    </div>
                    <div className="tk-pad-top-13 tk-pad-l-15">
                        <label htmlFor="result-sort-by" className="select-block medium">
                            <select name="result-sort-by" id="result-sort-by" className="tire-result-sort-combo-height" onChange={(event) => props.onSortSelection(event.target.value)} value={props.sortMethod}>
                                {buildOptions(txtSection.sortByOptions)}
                            </select>
                            <svg className="svg-arrow face-down tire-result-sort-combo-arrow">
                                <use xlinkHref="#svg-arrow"></use>
                            </svg>
                        </label>
                    </div>
                    <div className="tk-pad-top-13 tk-pad-l-25">
                        <div className={dollarCss} onMouseOut={() => toggleInfoDollars(false)} onMouseOver={() => toggleInfoDollars(true)} onClick={props.onButtonClickDollars}>
                            <i className="fa fa-dollar tk-font-size-30" aria-hidden="true"></i>
                            {showInfoDollars &&
                                <div className="tk-text-center tire-result-sort-popup-parent">
                                    <div className="tire-result-sort-popup-child">
                                        <InfoPopup msg={getText('popup_mask_price')} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="tk-pad-top-13 tk-pad-l-5">
                        <div className={eyeCss} onMouseOut={() => toggleInfoEye(false)} onMouseOver={() => toggleInfoEye(true)} onClick={props.onButtonClickEye}>
                            <i className="fa fa-eye tk-font-size-30" aria-hidden="true"></i>
                            {showInfoEye &&
                                <div className="tk-text-center tire-result-sort-popup-parent">
                                    <div className="tire-result-sort-popup-child">
                                        <InfoPopup msg={getText('popup_show_price')} left='-51px' />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default TireResultSort;