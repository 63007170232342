import { JSON_SECTION_MANAGE_USERS, JSON_SECTION_BASE, JSON_SUB_SECTION_PROFILE, JSON_SUB_SECTION_ADD, JSON_SUB_SECTION_EDIT, JSON_SUB_SECTION_LIST, loadSection, loadSubSection } from '../../../../services/languageService';
import UserList from './UserList';
import UserProfile from './UserProfile';
import UserAddEdit from './UserAddEdit';
import * as constants from '../../../../constants';
import * as backEndService from '../../../../services/backend/backEndService';
import { useEffect, useState } from 'react';

export const ACTION_PROFILE = "Profile";
export const ACTION_ADD = "Add";
export const ACTION_EDIT = "Edit";
export const ACTION_LIST = "List";

function ManageUsers(props) {

  var baseText = null;
  const [loggedUser, setLoggedUser] = useState([]);

  useEffect(() => {
    getLoggedUser();
  }, []);

  const [noAccount, setNoAccount] = useState('Unknown');

  function modifyProfile(mapOfAllFields, originalJsonUser) {
    backEndService.modifyUser(props.securityInfo.token, mapOfAllFields, originalJsonUser, handleChangeProfile, handleChangeProfileFailed);
  }

  function handleChangeProfile() {
    props.displaySuccessMessage(baseText.CommonTexts.Messages.SUCCESS_REQUEST_FOR_CHANGE_PROFILE_USER);
  }

  function handleChangeProfileFailed(){
    props.displayErrorMessage(baseText.CommonTexts.Messages.ERROR_MANAGE_USER_CHANGE_PROFILE_FAILED);
  }

  function onClickAddUser() {
    props.goToRoute(constants.ROUTE_ADDUSER);
  }

  function getLoggedUser() {
    backEndService.getLoggedUser(props.securityInfo.token, handleGetLoggedUser, handleGetLoggedUserFailed)
  }

  function handleGetLoggedUser(data) {
    setNoAccount(data.userInfo.noClient);
    setLoggedUser(data);
  }

  function handleGetLoggedUserFailed(data) {
    props.displayErrorMessage(baseText.CommonTexts.Messages.ERROR_MANAGE_USER_GET_USER_PROFILE_FAILED);
  }

  if (props.texts) {
    baseText = loadSection(JSON_SECTION_BASE, props.texts, props.securityInfo);
    if (props.action === ACTION_PROFILE) {
      if (props.texts) {
        return <UserProfile loggedUser={loggedUser} clearMessage={props.clearMessage} displayErrorMessage={props.displayErrorMessage} buttonMethod={modifyProfile} noAccount={noAccount} theTexts={loadSubSection(JSON_SECTION_MANAGE_USERS, JSON_SUB_SECTION_PROFILE, props.texts, props.securityInfo)} securityInfo={props.securityInfo} onTextNotFound={props.onTextNotFound}/>;
      }
    } else if (props.action === ACTION_ADD) {
      if (props.texts) {
        return <UserAddEdit clearMessage={props.clearMessage} displaySuccessMessage={props.displaySuccessMessage} goToRoute={props.goToRoute} displayErrorMessage={props.displayErrorMessage} update={false} noAccount={noAccount} theTexts={loadSubSection(JSON_SECTION_MANAGE_USERS, JSON_SUB_SECTION_ADD, props.texts, props.securityInfo)} userId="-1" securityInfo={props.securityInfo} loggedUser={loggedUser} onTextNotFound={props.onTextNotFound}/>;
      }
    } else if (props.action === ACTION_EDIT) {
      if (props.texts) {
        return <UserAddEdit clearMessage={props.clearMessage} displaySuccessMessage={props.displaySuccessMessage} goToRoute={props.goToRoute} displayErrorMessage={props.displayErrorMessage} update={true} noAccount={noAccount} securityInfo={props.securityInfo} userId={props.paramId} loggedUser={loggedUser} theTexts={loadSubSection(JSON_SECTION_MANAGE_USERS, JSON_SUB_SECTION_EDIT, props.texts, props.securityInfo)} onTextNotFound={props.onTextNotFound}/>;
      }
    } else if (props.action === ACTION_LIST) {
      if (props.texts) {
        return <UserList theTexts={loadSubSection(JSON_SECTION_MANAGE_USERS, JSON_SUB_SECTION_LIST, props.texts, props.securityInfo)} displayErrorMessage={props.displayErrorMessage} displaySuccessMessage={props.displaySuccessMessage} goToRoute={props.goToRoute} securityInfo={props.securityInfo} onClickAddUser={onClickAddUser} onTextNotFound={props.onTextNotFound}/>
      }
    }
  }

  return (
    <>
    </>
  );

}
export default ManageUsers;
