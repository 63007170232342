import { useState } from 'react';
import './S1MobileMenu.css';
import { JSON_SECTION_S1_MAIN_MENU, loadSection } from '../../../services/languageService';
import * as constants from '../../../constants';

function S1MobileMenu(props) {

    const [isOpen, setIsOpen] = useState(props.isOpen);

    function toggleMenu() {
        setIsOpen(!isOpen);
    }

    function internGoToRoute(url) {
        props.goToRoute(url);
    }


    function handleClick(event) {
        var id = event.target.id
        hrefOrJavascript(document.getElementById('href_' + id).value, document.getElementById('script_' + id).value, document.getElementById('route_' + id).value)
    }

    /*
    BUG REACT WARNING: For some reason, when a onClick event in linked to a Div
    it get trigger twice... so I set a lastCalled variable to check if it's the second time.
    */
    var lastCalled = 0;
    function hrefOrJavascript(href, theScript, route) {
        var callTime = Date.now();
        if ((callTime - lastCalled) > 1000) {
            lastCalled = callTime;
            if (theScript) {
                window[theScript]();
            } else if (route) {
                internGoToRoute(route);
            } else {
                window.location = href;
            }
        }
    }

    function createSubMenus(level, jsonData, subMenus) {
        var paddingText = 'line_first_column tk-pad-l-' + (20 * (level + 1));

        if (jsonData.menus && jsonData.menus.length > 0) {
            var subMenu = [];
            var cssMenu = 'mobile-menu-submenu';
            var cssMenuItem = 'mobile-menu-subMenuItem';
            for (let i = 0; i < jsonData.menus.length; i++) {
                var current = jsonData.menus[i];
                var theName = current.name ? current.name : current.title;
                var theId = current.id;
                var menuName = Math.random();
                var subMenuList = [];
                var hasSubMenu = createSubMenus(level + 1, current, subMenuList);
                var arrow = (<svg className="svg-arrow medium rotable"><use xlinkHref="#svg-arrow"></use></svg>);
                var labelPlus = hasSubMenu ? arrow : constants.EMPTY;
                var hrefName = 'href_' + theId;
                var scriptName = 'script_' + theId;
                var routeName = 'route_' + theId;
                var toReturn = constants.EMPTY;

                if (hasSubMenu) {
                    cssMenuItem = 'mobile-menu-subMenuItem_WithChild';
                    toReturn = (<label className="checkbox mobile-menu-accormenu-checkbox" htmlFor={menuName}>
                        <div className='tk-grid mobile-menu-grid-2-columns'>
                            <div className={paddingText}>
                                {theName}
                            </div>
                            <div className="mobile-menu-line_second_column">
                                {labelPlus}
                            </div>
                        </div>
                    </label>);
                } else {
                    toReturn = (<div className="checkbox mobile-menu-accormenu-checkbox tk-cursor-pointer">
                        <div className='tk-grid mobile-menu-grid-2-columns'>
                            <input type='hidden' name={hrefName} id={hrefName} value={current.href} />
                            <input type='hidden' name={scriptName} id={scriptName} value={current.javascript} />
                            <input type='hidden' name={routeName} id={routeName} value={current.route} />
                            <div className={paddingText} id={theId} onClick={handleClick}>
                                {theName}
                            </div>
                            <div className="mobile-menu-line_second_column">
                                {labelPlus}
                            </div>
                        </div>
                    </div>);
                }

                subMenu.push(
                    <div key={Math.random()} className={cssMenuItem}>
                        <input type="checkbox" className='mobile-menu-menuParent' id={menuName} name={menuName} />
                        {toReturn}
                        {subMenuList}
                    </div>);

            }

            subMenus.push(
                <div key={Math.random()} id={cssMenu} className={cssMenu + " mobile-menu-menuLeaf"}>
                    {subMenu}
                </div>

            );
        }

        return (subMenus.length > 0);
    }

    function createMenu(menuNameOri, id, key, jsonData) {
        var menuName = menuNameOri + '_' + id;

        var subMenus = [];

        //Check for Submenu
        var hasSubMenu = createSubMenus(1, jsonData, subMenus);
        var arrow = (<svg className="svg-arrow medium rotable"><use xlinkHref="#svg-arrow"></use></svg>);
        var labelPlus = hasSubMenu ? arrow : constants.EMPTY;

        var theHtmlCode = constants.EMPTY;

        if (hasSubMenu) {
            theHtmlCode = (
                <div className='tk-grid mobile-menu-grid-2-columns tk-pad-l-20'>
                    <div className="line_first_column">
                        {jsonData.name}
                    </div>
                    <div className="mobile-menu-line_second_column">
                        {labelPlus}
                    </div>
                </div>);
        } else {
            var hrefName = 'href_' + jsonData.id;
            var scriptName = 'script_' + jsonData.id;
            var routeName = 'route_' + jsonData.id;
            var theId = jsonData.id;

            theHtmlCode = (
                <div className='tk-grid mobile-menu-grid-2-columns tk-pad-l-20'>
                    <input type='hidden' name={hrefName} id={hrefName} value={jsonData.href} />
                    <input type='hidden' name={scriptName} id={scriptName} value={jsonData.javascript} />
                    <input type='hidden' name={routeName} id={routeName} value={jsonData.route} />
                    <div className="tk-grid-col-span-2" id={theId} onClick={handleClick}>
                        {jsonData.name}
                    </div>
                </div>);

        }

        if (hasSubMenu) {
            theHtmlCode = (
                <label className="checkbox mobile-menu-accormenu-checkbox" htmlFor={menuName}>
                    {theHtmlCode}
                </label>);
        } else {
            theHtmlCode = (
                <div className="checkbox mobile-menu-accormenu-checkbox tk-cursor-pointer" htmlFor=''>
                    {theHtmlCode}
                </div>);
        }

        return (
            <>
                <div className='tk-pad-top-10'>
                    <input type="checkbox" className='mobile-menu-menuParent' id={menuName} name={menuName} />
                    {theHtmlCode}
                    {subMenus}
                </div>
            </>
        );
    }

    var divWrapper = "mobile-menu-so-accordion-wrapper";
    var divSoTab = "mobile-menu-so-tab mobile-menu-so-tab-no-padding-top";

    if (!props.firstLine) {
        divWrapper = "mobile-menu-so-accordion-wrapper-padding-top";
        divSoTab = "mobile-menu-so-tab";
    }

    if (props.texts) {
        var theTexts = loadSection(JSON_SECTION_S1_MAIN_MENU, props.texts, props.securityInfo);

        var checkBoxes = [];
        if (theTexts && theTexts.menus) {
            for (let i = 0; i < theTexts.menus.length; i++) {
                var current = theTexts.menus[i];
                var key = 'MobileMenuComponent_item_' + i;
                var createdMenu = createMenu('MobileMenuComponent', i, key, current);
                checkBoxes.push(<div key={key} className="mobile-menu-so-tab-content mobile-menu-not-selected testHover">{createdMenu}</div>);
            }
        }

        return (
            <>
                <div id="mobile-menu-main-div" name="mobile-menu-main-div" className="tk-display-none">
                    <div key='MobileMenuComponent' className={divWrapper}>
                        <div className={divSoTab}>
                            <div className="tk-bgcolor-white">
                                <div className="mobile-menu-top-element">
                                    <input id='MobileMenuComponent' type="checkbox" name="tabs" defaultChecked={isOpen} />
                                    <span onClick={toggleMenu} className="fa fa-bars mobile-menu-burger"></span>
                                    {checkBoxes}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default S1MobileMenu;