function OtherComponents() {

  return (
    <>
      <div className="tk-display-none-imp">
        <svg style={{ display: null }}>
          <symbol id="svg-arrow" viewBox="0 0 3.923 6.64014" preserveAspectRatio="xMinYMin meet">
            <path d="M3.711,2.92L0.994,0.202c-0.215-0.213-0.562-0.213-0.776,0c-0.215,0.215-0.215,0.562,0,0.777l2.329,2.329
      L0.217,5.638c-0.215,0.215-0.214,0.562,0,0.776c0.214,0.214,0.562,0.215,0.776,0l2.717-2.718C3.925,3.482,3.925,3.135,3.711,2.92z"/>
          </symbol>
        </svg>


        <svg style={{ display: null }}>
          <symbol id="svg-plus" viewBox="0 0 10 10" preserveAspectRatio="xMinYMin meet">
            <rect x="4" width="2" height="10" />
            <rect y="4" width="10" height="2" />
          </symbol>
        </svg>

        <svg style={{ display: null }}>
          <symbol id="svg-minus" viewBox="0 0 10 10" preserveAspectRatio="xMinYMin meet">
            <rect y="4" width="10" height="2" />
          </symbol>
        </svg>

        <svg style={{ display: null }}>
          <symbol id="svg-mag-glass" viewBox="0 0 12 12" preserveAspectRatio="xMinYMin meet">
            <path d="M3.094,6.916c-0.275-0.269-0.482-0.573-0.62-0.912C2.336,5.666,2.267,5.32,2.267,4.968
      c0-0.351,0.069-0.699,0.207-1.041c0.138-0.344,0.345-0.65,0.62-0.92c0.275-0.269,0.587-0.468,0.934-0.599
      c0.347-0.13,0.702-0.195,1.066-0.195s0.72,0.064,1.067,0.195c0.346,0.131,0.657,0.331,0.933,0.599c0.275,0.27,0.482,0.576,0.62,0.92
      c0.139,0.342,0.207,0.69,0.207,1.041c0,0.353-0.068,0.698-0.207,1.036c-0.138,0.339-0.345,0.644-0.62,0.912
      c-0.275,0.27-0.587,0.471-0.933,0.605C5.813,7.657,5.458,7.724,5.094,7.724s-0.72-0.066-1.066-0.202
      C3.681,7.387,3.369,7.186,3.094,6.916z M1.494,1.443C0.996,1.93,0.622,2.479,0.374,3.092C0.125,3.705,0,4.328,0,4.962
      s0.125,1.257,0.374,1.87c0.248,0.611,0.622,1.161,1.12,1.648c0.399,0.391,0.844,0.703,1.333,0.938
      c0.488,0.234,0.995,0.391,1.52,0.469c0.525,0.078,1.052,0.074,1.58-0.013C6.456,9.786,6.961,9.621,7.44,9.379l2.294,2.241
      c0.133,0.13,0.28,0.226,0.44,0.286c0.159,0.061,0.326,0.091,0.5,0.091c0.173,0,0.34-0.03,0.5-0.091s0.307-0.156,0.439-0.286
      c0.134-0.13,0.231-0.274,0.293-0.431c0.063-0.156,0.094-0.318,0.094-0.488s-0.031-0.332-0.094-0.488
      c-0.062-0.157-0.159-0.301-0.293-0.431L9.401,7.619C9.712,7.143,9.93,6.63,10.055,6.082c0.124-0.547,0.157-1.094,0.1-1.641
      c-0.058-0.547-0.209-1.08-0.453-1.596c-0.245-0.517-0.58-0.983-1.007-1.401C8.196,0.967,7.634,0.605,7.008,0.363
      C6.381,0.119,5.743-0.003,5.094-0.003S3.808,0.119,3.181,0.363C2.554,0.605,1.991,0.967,1.494,1.443z"/>
          </symbol>
        </svg>

        <svg style={{ display: null }}>
          <symbol id="svg-cross" viewBox="0 0 10 10" preserveAspectRatio="xMinYMin meet">
            <g>
              <g>
                <polygon fillRule="evenodd" clipRule="evenodd" points="9.949,1.465 8.535,0.051 5,3.586 1.465,0.051
          0.051,1.465 3.586,5 0.051,8.535 1.465,9.949 5,6.414 8.535,9.949 9.949,8.535 6.414,5 		"/>
              </g>
            </g>
          </symbol>
        </svg>

        <svg style={{ display: null }}>
          <symbol id="svg-cart" viewBox="0 0 22 18" preserveAspectRatio="xMinYMin meet">
            <g>
              <g>
                <path fillRule="evenodd" clipRule="evenodd" d="M16.991,13.991c-1.106,0-2.003,0.896-2.003,2.003s0.896,2.003,2.003,2.003
          s2.003-0.896,2.003-2.003S18.098,13.991,16.991,13.991z M8.012,13.991c-1.105,0-2.003,0.896-2.003,2.003s0.897,2.003,2.003,2.003
          c1.106,0,2.003-0.896,2.003-2.003S9.118,13.991,8.012,13.991z M5,2V1c0-0.552-0.447-1-1-1H1C0.447,0,0,0.448,0,1v1
          c0,0.553,0.447,1,1,1h2.182L5,13h15l2-11H5z M18.331,11H6.669L5.396,4h14.207L18.331,11z"/>
              </g>
            </g>
            <rect x="3.734" fillRule="evenodd" clipRule="evenodd" width="1.266" height="3" />
          </symbol>
        </svg>

        <svg style={{ display: null }}>
          <symbol id="svg-cart-small" viewBox="0 0 16 13" preserveAspectRatio="xMinYMin meet">
            <g>
              <g>
                <path fillRule="evenodd" clipRule="evenodd" d="M12.49,10.012c-0.826,0-1.495,0.669-1.495,1.493
          c0,0.825,0.669,1.494,1.495,1.494s1.495-0.669,1.495-1.494C13.985,10.681,13.316,10.012,12.49,10.012z M5.497,10.012
          c-0.826,0-1.495,0.669-1.495,1.493c0,0.825,0.669,1.494,1.495,1.494c0.825,0,1.495-0.669,1.495-1.494
          C6.992,10.681,6.322,10.012,5.497,10.012z M4,1.001v-1H1c-0.553,0-1,0.448-1,1s0.447,1,1,1h1.25l1.75,7h10l2-8H4z M12.438,7.001
          H5.561l-1-4h8.877L12.438,7.001z"/>
              </g>
            </g>
          </symbol>
        </svg>

        <svg style={{ display: null }}>
          <symbol id="svg-heart" viewBox="0 0 14 12" preserveAspectRatio="xMinYMin meet">
            <path d="M10.043,1.998c0.55,0,0.95,0.167,1.381,0.577c0.371,0.353,0.575,0.813,0.575,1.297
      c0,0.484-0.204,0.945-0.591,1.313C11.384,5.208,11.34,5.25,7.002,9.705C5.717,8.346,2.975,5.451,2.576,5.071
      C2.205,4.719,2,4.258,2,3.774s0.204-0.943,0.576-1.296C2.823,2.243,3.178,2.003,3.93,2.003c0.209,0,0.802,0.225,1.702,1.069
      l1.39,1.303l1.369-1.325C9.213,2.255,9.858,1.998,10.043,1.998 M10.043-0.002C9.011-0.002,7.859,0.782,7,1.613
      c-0.887-0.832-2.003-1.61-3.07-1.61c-1.045,0-1.965,0.297-2.731,1.025c-1.598,1.517-1.598,3.976,0,5.493
      c0.456,0.434,4.883,5.121,4.883,5.121c0.252,0.24,0.583,0.359,0.914,0.359c0.33,0,0.66-0.119,0.912-0.359
      c0,0,4.843-4.974,4.895-5.024c1.597-1.517,1.597-3.977,0-5.494C12.009,0.372,11.123-0.002,10.043-0.002L10.043-0.002z"/>
          </symbol>
        </svg>

        <svg style={{ display: null }}>
          <symbol id="svg-check" viewBox="0 0 20 16" preserveAspectRatio="xMinYMin meet">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.692,3.398L7.764,15.669c-0.412,0.424-1.08,0.424-1.491,0
    L0.308,9.534C-0.104,9.11-0.104,8.423,0.308,8l1.491-1.534c0.412-0.424,1.08-0.424,1.491,0l3.728,3.834L16.71,0.33
    c0.412-0.423,1.08-0.423,1.491,0l1.491,1.534C20.104,2.288,20.104,2.975,19.692,3.398z"/>
          </symbol>
        </svg>

        <svg style={{ display: null }}>
          <symbol id="svg-star" viewBox="0 0 15 14" preserveAspectRatio="xMinYMin meet">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.5-0.002l2.473,4.399L15,5.345l-3.5,3.667l0.636,4.985
    L7.5,11.863l-4.635,2.134L3.5,9.012L0,5.345l5.028-0.948L7.5-0.002"/>
          </symbol>
        </svg>
        <svg>
          <symbol id="svg-burger" viewBox="0 0 22 14" preserveAspectRatio="xMinYMin meet">
            <g>
              <g>
                <path fillRule="evenodd" clipRule="evenodd" d="M1,2h20c0.553,0,1-0.448,1-1s-0.447-1-1-1H1C0.448,0,0,0.448,0,1
            S0.448,2,1,2z M21,6H1C0.448,6,0,6.448,0,7c0,0.553,0.448,1,1,1h20c0.553,0,1-0.447,1-1C22,6.448,21.553,6,21,6z M21,12H1
            c-0.552,0-1,0.447-1,1s0.448,1,1,1h20c0.553,0,1-0.447,1-1S21.553,12,21,12z"/>
              </g>
            </g>
          </symbol>
        </svg>
      </div>
    </>
  );
}

export default OtherComponents;
