import { JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_RESULT, loadSubSection, getTextByKey } from '../../../../../../../services/languageService';
import * as stringService from '../../../../../../../services/stringService';
import './TirePrice.css';
import * as constants from '../../../../../../../constants';
function TirePrice(props) {

    var theTexts = props.texts;

    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    function formatPrice(displayP, displayPDiscount) {
        if (props.jsonData.pDiscount) {
            if (!props.maskPrice) {
                displayPDiscount = stringService.toDollars(displayPDiscount);
                displayP = stringService.toDollars(displayP);
            }
            return (<div className="tk-pad-left-18 tk-div-align-content-vh-center">
                <div>
                    <div className='frb-font-lato frb-color-orange frb-font-bold frb-font-medium'>{displayPDiscount}</div>
                    <div className='frb-font-lato frb-font-small tk-strike frb-font-small'>{displayP}</div>
                </div>
            </div>);
        } else {
            if (!props.maskPrice) {
                displayP = stringService.toDollars(displayP);
            }

            return (<div className="frb-font-lato frb-font-bold tk-pad-left-18 frb-font-small tk-div-align-content-vh-center">{displayP}</div>);
        }
    }

    if (theTexts) {
        var txtSection = loadSubSection(JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_RESULT, theTexts, props.securityInfo);

        var partner = constants.EMPTY;

        if (props.jsonData.partner !== null) {
            partner = (
                <div className="tk-uppercase frb-font-lato frb-font-bold">
                    <img width="65px" alt={constants.EMPTY} src={getText('pricePartnerImageURL')} /><br />
                    {props.jsonData.partner}
                </div>);
        }

        var displayP = props.jsonData.p;
        var displayPDiscount = props.jsonData.pDiscount;
        var displayE = props.jsonData.e + '%';
        var displayM = stringService.toDollars(props.jsonData.m);
        if (props.maskPrice) {
            displayP = props.jsonData.pHide;
            displayPDiscount = props.jsonData.pDiscountHide;
            displayE = props.jsonData.eHide;
            displayM = props.jsonData.mHide;
        }

        return (
            <div className="tk-text-center">
                <div className="tk-grid tk-bgcolor-white tire-price-main-grid-2-colons tk-height-30">
                    <div className="tk-pad-left-18 tk-div-align-content-vh-center frb-font-lato frb-font-bold frb-font-medium">{getText('priceP')}</div>
                    {formatPrice(displayP, displayPDiscount)}
                </div>
                <div className="tk-grid tk-bgcolor-white tire-price-main-grid-2-colons tk-height-30">
                    <div className="tk-pad-left-18 tk-div-align-content-vh-center frb-font-lato frb-font-bold frb-font-medium">{getText('priceE')}</div>
                    <div className="tk-pad-left-18 tk-div-align-content-vh-center frb-font-lato frb-font-medium">{displayE}</div>
                </div>
                <div className="tk-grid tk-bgcolor-white tire-price-main-grid-2-colons tk-height-30">
                    <div className="tk-pad-left-18 tk-div-align-content-vh-center frb-font-lato frb-font-bold frb-font-medium">{getText('priceM')}</div>
                    <div className="tk-pad-left-18 tk-div-align-content-vh-center frb-font-lato frb-font-bold frb-font-medium">{displayM}</div>
                </div>
                {partner}
            </div>
        );
    }
}

export default TirePrice;