import { JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_RESULT, loadSubSection, getTextByKey } from '../../../../../../../services/languageService';

function TireAvailability(props) {

    var theTexts = props.texts;

    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    function local() {

        return (
            <div className="tk-div-align-content-vh-center">
                <div>
                    <div className='frb-font-lato frb-color-orange frb-font-xbold'>{props.jsonData.local}</div>
                    <div className='frb-font-lato frb-color-black frb-font-small'>{getText('availLocal')}</div>
                </div>
            </div>);
    }

    function external() {
        return (
            <div className="tk-div-align-content-vh-center tk-pad-top-12">
                <div>
                    <div className='frb-font-lato frb-color-black frb-font-xbold'>{props.jsonData.external}</div>
                    <div className='frb-font-lato frb-color-black frb-font-small'>{getText('availExternal')}</div>
                </div>
            </div>);
    }

    if (theTexts) {
        var txtSection = loadSubSection(JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_RESULT, theTexts, props.securityInfo);
        return (
            <div className="tk-text-center">
                {local()}
                {external()}
            </div>
        );
    }
}

export default TireAvailability;