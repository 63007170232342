import { useEffect, useState } from 'react';
import '../../TireSearchPage.css'
import './TabVeh.css'
import * as backEndService from '../../../../../../services/backend/backEndService';
import * as constants from '../../../../../../constants';
import { JSON_SECTION_TIRE_SEARCH_PAGE, JSON_SUB_SECTION_TAB_VEH, loadSubSection, getTextByKey, getGeneralTextByKey } from '../../../../../../services/languageService';
import * as validationService from '../../../../../../services/validationService';

function TabVeh(props) {
    const COMBO_BOX_NO_SELECTION = '-1';

    const DEFAULT_YEAR = COMBO_BOX_NO_SELECTION;
    const DEFAULT_BRAND = COMBO_BOX_NO_SELECTION;
    const DEFAULT_MODEL = COMBO_BOX_NO_SELECTION;
    const DEFAULT_GROUP = COMBO_BOX_NO_SELECTION;
    const DEFAULT_SIZE = COMBO_BOX_NO_SELECTION;

    const DEFAULT_DISCOUNT_ONLY = false;
    const BASIC_OPTION_DEFAULT = <option value={COMBO_BOX_NO_SELECTION}></option>;
    const BASIC_OPTION_LOADING = <option value={COMBO_BOX_NO_SELECTION}>Chargement en cours...</option>;

    const [year, setYear] = useState(DEFAULT_YEAR);
    const [brand, setBrand] = useState(DEFAULT_BRAND);
    const [model, setModel] = useState(DEFAULT_MODEL);
    const [group, setGroup] = useState(DEFAULT_GROUP);
    const [size, setSize] = useState(DEFAULT_SIZE);

    const [yearDisabled, setYearDisabled] = useState(true);
    const [brandDisabled, setBrandDisabled] = useState(true);
    const [modelDisabled, setModelDisabled] = useState(true);
    const [groupDisabled, setGroupDisabled] = useState(true);
    const [sizeDisabled, setSizeDisabled] = useState(true);


    const [type, setType] = useState(props.selectedType);
    const [discountOnly, setDiscountOnly] = useState(DEFAULT_DISCOUNT_ONLY);

    const [yearList, setYearList] = useState(<option value={COMBO_BOX_NO_SELECTION}>Chargement en cours...</option>);
    const [brandList, setBrandList] = useState(BASIC_OPTION_DEFAULT);
    const [modelList, setModelList] = useState(BASIC_OPTION_DEFAULT);
    const [groupList, setGroupList] = useState(BASIC_OPTION_DEFAULT);
    const [sizeList, setSizeList] = useState(BASIC_OPTION_DEFAULT);

    var isMinimalDisplay = (props.displayType === constants.TIRE_SEARCH_DISPLAY_MINIMAL);

    useEffect(() => {
        if (props.tireSearchOptions  && props.tireSearchOptions.get(constants.MAPPING_TIRESEARCH_SEARCHTYPE) === constants.TIRESEARCH_TYPE_VEH) {
            var search = props.tireSearchOptions;
            setYearList(search.get(constants.LIST_TIRESEARCH_VEH_YEAR));
            setYearDisabled(false);
            setYear(search.get(constants.MAPPING_TIRESEARCH_VEH_YEAR));

            setBrandList(search.get(constants.LIST_TIRESEARCH_VEH_BRAND));
            setBrandDisabled(false);
            setBrand(search.get(constants.MAPPING_TIRESEARCH_VEH_BRAND));

            setModelList(search.get(constants.LIST_TIRESEARCH_VEH_MODEL));
            setModelDisabled(false);
            setModel(search.get(constants.MAPPING_TIRESEARCH_VEH_MODEL));

            setGroupList(search.get(constants.LIST_TIRESEARCH_VEH_OPTION_GROUP));
            setGroupDisabled(false);
            setGroup(search.get(constants.MAPPING_TIRESEARCH_VEH_OPTION_GROUP));

            setSizeList(search.get(constants.LIST_TIRESEARCH_VEH_SIZE));
            setSizeDisabled(false);
            setSize(search.get(constants.MAPPING_TIRESEARCH_SIZE_FRONT));

            setType(search.get(constants.MAPPING_TIRESEARCH_TYPE));
            setDiscountOnly(search.get(constants.MAPPING_TIRESEARCH_DISCOUNT_ONLY));

        } else {
            loadYears();
        }
    }, []);

    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    function getGeneralText(keyFields) {
        return getGeneralTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    function loadYears() {
        backEndService.loadVehiculeYears(props.securityInfo.token, handleVehiculeYearLoaded, handleVehiculeYearLoadedFailed);
    }

    function loadBrands(theYear) {
        var data = {
            'year': theYear
        }
        setBrandList(BASIC_OPTION_LOADING);
        backEndService.loadVehiculeBrands(props.securityInfo.token, handleVehiculeBrandsLoaded, handleVehiculeBrandsLoadedFailed, data);
    }

    function loadModels(theBrand) {
        var data = {
            'year': year,
            'brand': theBrand
        }
        setModelList(BASIC_OPTION_LOADING);
        backEndService.loadVehiculeModels(props.securityInfo.token, handleVehiculeModelsLoaded, handleVehiculeModelsLoadedFailed, data);
    }

    function loadGroups(theModel) {
        var data = {
            'year': year,
            'brand': brand,
            'model': theModel
        }
        setGroupList(BASIC_OPTION_LOADING);
        backEndService.loadVehiculeGroups(props.securityInfo.token, handleVehiculeGroupsLoaded, handleVehiculeGroupsLoadedFailed, data);
    }

    function loadSize(theGroup) {
        var data = {
            'year': year,
            'brand': brand,
            'model': model,
            'group': theGroup
        }
        setSizeList(BASIC_OPTION_LOADING);
        backEndService.loadVehiculeTireSizes(props.securityInfo.token, handleVehiculeTireSizesLoaded, handleVehiculeTireSizesLoadedFailed, data);
    }

    function doSearch() {

        if (validationService.validateStringEquals(size, DEFAULT_SIZE)) {
            props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_TIRE_SEARCH_ALL']));
            return;
        }

        const allFields = new Map();
        allFields.set(constants.MAPPING_TIRESEARCH_SEARCHTYPE, constants.TIRESEARCH_TYPE_VEH);
        allFields.set(constants.MAPPING_TIRESEARCH_VEH_YEAR, year);
        allFields.set(constants.MAPPING_TIRESEARCH_VEH_BRAND, brand);
        allFields.set(constants.MAPPING_TIRESEARCH_VEH_MODEL, model);
        allFields.set(constants.MAPPING_TIRESEARCH_VEH_OPTION_GROUP, group);
        allFields.set(constants.MAPPING_TIRESEARCH_DISCOUNT_ONLY, discountOnly);
        allFields.set(constants.MAPPING_TIRESEARCH_SIZE_FRONT, size);
        allFields.set(constants.MAPPING_TIRESEARCH_TYPE, type);

        allFields.set(constants.LIST_TIRESEARCH_VEH_YEAR, yearList);
        allFields.set(constants.LIST_TIRESEARCH_VEH_BRAND, brandList);
        allFields.set(constants.LIST_TIRESEARCH_VEH_MODEL, modelList);
        allFields.set(constants.LIST_TIRESEARCH_VEH_OPTION_GROUP, groupList);
        allFields.set(constants.LIST_TIRESEARCH_VEH_SIZE, sizeList);

        props.tireSearchFunction(allFields, props.moveToTop)

    }

    function createOptions(hintText, key, data, setterMethod) {
        var theList = [];

        if (data) {
            theList.push(<option key={key + 'select'} value={COMBO_BOX_NO_SELECTION}>{hintText}</option>);
            for (let i = 0; i < data.length; i++) {
                var current = data[i]
                theList.push(<option key={key + i} value={current}>{current}</option>);
            }

            setterMethod(theList);
        }
    }

    function handleVehiculeYearLoaded(values) {
        createOptions(getText('msgChooseYear'), 'year', values, setYearList)
        setYearDisabled(false);
    }

    function handleVehiculeYearLoadedFailed() {
        props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_VEH_YEAR_LOAD_FAILED']));
    }


    function handleVehiculeBrandsLoaded(values) {
        createOptions(getText('msgChooseBrand'), 'brand', values, setBrandList)
        setBrandDisabled(false);
    }

    function handleVehiculeBrandsLoadedFailed() {
        props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_VEH_BRAND_LOAD_FAILED']));
    }

    function handleVehiculeModelsLoaded(values) {
        createOptions(getText('msgChooseModel'), 'model', values, setModelList);
        setModelDisabled(false);
    }

    function handleVehiculeModelsLoadedFailed() {
        props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_VEH_MODEL_LOAD_FAILED']));
    }


    function handleVehiculeGroupsLoaded(values) {
        createOptions(getText('msgChooseOptions'), 'group', values, setGroupList)
        setGroupDisabled(false);
    }

    function handleVehiculeGroupsLoadedFailed() {
        props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_VEH_OPTIONS_LOAD_FAILED']));
    }

    function handleVehiculeTireSizesLoaded(values) {
        createOptions(getText('msgChooseSize'), 'size', values, setSizeList)
        setSizeDisabled(false);
    }

    function handleVehiculeTireSizesLoadedFailed() {
        props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_VEH_TIRE_SIZE_LOAD_FAILED']));
    }

    function resetLists(id) {
        if (id >= 4) {
            setBrandList(BASIC_OPTION_DEFAULT);
            setBrand(DEFAULT_BRAND);
            setBrandDisabled(true);
        }

        if (id >= 3) {
            setModelList(BASIC_OPTION_DEFAULT);
            setModel(DEFAULT_MODEL);
            setModelDisabled(true);
        }

        if (id >= 2) {
            setGroupList(BASIC_OPTION_DEFAULT);
            setGroup(DEFAULT_GROUP);
            setGroupDisabled(true);
        }

        if (id >= 1) {
            setSizeList(BASIC_OPTION_DEFAULT);
            setSize(DEFAULT_SIZE);
            setSizeDisabled(true);
        }
    }

    function handleYearChange(event) {
        resetLists(4);
        setYear(event.target.value);
        if (event.target.value !== COMBO_BOX_NO_SELECTION) {
            loadBrands(event.target.value);
        }
    }

    function handleBrandChange(event) {
        resetLists(3);
        setBrand(event.target.value);
        if (event.target.value !== COMBO_BOX_NO_SELECTION) {
            loadModels(event.target.value)
        }
    }

    function handleModelChange(event) {
        resetLists(2);
        setModel(event.target.value);
        if (event.target.value !== COMBO_BOX_NO_SELECTION) {
            loadGroups(event.target.value)
        }
    }

    function handleGroupChange(event) {
        resetLists(1);
        setGroup(event.target.value)
        if (event.target.value !== COMBO_BOX_NO_SELECTION) {
            loadSize(event.target.value)
        }
    }

    function handleSizeChange(event) {
        setSize(event.target.value);
    }

    function handleTypeChange(event) {
        setType(event.target.value);
    }

    function handleDiscountChange() {
        setDiscountOnly(!discountOnly);
    }

    function clearScreen() {
        resetLists(4);
        setType(props.selectedType);
        setDiscountOnly(DEFAULT_DISCOUNT_ONLY);
        setYear(DEFAULT_YEAR);
    }

    if (props.texts) {
        var txtSection = loadSubSection(JSON_SECTION_TIRE_SEARCH_PAGE, JSON_SUB_SECTION_TAB_VEH, props.texts, props.securityInfo);

        if (isMinimalDisplay) {
            return (
                <>
                    <div className="tk-grid tk-bgcolor-white tabveh-grid-1-column">
                        <div>
                            <div className="form-row">
                                <div className="tk-grid tk-bgcolor-white tabveh-grid-5-columns_2">
                                    <div className="tabveh-width-tire-pixel">
                                        <label className="rl-label">{getText('year')}</label>
                                        <label htmlFor="searchYear" className="select-block medium">
                                            <select name="searchYear" id="searchYear" disabled={yearDisabled} onChange={handleYearChange} value={year}>
                                                {yearList}
                                            </select>
                                            <svg className="svg-arrow face-down">
                                                <use xlinkHref="#svg-arrow"></use>
                                            </svg>
                                        </label>
                                    </div>
                                    <div className="tabveh-width-tire-pixel">
                                        <label className="rl-label">{getText('brand')}</label>
                                        <label htmlFor="searchBrand" className="select-block medium">
                                            <select name="searchBrand" id="searchBrand" disabled={brandDisabled} onChange={handleBrandChange} value={brand}>
                                                {brandList}
                                            </select>
                                            <svg className="svg-arrow face-down">
                                                <use xlinkHref="#svg-arrow"></use>
                                            </svg>
                                        </label>
                                    </div>
                                    <div className="tabveh-width-tire-pixel">
                                        <label className="rl-label">{getText('model')}</label>
                                        <label htmlFor="searchModel" className="select-block medium">
                                            <select name="searchModel" id="searchModel" disabled={modelDisabled} onChange={handleModelChange} value={model}>
                                                {modelList}
                                            </select>
                                            <svg className="svg-arrow face-down">
                                                <use xlinkHref="#svg-arrow"></use>
                                            </svg>
                                        </label>
                                    </div>
                                    <div className="tabveh-width-tire-pixel">
                                        <label className="rl-label">{getText('options')}</label>
                                        <label htmlFor="searchOption" className="select-block medium">
                                            <select name="searchOption" id="searchOption" disabled={groupDisabled} onChange={handleGroupChange} value={group}>
                                                {groupList}
                                            </select>
                                            <svg className="svg-arrow face-down">
                                                <use xlinkHref="#svg-arrow"></use>
                                            </svg>
                                        </label>
                                    </div>
                                    <div className="tabveh-width-tire-pixel">
                                        <label className="rl-label">{getText('size')}</label>
                                        <label htmlFor="searchSize" className="select-block medium">
                                            <select name="searchSize" id="searchSize" disabled={sizeDisabled} onChange={handleSizeChange} value={size}>
                                                {sizeList}
                                            </select>
                                            <svg className="svg-arrow face-down">
                                                <use xlinkHref="#svg-arrow"></use>
                                            </svg>
                                        </label>
                                    </div>
                                </div>
                                <div className="tk-grid tk-bgcolor-white tabveh-grid-5-columns tk-pad-top-20">
                                    <div>
                                        <input type="radio" id="pb_warranty1" name="pb_warranty1" value={constants.TIRE_TYPE_SUMMER} checked={type === constants.TIRE_TYPE_SUMMER} onChange={handleTypeChange} />
                                        <label className="radio rl-label" htmlFor="pb_warranty1">
                                            <span className="circle tk-bgcolor-white"><span className="inner-circle"></span></span>
                                            {getText('radioSummerTire')}
                                        </label>
                                    </div>
                                    <div>
                                        <input type="radio" id="pb_warranty2" name="pb_warranty2" value={constants.TIRE_TYPE_HOMOLOGUED} checked={type === constants.TIRE_TYPE_HOMOLOGUED} onChange={handleTypeChange} />
                                        <label className="radio rl-label" htmlFor="pb_warranty2">
                                            <span className="circle tk-bgcolor-white"><span className="inner-circle"></span></span>
                                            {getText('radioHomologued')}
                                        </label>
                                    </div>
                                    <div>
                                        <input type="radio" id="pb_warranty3" name="pb_warranty3" value={constants.TIRE_TYPE_WINTER} checked={type === constants.TIRE_TYPE_WINTER} onChange={handleTypeChange} />
                                        <label className="radio rl-label" htmlFor="pb_warranty3">
                                            <span className="circle tk-bgcolor-white"><span className="inner-circle"></span></span>
                                            {getText('radioWinterTire')}
                                        </label>
                                    </div>
                                    <div>

                                        <input type="radio" id="pb_warranty4" name="pb_warranty4" value={constants.TIRE_TYPE_4SEASONS} checked={type === constants.TIRE_TYPE_4SEASONS} onChange={handleTypeChange} />
                                        <label className="radio rl-label" htmlFor="pb_warranty4">
                                            <span className="circle tk-bgcolor-white"><span className="inner-circle"></span></span>
                                            {getText('radio4Seasons')}
                                        </label>
                                    </div>
                                    <div>
                                        <div className="tk-grid tk-bgcolor-white tabveh-grid-2-columns">
                                            <div onClick={clearScreen} className='tk-cursor-pointer'><span className="button v2 tabveh-button-erase">{getText('buttonClear')}</span></div>
                                            <div onClick={doSearch} className='tk-cursor-pointer'><span className="button tabveh-button-search">{getText('buttonSearch')}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <input type="checkbox" id="checkbox99" name="checkbox99" onChange={handleDiscountChange} checked={discountOnly} />
                                    <label className="checkbox" htmlFor="checkbox99">
                                        <span className="box">
                                            <svg className="svg-check">
                                                <use xlinkHref="#svg-check"></use>
                                            </svg>
                                        </span>
                                        <span className='tabveh-label-font-bold'>{getText('checkboxDiscountOnly')}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className="tk-grid tk-bgcolor-white tabveh-grid-2-columns_3">
                        <div>
                            <div className="form-row">
                                <div className="tabveh-width90">
                                    <label className="rl-label">{getText('year')}</label>
                                    <label htmlFor="searchYear" className="select-block medium">
                                        <select name="searchYear" id="searchYear" disabled={yearDisabled} onChange={handleYearChange} value={year}>
                                            {yearList}
                                        </select>
                                        <svg className="svg-arrow face-down">
                                            <use xlinkHref="#svg-arrow"></use>
                                        </svg>
                                    </label>
                                </div>
                                <div className="tabveh-width90 tk-pad-top-20">
                                    <label className="rl-label">{getText('brand')}</label>
                                    <label htmlFor="searchBrand" className="select-block medium">
                                        <select name="searchBrand" id="searchBrand" disabled={brandDisabled} onChange={handleBrandChange} value={brand}>
                                            {brandList}
                                        </select>
                                        <svg className="svg-arrow face-down">
                                            <use xlinkHref="#svg-arrow"></use>
                                        </svg>
                                    </label>
                                </div>
                                <div className="tabveh-width90 tk-pad-top-20">
                                    <label className="rl-label">{getText('model')}</label>
                                    <label htmlFor="searchModel" className="select-block medium">
                                        <select name="searchModel" id="searchModel" disabled={modelDisabled} onChange={handleModelChange} value={model}>
                                            {modelList}
                                        </select>
                                        <svg className="svg-arrow face-down">
                                            <use xlinkHref="#svg-arrow"></use>
                                        </svg>
                                    </label>
                                </div>
                                <div className="tabveh-width90 tk-pad-top-20">
                                    <label className="rl-label">{getText('options')}</label>
                                    <label htmlFor="searchOption" className="select-block medium">
                                        <select name="searchOption" id="searchOption" disabled={groupDisabled} onChange={handleGroupChange} value={group}>
                                            {groupList}
                                        </select>
                                        <svg className="svg-arrow face-down">
                                            <use xlinkHref="#svg-arrow"></use>
                                        </svg>
                                    </label>
                                </div>
                                <div className="tabveh-width90 tk-pad-top-20">
                                    <label className="rl-label">{getText('size')}</label>
                                    <label htmlFor="searchSize" className="select-block medium">
                                        <select name="searchSize" id="searchSize" disabled={sizeDisabled} onChange={handleSizeChange} value={size}>
                                            {sizeList}
                                        </select>
                                        <svg className="svg-arrow face-down">
                                            <use xlinkHref="#svg-arrow"></use>
                                        </svg>
                                    </label>
                                </div>
                                <div className="tk-grid tk-bgcolor-white tabveh-grid-2-columns_2 tk-pad-top-20">
                                    <div>
                                        <input type="radio" id="pb_warranty1" name="pb_warranty1" value={constants.TIRE_TYPE_SUMMER} checked={type === constants.TIRE_TYPE_SUMMER} onChange={handleTypeChange} />
                                        <label className="radio rl-label" htmlFor="pb_warranty1">
                                            <span className="circle tk-bgcolor-white"><span className="inner-circle"></span></span>
                                            {getText('radioSummerTire')}
                                        </label>
                                    </div>
                                    <div>
                                        <input type="radio" id="pb_warranty2" name="pb_warranty2" value={constants.TIRE_TYPE_HOMOLOGUED} checked={type === constants.TIRE_TYPE_HOMOLOGUED} onChange={handleTypeChange} />
                                        <label className="radio rl-label" htmlFor="pb_warranty2">
                                            <span className="circle tk-bgcolor-white"><span className="inner-circle"></span></span>
                                            {getText('radioHomologued')}
                                        </label>
                                    </div>
                                    <div>
                                        <input type="radio" id="pb_warranty3" name="pb_warranty3" value={constants.TIRE_TYPE_WINTER} checked={type === constants.TIRE_TYPE_WINTER} onChange={handleTypeChange} />
                                        <label className="radio rl-label" htmlFor="pb_warranty3">
                                            <span className="circle tk-bgcolor-white"><span className="inner-circle"></span></span>
                                            {getText('radioWinterTire')}
                                        </label>
                                    </div>
                                    <div>

                                        <input type="radio" id="pb_warranty4" name="pb_warranty4" value={constants.TIRE_TYPE_4SEASONS} checked={type === constants.TIRE_TYPE_4SEASONS} onChange={handleTypeChange} />
                                        <label className="radio rl-label" htmlFor="pb_warranty4">
                                            <span className="circle tk-bgcolor-white"><span className="inner-circle"></span></span>
                                            {getText('radio4Seasons')}
                                        </label>
                                    </div>
                                </div>
                                <div className="">
                                    <input type="checkbox" id="checkbox99" name="checkbox99" onChange={handleDiscountChange} checked={discountOnly} />
                                    <label className="checkbox" htmlFor="checkbox99">
                                        <span className="box">
                                            <svg className="svg-check">
                                                <use xlinkHref="#svg-check"></use>
                                            </svg>
                                        </span>
                                        <span className='tabveh-label-font-bold'>{getText('checkboxDiscountOnly')}</span>
                                    </label>
                                </div>
                                <div className="tk-grid tk-bgcolor-white tabveh-grid-2-columns_2 tk-pad-top-20">
                                    <div onClick={clearScreen} className='tk-cursor-pointer'><span className="button v2 tabveh-button-erase">{getText('buttonClear')}</span></div>
                                    <div onClick={doSearch} className='tk-cursor-pointer'><span className="button tabveh-button-search">{getText('buttonSearch')}</span></div>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                </>
            );
        }
    }
}

export default TabVeh;