import S1TopMenu from "./S1TopMenu/S1TopMenu";
import S1MainMenu from "./S1MainMenu/S1MainMenu";
import S12Footer from "./S12Footer/S12Footer";
import S13CopyRight from "./S13CopyRight/S13CopyRight";
import OtherComponents from "./OtherComponents/OtherComponents";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HomePage from "../HomePage/HomePage";
import Todo from "../Pages/Todo/Todo";
import JoinUs from "../Pages/JoinUs/JoinUs";
import LoginPage from "../Pages/LoginPage/LoginPage";
import { useNavigate } from "react-router";
import { JSON_SECTION_MESSAGE_ZONE, loadSection, getGeneralTextByKey } from '../../services/languageService';
import Localization from "./Localization/Localization";
import MessageZone from "./MessageZone/MessageZone";
import ManageUsers, { ACTION_PROFILE, ACTION_ADD, ACTION_EDIT, ACTION_LIST } from "../Pages/Users/ManageUsers/ManageUsers";
import * as browserService from '../../services/browserService';
import * as backToFront from '../../services/backToFrontService.js';
import * as backEndService from '../../services/backend/backEndService';
import * as validationService from '../../services/validationService';
import * as constants from '../../constants';
import OldSite from "../Pages/OldSite/OldSite";
import TireResultPage from "../Pages/Tires/TireResultPage/TireResultPage";
import TireSearch from "../Pages/Tires/TireSearchPage/TireSearch";
import Converter from "../Pages/Tires/Converter/Converter";
import S1MobileMenu from "./S1MobileMenu/S1MobileMenu";

export const ACTION_HOME = 'Home';
export const ACTION_404 = 'PAGE_NOT_FOUND'
export const ACTION_JOINUS = 'JoinUs';
export const ACTION_TIRES = 'Tires';
export const ACTION_TIRES_CONVERTER = 'TiresConverter';

export const ACTION_TIRES_RESULT = 'TiresResult';

export const ACTION_OLDSITE = 'OldSite';
export const ACTION_FATAL = 'Fatal';
export const ACTION_LOGIN = 'Login';
export const ACTION_LOGOUT = 'Logout';
export const ACTION_LOGOUT_LOGIN = 'ReLogin';
export const ACTION_MANAGEUSERS_PROFILE = 'ManageUsersProfile';
export const ACTION_MANAGEUSERS_LIST = 'ManageUsersList';
export const ACTION_MANAGEUSERS_ADDUSER = 'ManageUsersAdd';
export const ACTION_MANAGEUSERS_EDITUSER = 'ManageUsersEdit';

//Each time a message is display, this value is set to current timemillis. This is to
//be sure that a message just displayed is not erased by the automatic message removal
var clearMessageRequest = Date.now();

function BasePage(props) {
  const routeParams = useParams();
  const [selectedType, setSelectedType] = useState('0');
  const [tireSearchOptions, setTireSearchOptions] = useState();

  //Used to navigate without reloading the application
  const navigate = useNavigate();

  var needRole = null;

  //Load all UI Text/Image links from backend or local json file
  useEffect(() => {
    backEndService.loadUITexts(handleText, handleFatalError);
  }, []);

  function getGeneralText(keyFields) {
    if (theTexts) {
      return getGeneralTextByKey(theTexts, keyFields, props.onTextNotFound)
    }
  }

  function handleDefaultTireSearchType(data) {
    if (data.winter) {
      setSelectedType(constants.TIRE_TYPE_WINTER);
    } else {
      setSelectedType(constants.TIRE_TYPE_SUMMER);
    }
  }

  //Keep the Texts
  const [texts, setTexts] = useState(null);

  //For navigation line on UI (Ex: Accueil -> My Profile)
  var localizationLevel1 = constants.EMPTY;
  var localizationLevel2 = constants.EMPTY;

  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);
  const [loginRedirection, setLoginRedirection] = useState(null);
  const [tireSearchResult, setTireSearchResult] = useState(null);
  const [tireSearchResultSort, setTireSearchResultSort] = useState(-1);

  const [tireSearchTime, setTireSearchTime] = useState(-1);
  const [partnerList, setPartnerList] = useState([]);
  const [loggedActionDone, setLoggedActionDone] = useState(false);

  useEffect(() => {
    if (validationService.isLogged(browserService.getSecurityInfo()) && !loggedActionDone) {
      setLoggedActionDone(true);
      loadPartners();
      backEndService.loadDefaultTireSearhType(securityInfo.token, handleDefaultTireSearchType);
    }
  });

  var loginInfo = browserService.getLoginInfo();

  //Authentication Value loaded
  var securityInfo = browserService.getSecurityInfo();

  //Calls when the LoginPopup is closed
  function saveAuthenticationResponse(jsonObj) {
    browserService.saveAuthenticationResponse(jsonObj);
    securityInfo = browserService.getSecurityInfo();
  }

  function goToRoute(route) {
    navigate(route);
  }

  function onTextNotFound(data) {
    console.log("Text not found: " + data);
  }

  function loginRedirect(url) {
    if (validationService.isLogged(securityInfo)) {
      window.location.replace(url);
    } else {
      setLoginRedirection(url);
      window.scrollTo(0, 0);
      goToRoute(constants.ROUTE_LOGIN);
    }
  }

  function clearMessage(time) {
    if (time === clearMessageRequest) {
      setMessage(constants.EMPTY);
      setMessageType(-1);
    }
  }

  function closeMessageBox() {
    setMessage(constants.EMPTY);
    setMessageType(-1);
  }

  function displayErrorMessage(msg) {
    setMessage(msg);
    setMessageType(0);
    var timeNow = Date.now();
    clearMessageRequest = timeNow;
    setTimeout(clearMessage, 7500, timeNow);
  }

  function displaySuccessMessage(msg) {
    setMessage(msg);
    setMessageType(1);
    var timeNow = Date.now();
    clearMessageRequest = timeNow;
    setTimeout(clearMessage, 7500, timeNow);
  }

  //Handler for Callback of the Login Rest Service
  function handleLogin(data) {
    clearMessage();
    saveAuthenticationResponse(data);
    loadPartners();
    if (loginRedirection) {
      var direction = loginRedirection
      setLoginRedirection(null);
      window.location.replace(direction);
    } else {
      setLoginRedirection(null);
      goToRoute(constants.ROUTE_TIRES);
    }
  }

  function loadPartners() {
    backEndService.loadPartners(securityInfo.token, handlePartnersLoaded, handlePartnersLoadedFailed);
  }

  function handlePartnersLoaded(values) {
    setPartnerList(values);
    // setPartnerCSS(CSS_VISIBLE);
  }

  function handlePartnersLoadedFailed() {
    displayErrorMessage(getGeneralText(['Messages', 'ERROR_TIRE_PARTNER_FAILED']));
    // setPartnerCSS(CSS_INVISIBLE);
  }

  //Handler for Callback of the Text for the UI
  function handleText(data) {
    setTexts(data);
  }


  function handleLoginError(data) {
    displayErrorMessage(getGeneralText(['Messages', 'ERROR_LOGIN_FAILED']));
  }

  function handleFatalError(data) {
    goToRoute(constants.ROUTE_FATAL);
  }

  //Function called y the login popup page
  function performLogin(clientNo, userCode, password, rememberMe) {
    browserService.saveLoginInfo(clientNo, userCode, password, rememberMe);
    backEndService.login(clientNo, userCode, password, handleLogin, handleLoginError)
  }

  function performTireSearch(fieldMap, moveToTop) {
    backEndService.searchTires(securityInfo.token, handleTireSearchLoaded, handleTireSearchLoadedFailed, fieldMap);
    setTireSearchOptions(fieldMap);
    setTireSearchResult(null);
    setTireSearchResultSort(-1);
    document.getElementById('spinner').className = constants.EMPTY;
    document.getElementById('results').className = "tk-display-none";

    if (moveToTop) {
      window.scrollTo(0, 0);
    }
  }

  function tireSearchResultSortApplied(sortMethod) {
    var sortedResult = backToFront.sortTireResult(tireSearchResult, sortMethod);

    sortedResult.summary.pageToDisplay = 1;
    setTireSearchResult(sortedResult);
    setTireSearchResultSort(sortMethod);
  }

  function tireSearchFilterApplied(filterValues) {
    setTireSearchResult(backToFront.filterTireResult(tireSearchResult, filterValues));
  }

  function changePageToDisplay(pageNumber) {
    var jsonData = tireSearchResult;
    jsonData.summary.pageToDisplay = pageNumber;
    setTireSearchResult(jsonData);
    goToRoute(constants.ROUTE_TIRES_RESULT);
  }

  function handleTireSearchLoaded(jsonObject) {
    var jsonData = backToFront.convertTireSearch(jsonObject, constants.TIRE_SEARCH_RESULT_ITEM_PER_PAGE, partnerList);
    setTireSearchTime(Date.now);
    setTireSearchResult(jsonData);
    document.getElementById('spinner').className = "tk-display-none";
    document.getElementById('results').className = constants.EMPTY;
    goToRoute(constants.ROUTE_TIRES_RESULT);
  }

  function handleTireSearchLoadedFailed(msg) {
    document.getElementById('spinner').className = "tk-display-none";
    document.getElementById('results').className = constants.EMPTY;
    displayErrorMessage(getGeneralText(['Messages', 'ERROR_TIRE_SEARCH_FAILED']));
  }

  function performLogout(whereToGo) {
    browserService.logout();
    securityInfo = browserService.getSecurityInfo();
    window.location.replace(whereToGo + '?auto=true');
  }

  function buildOldSiteObject(url) {
    var loginAccount = constants.EMPTY;
    var loginUser = constants.EMPTY;
    var loginPassword = constants.EMPTY;

    if (validationService.isLogged(securityInfo)) {
      loginAccount = loginInfo.loginAccount;
      loginUser = loginInfo.loginUser
      loginPassword = loginInfo.loginPassword;
    }

    return (
      <OldSite url={url} goToRoute={goToRoute} key='OldSite' oldsite={constants.OLD_SITE_URL} onTextNotFound={onTextNotFound} texts={texts} loginAccount={loginAccount} loginUser={loginUser} loginPassword={loginPassword} />
    );
  }

  var displayHeaders = true;
  var componentToShow = [];

  if (texts) {
    var theTexts = loadSection(JSON_SECTION_MESSAGE_ZONE, texts, props.securityInfo);
  }

  if (props.action === ACTION_404) {
    window.location.href = '/';
  } else
    if (props.action === ACTION_HOME) {
      componentToShow.push(<HomePage key='HomePage' onTextNotFound={onTextNotFound} loginRedirect={loginRedirect} displaySuccessMessage={displaySuccessMessage} displayErrorMessage={displayErrorMessage} texts={texts} />);
    } else if (props.action === ACTION_OLDSITE) {
      displayHeaders = false;
      componentToShow.push(buildOldSiteObject(props.page));
    } else if (props.action === ACTION_JOINUS) {
      componentToShow.push(<JoinUs key='JoinUs' displayErrorMessage={displayErrorMessage} displaySuccessMessage={displaySuccessMessage} onTextNotFound={onTextNotFound} texts={texts} />);
      if (texts) {
        localizationLevel1 = texts.JoinUs.all.navigation[0].name;
        localizationLevel2 = texts.JoinUs.all.navigation[1].name;
      }
    } else if (props.action === ACTION_TIRES) {
      componentToShow.push(<TireSearch key='Tires' moveToTop={true} partnerList={partnerList} tireSearchFunction={performTireSearch} goToRoute={goToRoute} selectedType={selectedType} onTextNotFound={onTextNotFound} displaySuccessMessage={displaySuccessMessage} displayErrorMessage={displayErrorMessage} texts={texts} securityInfo={securityInfo} />);
      needRole = constants.ROLE_LOGGED;
      if (texts) {
        localizationLevel1 = texts.Tires.all.navigation[0].name;
        localizationLevel2 = texts.Tires.all.navigation[1].name;
      }
    } else if (props.action === ACTION_TIRES_RESULT) {
      needRole = constants.ROLE_LOGGED;
      componentToShow.push(<TireResultPage moveToTop={false} partnerList={partnerList} tireSearchTime={tireSearchTime} tireSearchOptions={tireSearchOptions} tireSearchFilterApplied={tireSearchFilterApplied} sortMethod={tireSearchResultSort} onSortSelection={tireSearchResultSortApplied} changePageToDisplay={changePageToDisplay} tireSearchFunction={performTireSearch} jsonData={tireSearchResult} goToRoute={goToRoute} key='TiresResult' selectedType={selectedType} onTextNotFound={onTextNotFound} displaySuccessMessage={displaySuccessMessage} displayErrorMessage={displayErrorMessage} texts={texts} securityInfo={securityInfo} />);
      if (texts) {
        localizationLevel1 = texts.Tires.all.navigation[0].name;
        localizationLevel2 = texts.Tires.all.navigation[1].name;
      }
    } else if (props.action === ACTION_MANAGEUSERS_LIST) {
      needRole = constants.ROLE_ADMIN;
      componentToShow.push(<ManageUsers key='ManageUsers_List' clearMessage={clearMessage} goToRoute={goToRoute} action={ACTION_LIST} paramId='-1' displayErrorMessage={displayErrorMessage} displaySuccessMessage={displaySuccessMessage} securityInfo={securityInfo} texts={texts} onTextNotFound={onTextNotFound} />);
      if (texts) {
        localizationLevel1 = texts.ManageUsers.list.all.navigation[0].name;
        localizationLevel2 = texts.ManageUsers.list.all.navigation[1].name;
      }
    } else if (props.action === ACTION_MANAGEUSERS_PROFILE) {
      needRole = constants.ROLE_LOGGED;
      componentToShow.push(<ManageUsers key='ManageUsers_Profile' clearMessage={clearMessage} displaySuccessMessage={displaySuccessMessage} displayErrorMessage={displayErrorMessage} goToRoute={goToRoute} action={ACTION_PROFILE} paramId='-1' securityInfo={securityInfo} texts={texts} onTextNotFound={onTextNotFound} />);
      if (texts) {
        localizationLevel1 = texts.ManageUsers.profile.all.navigation[0].name;
        localizationLevel2 = texts.ManageUsers.profile.all.navigation[1].name;
      }
    } else if (props.action === ACTION_MANAGEUSERS_ADDUSER) {
      needRole = constants.ROLE_ADMIN;
      componentToShow.push(<ManageUsers key='ManageUsers_Add' clearMessage={clearMessage} displaySuccessMessage={displaySuccessMessage} displayErrorMessage={displayErrorMessage} goToRoute={goToRoute} action={ACTION_ADD} paramId='-1' securityInfo={securityInfo} texts={texts} onTextNotFound={onTextNotFound} />);
      if (texts) {
        localizationLevel1 = texts.ManageUsers.add.all.navigation[0].name;
        localizationLevel2 = texts.ManageUsers.add.all.navigation[1].name;
      }
    } else if (props.action === ACTION_MANAGEUSERS_EDITUSER) {
      needRole = constants.ROLE_ADMIN;
      componentToShow.push(<ManageUsers key='ManageUsers_Edit' clearMessage={clearMessage} displaySuccessMessage={displaySuccessMessage} displayErrorMessage={displayErrorMessage} goToRoute={goToRoute} action={ACTION_EDIT} paramId={routeParams.id} securityInfo={securityInfo} texts={texts} onTextNotFound={onTextNotFound} />);
      if (texts) {
        localizationLevel1 = texts.ManageUsers.edit.all.navigation[0].name;
        localizationLevel2 = texts.ManageUsers.edit.all.navigation[1].name;
      }
    } else if (props.action === ACTION_LOGIN) {
      componentToShow.push(<LoginPage key='LoginPage' displayErrorMessage={displayErrorMessage} performLogin={performLogin} loginInfo={loginInfo} texts={texts} onTextNotFound={onTextNotFound} />);
      if (texts) {
        localizationLevel1 = texts.LoginPage.all.navigation[0].name;
        localizationLevel2 = texts.LoginPage.all.navigation[1].name;
      }
    } else if (props.action === ACTION_LOGOUT_LOGIN) {
      performLogout(constants.ROUTE_LOGIN);
    } else if (props.action === ACTION_LOGOUT) {
      performLogout(constants.ROUTE_HOME);
    } else {
      componentToShow.push(<Todo key='Todo' />);
    }

  if (needRole !== null) {
    var ok = true;
    if (needRole === constants.ROLE_LOGGED && !(validationService.isLogged(securityInfo))) {
      ok = false;
    }

    if (needRole === constants.ROLE_ADMIN && !(validationService.isAdmin(securityInfo))) {
      ok = false;
    }

    if (!ok) {
      goToRoute(constants.ROUTE_HOME);
      return;
    }
  }

  if (displayHeaders) {
    //This is to ensure that we don't stay connected to the old web site. Make sure to remove this iframe when the site
    //will no longer call the old one.
    var oldLogoutUrl = constants.OLD_SITE_URL + 'users/deconnexion';

    return (
      <>
        <iframe hidden id='oldSiteLogout' title='oldSiteLogout' src={oldLogoutUrl} />

        <div id="grayoutTable">
          <S1TopMenu goToRoute={goToRoute} securityInfo={securityInfo} texts={texts} onTextNotFound={onTextNotFound} />
          <S1MainMenu goToRoute={goToRoute} securityInfo={securityInfo} texts={texts} onTextNotFound={onTextNotFound} />
          <S1MobileMenu goToRoute={goToRoute} securityInfo={securityInfo} texts={texts} />
          <Localization level1={localizationLevel1} level2={localizationLevel2} />
          <MessageZone closeMessageBox={closeMessageBox} message={message} messageType={messageType} texts={texts} />
          {componentToShow}
          <S12Footer onTextNotFound={onTextNotFound} texts={texts} />
          <S13CopyRight onTextNotFound={onTextNotFound} texts={texts} />
          <OtherComponents />
        </div>
        <div id="converterPopup" className="tk-display-none"><Converter closeMethod={window['frb_js_toggleConverter']} securityInfo={securityInfo} displaySuccessMessage={displaySuccessMessage} displayErrorMessage={displayErrorMessage} texts={texts} onTextNotFound={onTextNotFound} /></div>
      </>
    );
  } else {
    return (
      <>
        {componentToShow}
      </>
    );
  }


}

export default BasePage;