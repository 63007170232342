import { JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_RESULT, loadSubSection, getTextByKey } from '../../../../../../../services/languageService';
import * as stringService from '../../../../../../../services/stringService';
import ResultPopup from '../../../ResultPopup/ResultPopup';
import ResultPopupDiscount from '../../../ResultPopupDiscount/ResultPopupDiscount';
import * as constants from '../../../../../../../constants';

import './TireCounter.css';
import { useEffect } from 'react';

function TireCounter(props) {
    var theTexts = props.texts;

    useEffect(() => {
        if (theTexts) {
            hideDiscountPopup()
        }
    }, [props.removePopup]);

    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    function price() {
        if (props.jsonData.priceOriginal) {
            return (
                <div className="tk-div-align-content-vh-center">
                    <div onMouseEnter={(event) => showDetailPopup(event)} onMouseLeave={hideDetailPopup}>
                        <div className='frb-color-orange frb-font-lato frb-font-xbold tk-pad-bot-5'>{stringService.toDollars(props.jsonData.price)}</div>
                        <div className='frb-color-black frb-font-lato frb-font-medium frb-font-bold tk-strike'>{stringService.toDollars(props.jsonData.priceOriginal)}</div>
                        <div className='frb-color-black frb-font-lato frb-font-small tk-pad-top-3'>{getText('counterPrice')}</div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="tk-div-align-content-vh-center">
                    <div onMouseEnter={(event) => showDetailPopup(event)} onMouseLeave={hideDetailPopup}>
                        <div className='frb-color-orange frb-font-lato frb-font-xbold'>{stringService.toDollars(props.jsonData.price)}</div>
                        <div className='frb-color-black frb-font-lato frb-font-small'>{getText('counterPrice')}</div>
                    </div>
                </div>
            );
        }
    }

    function discount() {
        if (props.jsonData.dealerDiscount) {
            return (
                <div className="tk-div-align-content-vh-center tk-pad-top-12">
                    <div onMouseEnter={(event) => showDiscountPopup(event)} >
                        <div className='frb-color-orange frb-font-lato frb-font-xbold tk-underline'>{stringService.toDollars(props.jsonData.dealerDiscount)}</div>
                        <div className='frb-color-black frb-font-lato frb-font-small'>{getText('counterDiscount')}</div>
                    </div>
                </div>
            );
        } else {
            return constants.EMPTY;
        }
    }

    function hideDiscountPopup() {
        document.getElementById(popupDiscountId).className = "tire-counter-popup-child tk-display-none";
    }

    function showDiscountPopup(event) {
        document.getElementById(popupDiscountId).className = "tire-counter-popup-child tk-display-block";

        var popupWidth = document.getElementById(popupDiscountId).getBoundingClientRect().width;

        var a = (event.currentTarget.parentElement.getBoundingClientRect().width - event.currentTarget.getBoundingClientRect().width) / 2;
        var theLeft = -(popupWidth + a + 20);
        document.getElementById(popupDiscountId).style.left = theLeft + constants.PIXEL;
    }

    function hideDetailPopup() {
        document.getElementById(popupId).className = "tire-counter-popup-child tk-display-none";
    }

    function showDetailPopup(event) {
        var mainDiv = document.getElementById(mainDivId);

        document.getElementById(popupId).className = "tire-counter-popup-child tk-display-block";

        var theLeft = -(document.getElementById(popupId).getBoundingClientRect().width / 2) + (mainDiv.parentElement.getBoundingClientRect().width / 2);

        var x = mainDiv.getBoundingClientRect().height;
        var y = mainDiv.parentElement.getBoundingClientRect().height;
        var a = (y - x) / 2;

        var theTop = x + a + 25;
        document.getElementById(popupId).style.left = theLeft + constants.PIXEL;
        document.getElementById(popupId).style.top = theTop + constants.PIXEL;
    }

    if (theTexts) {
        var popupId = 'resultPopup_' + Math.random();
        var popupDiscountId = 'resultDiscountPopup_' + Math.random();
        var txtSection = loadSubSection(JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_RESULT, theTexts, props.securityInfo);
        var mainDivId = 'MainDivId_' + popupId
        return (
            <>
                <div id={mainDivId} className="tk-text-center tire-counter-popup-parent">
                    <div id={popupId} className="tire-counter-popup-child tk-display-none">
                        <ResultPopup securityInfo={props.securityInfo} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} onTextNotFound={props.onTextNotFound} />
                    </div>
                    <div id={popupDiscountId} className="tire-counter-popup-child tk-display-none">
                        <ResultPopupDiscount jsonData={props.jsonData} securityInfo={props.securityInfo} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} onTextNotFound={props.onTextNotFound} />
                    </div>
                    {price()}
                    {discount()}
                </div>
            </>
        );
    }
}

export default TireCounter;