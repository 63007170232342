import { useState } from 'react';
import './Partner.css'

function Partner(props) {

    const [selected, setSelected] = useState(false);
    const [lastReset, setLastReset] = useState('-1');

    function resetCheckBox(){
        setSelected(false);
    }

    if (props.resetAsked){
        if (props.resetAsked !== '-1' && lastReset !== props.resetAsked){
            resetCheckBox();
            setLastReset(props.resetAsked);
        }
    }

    function handleClickPartner(event, id) {
        setSelected(!selected);

        if (event.target.checked === true){
            props.addPartner(id);
        }else{
            props.removePartner(id);
        }
    }

    return (
        <>
            <div className='tk-grid tk-bgcolor-white partner-bg'>
                <div className='tk-grid frb-bgcolor-gray partner-grid-2-columns'>
                    <div className="partner-bg">
                        <input data-testid="checkBoxTest" type="checkbox" checked={selected} id={"checkboxPartner" + props.id} name={"checkboxPartner" + props.id} onChange={(event) => handleClickPartner(event, props.id)}/>
                        <label className="checkbox" htmlFor={"checkboxPartner" + props.id}>
                            <span className="box">
                                <svg className="svg-check">
                                    <use xlinkHref="#svg-check"></use>
                                </svg>
                            </span>
                            <span className='partner-label-font-bold'></span>
                        </label>
                    </div>
                    <div className="partner-grid-item-image-element">
                        <img onClick={resetCheckBox} alt='' src={props.img} />
                    </div>
                </div>
            </div>
            </>
    );
}

export default Partner;