import { JSON_SECTION_MESSAGE_ZONE, loadSection, getTextByKey } from '../../../services/languageService';
import * as constants from '../../../constants';
import './MessageZone.css';

function MessageZone(props) {

  function close() {
    props.closeMessageBox();
  }

  if (props.texts) {
    var theTexts = loadSection(JSON_SECTION_MESSAGE_ZONE, props.texts, props.securityInfo);
    var msg = props.message;
    var img = getTextByKey(theTexts, 'infoImage', props);
    var title = theTexts.infoTxt;

    if (props.messageType === 0) {
      img = theTexts.errorImage;
      title = theTexts.errorTxt;
    }

    if (msg != null && msg !== 'undefined' && msg !== constants.EMPTY) {
      return (
        <>
            <div className="message_zone_mainDiv section-navigation-msg message_zone_sticky">
              <div className="message_zone_topDiv">
                <div className="message_zone_topDiv_columnOne">
                  <div className="message_zone_topDiv_columnOne_innerColumnOne"><img alt="" src={img} /></div>
                  <div className="message_zone_topDiv_columnOne_innerColumnTwo">{title}</div>
                </div>
                <div className="message_zone_topDiv_columnTwo section-navigation-path-msg">{msg}</div>
                <div className="message_zone_topDiv_columnThree tk-cursor-pointer">
                  <i onClick={close} className="fa fa-close"></i>
                </div>
              </div>
          </div>
        </>

      );

    } else {
      return (<></>);
    }

  }
}
export default MessageZone;