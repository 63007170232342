import './S12Footer.css';
import { JSON_SECTION_S12_FOOTER, loadSection, getTextByKey } from '../../../services/languageService';

function S12Footer(props) {

  function getText(keyFields) {
    return getTextByKey(theTexts, keyFields, props.onTextNotFound)
  }

  if (props.texts) {
    var theTexts = loadSection(JSON_SECTION_S12_FOOTER, props.texts, props.securityInfo);
    return (
      <div className="s12-footer-container">
        <img src={getText(['footerImageUrl'])} alt="" />;
      </div>
    );
  }
}
export default S12Footer;
