import './UserList.css';
import * as constants from '../../../../constants';
import * as backEndService from '../../../../services/backend/backEndService';
import { getTextByKey, getGeneralTextByKey } from '../../../../services/languageService';
import { useEffect, useState } from 'react';

function UserList(props) {

    const [userList, setUserList] = useState([]);

    useEffect(() => {
        getUserList();
      }, []);

    function getText(keyFields) {
        return getTextByKey(theTexts, keyFields, props.onTextNotFound)
    }

    function getGeneralText(keyFields) {
        return getGeneralTextByKey(theTexts, keyFields, props.onTextNotFound)
    }

    var theTexts = props.theTexts;
    var users = [];

    function getUserList() {
        document.getElementById('spinner').className = constants.EMPTY;
        document.getElementById('results').className = "tk-display-none";
        backEndService.getUserList(props.securityInfo.token, handleGetUserList, handleGetUserListFailed)
      }

      function handleGetUserList(data) {
        setUserList(data);
        document.getElementById('spinner').className = "tk-display-none";
        document.getElementById('results').className = constants.EMPTY;
      }

      function handleGetUserListFailed() {
        props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_GET_USER_LIST_FAILED']));
        setUserList(null);
        document.getElementById('spinner').className = "tk-display-none";
        document.getElementById('results').className = constants.EMPTY;
      }

    function editUser(jsonUser) {
        props.goToRoute(constants.ROUTE_EDITUSER + constants.SLASH + jsonUser.trusager);
    }

    function deleteUser(userId) {
        if (window.confirm(getGeneralText(['Messages', 'CONFIRM_REQUEST_FOR_DELETE_USER']))) {
            backEndService.deleteUser(props.securityInfo.token, userId, handleDeleteUser, handleDeleteUserFailed);
        }
    }

    function handleDeleteUser(data) {
        props.displaySuccessMessage(getGeneralText(['Messages', 'SUCCESS_REQUEST_FOR_DELETE_USER']));
        getUserList();
    }

    function handleDeleteUserFailed(){
        props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_MANAGE_USER_DELETE_FAILED']));
    }

    function noUser() {
        return (
            <div key="nouser" className="userlist-table-row userlist-no-user">
                {getText('noUser')}
            </div>
        );
    }
    function createUserLine(i, jsonUser) {
        var style = 'userlist-table-row userlist-table-row-gray';

        if (i % 2 === 0) {
            style = 'userlist-table-row userlist-table-row-white';
        }

        var userTypeText = constants.EMPTY;

        if (jsonUser.user_type === Number(constants.USER_TYPE_USER)) {
            userTypeText = "Utilisateur";
        } else if (jsonUser.user_type === Number(constants.USER_TYPE_ADMIN)) {
            userTypeText = "Administrateur";
        } else if (jsonUser.user_type === Number(constants.USER_TYPE_COMPT)) {
            userTypeText = "Comptable";
        }

        return (
            <div key={i} className={style}>
                <div className="userlist-table-name userlist-inline">
                    <p className="product-description small userlist-vertical-center">{jsonUser.nom}</p>
                </div>

                <div className="userlist-table-user">
                    <p className="product-description small userlist-vertical-center">{jsonUser.code}</p>
                </div>

                <div className="userlist-table-type">
                    <p className="product-description small tk-color-black frb-font-bold tk-uppercase userlist-vertical-center">{userTypeText}</p>
                </div>

                <div className="userlist-table-email">
                    <p className="product-description small userlist-vertical-center">{jsonUser.email}</p>
                </div>

                <div className="userlist-table-password">
                    <p className="product-description small userlist-vertical-center"><span className="fa fa-asterisk" /><span className="fa fa-asterisk" /><span className="fa fa-asterisk" /><span className="fa fa-asterisk" /><span className="fa fa-asterisk" /><span className="fa fa-asterisk" /><span className="fa fa-asterisk" />&nbsp;&nbsp;&nbsp;&nbsp;<span title={jsonUser.passe} className={getText('imageView')}></span></p>
                </div>

                <div className="userlist-table-action">
                    <p className="product-description small userlist-vertical-center">
                        <span onClick={() => editUser(jsonUser)} className={getText('imageEdit')}></span><span className="userlist-gray_color"> | </span><span onClick={() => deleteUser(jsonUser.trusager)} className={getText('imageDelete')}></span>
                    </p>
                </div>
            </div>
        );
    }

    if (!userList || userList.length === 0) {
        users.push(noUser());
    }
    else {
        for (let i = 0; i < userList.length; i++) {
            users.push(createUserLine(i, userList[i]));
        }
    }

    return (
        <>
            <div className="section-wrap">
                <div className="section no-title">
                    <h2 className="subsection-title">{getText('manageTitle')}</h2>
                    <hr className="line-separator" />

                    <div className="userlist-table">
                        <div className="userlist-table-header">
                            <div className="userlist-table-name">
                                <h6 className="title">{getText('manageColName')}</h6>
                            </div>
                            <div className="userlist-table-user">
                                <h6 className="title">{getText('manageColUser')}</h6>
                            </div>
                            <div className="userlist-table-type">
                                <h6 className="title">{getText('manageColType')}</h6>
                            </div>
                            <div className="userlist-table-email">
                                <h6 className="title">{getText('manageColEmail')}</h6>
                            </div>
                            <div className="userlist-table-password">
                                <h6 className="title">{getText('manageColPassword')}</h6>
                            </div>
                            <div className="userlist-table-actions">
                                <h6 className="title">{getText('manageColAction')}</h6>
                            </div>
                        </div>
                        <div id="results">
                            {users}
                        </div>
                        <div id="spinner" className="tk-display-none">
                            <div className="tk-min-height-20 tk-height-20" />
                            <div className="tk-text-center tk-pad-top-20"><img width="30px" alt="" src='/frb/images/common/loading.gif' /><br />
                                <label className="rl-label">Recherche en cours...</label>
                            </div>
                        </div>

                        <div className="form-row userlist-centerize">
                            <button className="button userlist-button-middle" onClick={props.onClickAddUser}>{getText('manageButton')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>);
}

export default UserList;