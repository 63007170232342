import { JSON_SECTION_S2_IMAGE_SLIDER, loadSection, getTextByKey } from '../../../services/languageService';
import './S2ImageSlider.css'

function S2ImageSlider(props) {
  function getText(keyFields) {
    return getTextByKey(theTexts, keyFields, props.onTextNotFound)
  }

  if (props.texts) {
    var theTexts = loadSection(JSON_SECTION_S2_IMAGE_SLIDER, props.texts, props.securityInfo);
    return (
      <div className="main-slider">
        <div className="slide-list">
          <video className="S2-image-slider-videoInsert" src={getText(['list', 0, 'src'])} type='video/mp4' autoPlay={true} muted={true} loop={true} playsInline={true} />
        </div>
      </div>
    );
  }
}
export default S2ImageSlider;
