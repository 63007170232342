import { JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_RESULT, loadSubSection, getTextByKey } from '../../../../../../../services/languageService';
import './TireSubmition.css';

function TireSubmition(props) {

    var theTexts = props.texts;

    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    if (theTexts) {
        var txtSection = loadSubSection(JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_RESULT, theTexts, props.securityInfo);

        return (
            <div className='tk-pad-top-10 tk-pad-bot-10'>
                <div>
                    <span className="button tire-submition-button frb-font-light-bold disabled">{getText('soumSoumission')}</span>
                </div>
                <div className='tk-pad-l-10 tk-pad-top-10 tk-pad-bot-10'>
                    <div className="form-row">
                        <div className="quantity-input">
                            <span className="count">4</span>
                            <div className="increment-count">
                                <svg className="svg-arrow face-up">
                                    <use xlinkHref="#svg-arrow"></use>
                                </svg>
                            </div>
                            <div className="decrement-count">
                                <svg className="svg-arrow face-down">
                                    <use xlinkHref="#svg-arrow"></use>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <span className="button tire-submition-button frb-font-light-bold tk-cursor-pointer">{getText('soumCommander')}</span>
                </div>
            </div>
        );
    }
}
export default TireSubmition;