import { useState } from 'react';
import { JSON_SECTION_CONVERTER, loadSection, getTextByKey, getGeneralTextByKey } from '../../../../services/languageService';
import * as backendService from '../../../../services/backend/backEndService';
import * as stringService from '../../../../services/stringService';
import * as constants from '../../../../constants';
import './ConverterPage.css';

function ConverterPage(props) {

    const [calcResult, setCalcResult] = useState(null);

    const [chosenWidth, setChosenWidth] = useState('205');
    const [chosenThickness, setChosenThickness] = useState('55');
    const [chosenRadius, setChosenRadius] = useState('16');

    const [sizeSelected, setSizeSelected] = useState(constants.STRING_MINUS_ONE);
    const [tireWidth, setTireWidth] = useState(null);
    const [tireThickness, setTireThickness] = useState(null);
    const [tireRadius, setTireRadius] = useState(null);

    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    function getGeneralText(keyFields) {
        return getGeneralTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    function doCalculation() {
        const allFields = new Map();

        allFields.set(constants.MAPPING_CONVERTER_DIM_TIRE_WIDTH, chosenWidth);
        allFields.set(constants.MAPPING_CONVERTER_DIM_TIRE_THINKNESS, chosenThickness);
        allFields.set(constants.MAPPING_CONVERTER_DIM_TIRE_RADIUS, chosenRadius);

        backendService.doDimensionConversionCalc(props.securityInfo.token, handleCalcSuccess, handleCalcFailed, allFields)
        document.getElementById('spinnerConverter').className = constants.EMPTY;
        document.getElementById('results').className = "tk-display-none";
    }

    function handleCalcSuccess(values) {
        setCalcResult(values);
        document.getElementById('spinnerConverter').className = "tk-display-none";
        document.getElementById('results').className = constants.EMPTY;
    }

    function handleCalcFailed() {
        props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_CALCULATION_FAILED']));
        document.getElementById('spinnerConverter').className = "tk-display-none";
        document.getElementById('results').className = constants.EMPTY;
    }


    function handleTireRadiusChange(event) {
        setChosenRadius(event.target.value);
    }

    function handleTireWidthChange(event) {
        setChosenWidth(event.target.value);
    }

    function handleTireThicknessChange(event) {
        setChosenThickness(event.target.value);
    }

    function buildResultSizes() {

        if (calcResult) {

            var dim = [];

            for (let i = 0; i < calcResult.sizes.length; i++) {
                var current = calcResult.sizes[i];
                if (i === sizeSelected) {
                    dim.push(<div key={i}><a className="button converter-page-button tk-cursor-pointer">{current.size}</a></div>);
                } else {
                    dim.push(<div key={i} onClick={() => setSizeSelected(i)}><a className="button dark converter-page-button tk-cursor-pointer">{current.size}</a></div>);
                }
            }

            if (sizeSelected === '-1') {
                dim.push(<div key='all'><a className="button converter-page-button tk-cursor-pointer">{getText('convAll')}</a></div>);
            } else {
                dim.push(<div key='all' onClick={() => setSizeSelected('-1')}><a className="button converter-page-button dark tk-cursor-pointer">{getText('convAll')}</a></div>);
            }


            return (
                <div className="tk-grid tk-bgcolor-white converter-page-dim-result-grid-15-columns">
                    {dim}
                </div>
            );
        }
    }

    function createOptionsForComboBox(textSection, theSetterMethod) {
        var theList = getGeneralText(['Constants', textSection]);
        var tempoOptions = [];
        for (let i = 0; i < theList.length; i++) {
            var key = constants.EMPTY + i
            tempoOptions.push(<option key={key} value={theList[i]}>{theList[i]}</option>);
            //if (i === 0) {
            //    theSetterMethod(theList[i]);
            //}
        }
        return tempoOptions;
    }

    function buildResults() {

        if (calcResult) {

            var res = [];
            var counter = 0;
            var hasResult = false;
            var css = 'converter-page-result frb-color-gray-87 converter-page-result-even';
            var noResultCss = 'converter-page-result frb-color-gray-87 converter-page-result-even tk-grid-col-span-4';
            for (let i = 0; i < calcResult.sizes.length; i++) {
                var current = calcResult.sizes[i];

                if (i === Number(sizeSelected) || sizeSelected === '-1') {

                    for (let j = 0; j < current.elements.length; j++) {
                        var currentResult = current.elements[j];

                        if (counter % 2 === 0) {
                            css = 'converter-page-result frb-color-gray-87 converter-page-result-odd';
                        }
                        var key = constants.EMPTY + i + '_' + j;
                        hasResult = true;
                        res.push(
                            <div key={key} className="tk-grid tk-bgcolor-white converter-page-dim-result-grid-4-columns">
                                <div className={css}>{currentResult.dimension}</div>
                                <div className={css}>{stringService.numberToStringWith2Decimals(currentResult.totalDiameter)}</div>
                                <div className={css}>{stringService.numberToStringWith2Decimals(currentResult.delta)}</div>
                                <div className={css}>{stringService.numberToStringWith2Decimals(currentResult.speed)}</div>
                            </div>
                        );
                        counter++;
                    }
                }
            }

            if (!hasResult) {
                res.push(
                    <div key='no-result' className="tk-grid tk-bgcolor-white converter-page-dim-result-grid-4-columns">
                        <div className={noResultCss}>{getText('convNoResult')}</div>
                    </div>
                );
            }

            return (<>
                <div className="tk-grid tk-bgcolor-white converter-page-dim-result-grid-4-columns">
                    <div className="converter-page-result-top">{getText('convResultCol1')}</div>
                    <div className="converter-page-result-top">{getText('convResultCol2')}</div>
                    <div className="converter-page-result-top">{getText('convResultCol3')}</div>
                    <div className="converter-page-result-top">{getText('convResultCol4')}</div>
                </div>
                {res}
            </>
            );
        }
    }

    function displayResult() {

        if (calcResult) {
            return (
                <>
                    <div className="tk-min-height-20 tk-height-20" />
                    <div className="converter-page-gray-line" />
                    <div className="tk-min-height-20 tk-height-20" />
                    <div className="tk-pad-l-20">
                        <div className="tk-pad-bot-10 frb-color-gray-87">
                            {getText('convDimCalc')} <span className="converter-page-diameter">{stringService.numberToStringWith2Decimals(calcResult.totalDiameter)}</span>
                        </div>
                        {buildResultSizes()}
                        {buildResults()}
                    </div>
                </>
            );
        }
    }
    if (props.texts) {
        var txtSection = loadSection(JSON_SECTION_CONVERTER, props.texts, props.securityInfo);

        if (tireRadius == null) {
            if (tireRadius == null) {
                setTireRadius(createOptionsForComboBox('TIRE_RADIUS', setChosenRadius));
                setTireThickness(createOptionsForComboBox('TIRE_THICKNESS', setChosenThickness));
                setTireWidth(createOptionsForComboBox('TIRE_WIDTH', setChosenWidth));
            }
        }
        return (
            <>
                <div className="tk-pad-l-20">
                    <div className="converter-page-rl-label">{getText('convTitle')}</div>
                    <div className="tk-grid tk-bgcolor-white converter-page-dim-result-grid-6-columns">
                        <div className="tk-width-85 tk-float-left">
                            <label htmlFor="tireWidth" className="select-block">
                                <select name="tireWidth" id="tireWidth" value={chosenWidth} onChange={handleTireWidthChange} className="tk-height-30 tk-lh-30">
                                    {tireWidth}
                                </select>
                                <svg className="svg-arrow converter-page-force-top face-down">
                                    <use xlinkHref="#svg-arrow"></use>
                                </svg>
                            </label>
                        </div>
                        <div className="tk-pad-top-5"><span className="converter-page-rl-label">{getText('slash')}</span></div>
                        <div className="tk-width-85 tk-float-left">
                            <label htmlFor="tireThickness" className="select-block">
                                <select name="tireThickness" id="tireThickness" value={chosenThickness} onChange={handleTireThicknessChange} className="tk-height-30 tk-lh-30">
                                    {tireThickness}
                                </select>
                                <svg className="svg-arrow converter-page-force-top face-down">
                                    <use xlinkHref="#svg-arrow"></use>
                                </svg>
                            </label>
                        </div>
                        <div className="tk-pad-top-5"><span className="converter-page-rl-label">{getText('r')}</span></div>
                        <div className="tk-width-85 tk-float-left">
                            <label htmlFor="tireRadius" className="select-block">
                                <select name="tireRadius" id="tireRadius" value={chosenRadius} onChange={handleTireRadiusChange} className="tk-height-30 tk-lh-30">
                                    {tireRadius}
                                </select>
                                <svg className="svg-arrow converter-page-force-top face-down">
                                    <use xlinkHref="#svg-arrow"></use>
                                </svg>
                            </label>
                        </div>
                        <div className="tk-pad-l-20" onClick={doCalculation}> <a href="#" className="button converter-page-calc-button">{getText('convButtonCalc')}</a> </div>
                    </div>
                </div>
                <div id="spinnerConverter" className="tk-display-none">
                    <div className="tk-min-height-20 tk-height-20" />
                    <div className="converter-page-gray-line" />
                    <div className="tk-text-center tk-pad-top-20"><img alt="" width="30px" src={getText('loadingImageUrl')} /><br />
                        <span className="rl-label">{getText('calcInProgress')}</span>
                    </div>
                </div>
                <div id="results">
                    {displayResult()}
                </div>
            </>

        );

    }
}
export default ConverterPage;
