import S2ImageSlider from "./S2ImageSlider/S2ImageSlider";
import S3ThreeProductsBar from "./S3ThreeProductsBar/S3ThreeProductsBar";
import S4Publicity from "./S4Publicity/S4Publicity";
import S5Career from "./S5Career/S5Career";
import S6FourFeatures from "./S6FourFeatures/S6FourFeatures";
import S7ThreeColorBox from "./S7ThreeColorBox/S7ThreeColorBox";
import S8ThreeElements from "./S8ThreeElements/S8ThreeElements";
import S9Promo from "./S9Promo/S9Promo";
import S10FeatureProducts from "./S10FeatureProducts/S10FeatureProducts";
import S11NewsLetter from "./S11NewsLetter/S11NewsLetter";

function HomePage(props) {

  return (
    <>
      <S2ImageSlider onTextNotFound={props.onTextNotFound} texts={props.texts} securityInfo={props.securityInfo}/>
      <S3ThreeProductsBar onTextNotFound={props.onTextNotFound} loginRedirect={props.loginRedirect}  texts={props.texts} securityInfo={props.securityInfo}/>
      <S4Publicity onTextNotFound={props.onTextNotFound} texts={props.texts} securityInfo={props.securityInfo}/>
      <S5Career onTextNotFound={props.onTextNotFound} texts={props.texts} securityInfo={props.securityInfo}/>
      <S6FourFeatures onTextNotFound={props.onTextNotFound} texts={props.texts} securityInfo={props.securityInfo}/>
      <S7ThreeColorBox onTextNotFound={props.onTextNotFound} texts={props.texts} securityInfo={props.securityInfo} />
      <S8ThreeElements onTextNotFound={props.onTextNotFound} texts={props.texts} securityInfo={props.securityInfo}/>
      <S9Promo loginRedirect={props.loginRedirect} texts={props.texts} securityInfo={props.securityInfo}/>
      <S10FeatureProducts loginRedirect={props.loginRedirect} texts={props.texts} securityInfo={props.securityInfo}/>
      <S11NewsLetter onTextNotFound={props.onTextNotFound} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} securityInfo={props.securityInfo}/>
    </>
  );
}

export default HomePage;