import * as constants from '../../../../../../../constants';

import './ResultPagination.css';

function ResultPagination(props) {

    var theTexts = props.texts;
    var comboBoxDisplay = false;

    function comboValueChange(event){
        changePageToDisplay(Number(event.target.value));
    }

    function changePageToDisplay(pageNumber) {
        props.changePageToDisplay(pageNumber);
    }

    if (theTexts && props.jsonData) {        
        var isFirst = false;
        var isLast = false;

        var productTotal = props.jsonData.summary.productTotal;
        var nbrPerPage = props.jsonData.summary.productDisplay;
        var currentPage = props.jsonData.summary.pageToDisplay;

        var nbrPage = Math.floor(productTotal / nbrPerPage);

        if ((productTotal % nbrPerPage) > 0) {
            nbrPage = (nbrPage + 1);
        }

        if (currentPage < 1) {
            currentPage = 1;
        }

        if (currentPage > nbrPage) {
            currentPage = nbrPage;
        }

        if (currentPage === 1) {
            isFirst = true;
        }

        if (currentPage >= nbrPage) {
            isLast = true;
        }

        if (nbrPage <= 9) {
            comboBoxDisplay = false;
        } else {
            comboBoxDisplay = true;
        }

        var thePages = [];
        var theCombo = [];
        var beforeArrow = constants.EMPTY;
        var afterArrow = constants.EMPTY;

        if (!isFirst && currentPage > 0) {
            beforeArrow = (
                <li className="pager-item result-pagination-box result-pagination-box-previous tk-cursor-pointer" onClick={() => changePageToDisplay(currentPage - 1)}>
                    <svg className="svg-arrow medium inverted result-pagination-box-arrow">
                        <use xlinkHref="#svg-arrow"></use>
                    </svg>
                </li>);
        }

        if (comboBoxDisplay) {

            var options = [];
            for (let i = 0; i < nbrPage; i++) {
                var key = 'pagination_option_' + i;
                if (currentPage === (i + 1)) {
                    options.push(<option key={key} value={(i+1)}>{(i+1)}</option>);
                }else{
                    options.push(<option key={key} value={(i+1)}>{(i+1)}</option>);
                }

            }

            theCombo.push(
                <div key='pagination_combo' className="form-row">
                    <label htmlFor="comboPagination" className="select-block">
                        <select onChange={comboValueChange} value={currentPage} className='result-pagination-combo-box' name="comboPagination" id="comboPagination">
                            {options}
                        </select>
                        <svg className="svg-arrow face-down">
                            <use xlinkHref="#svg-arrow"></use>
                        </svg>
                    </label>
                </div>
            );

        } else {
            for (let i = 0; i < nbrPage; i++) {
                var selectedCss = 'pager-item result-pagination-box tk-cursor-pointer';
                var keyPage = 'pagination_' + i;
                if (currentPage === (i + 1)) {
                    selectedCss = 'pager-item result-pagination-box tk-cursor-pointer selected'
                }
                thePages.push(<li key={keyPage} className={selectedCss} onClick={() => changePageToDisplay((i + 1))}><span>{i + 1}</span></li>);
            }
        }
        if (!isLast) {
            afterArrow = (
                <li className="pager-item result-pagination-box result-pagination-box-next tk-cursor-pointer" onClick={() => changePageToDisplay(currentPage + 1)}>
                    <svg className="svg-arrow medium result-pagination-box-arrow">
                        <use xlinkHref="#svg-arrow"></use>
                    </svg>
                </li>
            );
        }

        if (comboBoxDisplay) {
            return (
                <div className="pager-wrap result-pagination-margin">
                    <div className='result-pagination-div'>
                        <div className='tk-grid result-pagination-grid-3-colons'>
                            <div>
                                <ul className="pager">
                                    {beforeArrow}
                                </ul>
                            </div>
                            <div>
                                {theCombo}
                            </div>
                            <div>
                                <ul className="pager">
                                    {afterArrow}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <div className="pager-wrap tk-text-center">
                        <ul className="pager">
                            {beforeArrow}
                        </ul>
                        <ul className="pager">
                            {thePages}
                        </ul>
                        <ul className="pager">
                            {afterArrow}
                        </ul>
                    </div>
                </>
            );
        }
    }
}

export default ResultPagination;