import { JSON_SECTION_S4_PUBLICITY, loadSection, getTextByKey } from '../../../services/languageService';

function S4Publicity(props) {
  function getText(keyFields) {
    return getTextByKey(theTexts, keyFields, props.onTextNotFound)
  }

  if (props.texts) {
    var theTexts = loadSection(JSON_SECTION_S4_PUBLICITY, props.texts, props.securityInfo);

    return (

      <div className="section-wrap welcome-section-wrap">
        <div className="welcome-section section">
          <h6 className="pretitle">{getText('pretitle')}</h6>
          <h3 className="title large">{getText('title')}</h3>
          <hr className="line-separator short" />
          <p className="section-description">{getText('desc')}</p>
          <div className="tk-mar-top-60"><img width="100%" src={getText('bgImageUrl')} alt="" /></div>
        </div>
      </div>
    );
  }
}
export default S4Publicity;
