import './OldSite.css';
import { JSON_SECTION_OLDSITE_PAGE, loadSection, getTextByKey } from '../../../services/languageService';
import * as constants from '../../../constants';

function OldSite(props) {
  const NEW = 0;
  const LOGIN_ASKED = 1;
  const REQUESTED_PAGE_ASKED = 3;
  
  var loginDone = false;
  var url = props.url;
  var navigationStatus = NEW;

  function internGoToRoute(url) {
    props.goToRoute(url);
  }
  
  function getText(keyFields) {
    return getTextByKey(theTexts, keyFields, props.onTextNotFound)
  }

  function iFrameLoaded(event) {
    if (navigationStatus === NEW) {
      if (!loginDone) {
        loginDone = true;
        doLoginToOldSite();
      } else {
        return;
      }
    }
    else if (navigationStatus === LOGIN_ASKED) {
      afterGoToRequestedPage();
    } else if (navigationStatus === REQUESTED_PAGE_ASKED) {
      document.getElementById('oldsite-waitingBox').className = 'tk-display-none';
      document.getElementById('oldsite-iFrameDiv').className = 'tk-display-block';
    }
  }

  function doLoginToOldSite() {
    navigationStatus = LOGIN_ASKED;
    document.getElementById("oldsite-loginform").submit();
  }

  function afterGoToRequestedPage() {
    navigationStatus = REQUESTED_PAGE_ASKED;
    document.getElementById('oldsite-iframe').src = url;
  }

  if (props.texts) {
    var theTexts = loadSection(JSON_SECTION_OLDSITE_PAGE, props.texts, props.securityInfo);

    return (
      <>
        <form id="oldsite-loginform" target="oldsite-iframe" action={props.oldsite} method="post">
          <input type="hidden" name="txtAction" value="login" />
          <input type="hidden" name="txtClient" value={props.loginAccount} />
          <input type="hidden" name="txtUser" value={props.loginUser} />
          <input type="hidden" name="txtPwd" value={props.loginPassword} />
        </form>

        <div className="widget-line-wrap">
          <div className="widget-line oldsite-maximize-line">
            <ul className="actions-list left">
              <li className="actions-list-item">
                <div className="social-link oldsite-width-large">
                  <div className='oldsite-back-button tk-cursor-pointer' onClick={() => internGoToRoute(constants.ROUTE_HOME)}>
                    {getText('back')}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div id='oldsite-iFrameDiv' className='tk-display-none'>
          <iframe onLoad={iFrameLoaded} id="oldsite-iframe" name="oldsite-iframe" className="oldsite-full-Iframe" src={getText('waintingPageUrl')} title="description"></iframe>
        </div>
        <div id='oldsite-waitingBox' className='tk-display-block'>
          <iframe className="oldsite-full-Iframe" src={getText('waintingPageUrl')} title="waiting"></iframe>
        </div>
      </>
    );
  }
}

export default OldSite;