const LOCAL_STORE_TITLE = 'FRB_CONTEXT';
var templateLoginInfo = { 'loginAccount': '', 'loginUser': '', 'loginPassword': '', 'loginRememberMe': false };
var templateTokenInfo = { 'token': '', 'name': '', 'admin': false };

function getNewContext() {
  return { 'loginInfo': templateLoginInfo, 'tokenInfo': templateTokenInfo };
}

export function isAdmin(){
  return true;
}

export function getSecurityInfo() {
  return loadContextFromLocalStorage().tokenInfo;
}

function loadContextFromLocalStorage() {
  var jsonString = localStorage.getItem(LOCAL_STORE_TITLE);
  if (jsonString === null || jsonString === 'null' || jsonString === '[object Object]' || jsonString === 'undefined') {
    return getNewContext();;
  } else {
    return JSON.parse(jsonString);
  }
}

function saveContextToLocalStorage(jsonObj) {
  if (jsonObj === null || jsonObj === 'null') {
    localStorage.removeItem(LOCAL_STORE_TITLE);
  } else {
    localStorage.setItem(LOCAL_STORE_TITLE, JSON.stringify(jsonObj));
  }
}

export function getLoginInfo() {
  return loadContextFromLocalStorage().loginInfo;
}

export function logout() {
  var currentContext = loadContextFromLocalStorage();

  currentContext.tokenInfo.token = null;
  currentContext.tokenInfo.name = null;
  currentContext.tokenInfo.admin = null;

  saveContextToLocalStorage(currentContext);
}

export function saveAuthenticationResponse(jsonToken) {
  var currentContext = loadContextFromLocalStorage();

  currentContext.tokenInfo.token = jsonToken.accessToken;
  currentContext.tokenInfo.name = jsonToken.name;
  currentContext.tokenInfo.admin = jsonToken.admin;

  saveContextToLocalStorage(currentContext);
}

export function saveLoginInfo(loginAccount, loginUser, loginPassword, rememberMe) {
  var currentContext = loadContextFromLocalStorage();

  currentContext.loginInfo.loginAccount = loginAccount;
  currentContext.loginInfo.loginUser = loginUser;
  currentContext.loginInfo.loginPassword = loginPassword;
  currentContext.loginInfo.loginRememberMe = rememberMe;

  saveContextToLocalStorage(currentContext);
}
