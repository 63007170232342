import './S1TopMenuItem.css';
import * as validationService from '../../../../services/validationService'

function S1TopMenuItem(props) {

  var isSmall = props.small === true;

  var lastCalled = 0;
    function callGoToRoute(route) {
        var callTime = Date.now();
        if ((callTime - lastCalled) > 1000) {
            lastCalled = callTime;
            internGoToRoute(route);
        }
    }


  function internGoToRoute(url) {
    props.goToRoute(url);
  }

  if (props.menuType === 'welcomeMenu') {
    var theName = props.menu.welcome.name;
    var userName = props.securityInfo.name;

    if (validationService.validateStringNotEmpty(userName)) {
      theName = theName + ', ' + userName;
    }

    if (props.menu.welcome.route) {
      return (
        <>
          <li className="actions-list-item S1-topmenu-item-vertcenter S1-topmenu-item-noborder tk-mar-l-40">
            <img className="S1-topmenu-item-user-img" alt="" src={props.menu.welcome.userImageUrl} />
          </li>
          <li className="actions-list-item S1-topmenu-item-noborder S1-topmenu-item-bg-topMenu-color">
            <div className="S1-topmenu-style-div-route tk-cursor-pointer" onClick={() => callGoToRoute(props.menu.welcome.route)}>{theName}</div>
          </li>
        </>
      );
    } else {
      return (
        <>
          <li className="actions-list-item S1-topmenu-item-vertcenter S1-topmenu-item-noborder tk-mar-l-40">
            <img className="S1-topmenu-item-user-img" alt="" src={props.menu.welcome.userImageUrl} />
          </li>
          <li className="actions-list-item S1-topmenu-item-noborder S1-topmenu-item-bg-topMenu-color">
            <a className="action-button" href={props.menu.welcome.href}>{theName}</a>
          </li>
        </>
      );
    }

  } else {
    if (isSmall) {
      if (props.menu.route) {
        return (
          <li className="actions-list-item S1-topmenu-item-bg-topMenu-color">
            <div className="S1-topmenu-style-div-route tk-cursor-pointer" onClick={() => callGoToRoute(props.menu.route)}><i className={props.menu.iconSmall}></i></div>
          </li>
        );

      } else {
        return (
          <li className="actions-list-item S1-topmenu-item-bg-topMenu-color">
            <a className="action-button" href={props.menu.href}><i className={props.menu.iconSmall}></i></a>
          </li>
        );
      }
    } else {
      if (props.menu.route) {
        return (
          <li className="actions-list-item S1-topmenu-item-bg-topMenu-color">
            <div className="S1-topmenu-style-div-route tk-cursor-pointer" onClick={() => callGoToRoute(props.menu.route)}>{props.menu.name}</div>
          </li>
        );
      } else {
        return (
          <li className="actions-list-item S1-topmenu-item-bg-topMenu-color">
            <a className="action-button" href={props.menu.href}>{props.menu.name}</a>
          </li>);
      }
    }
  }
}

export default S1TopMenuItem;
