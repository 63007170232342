import CheckBoxFilter from './CheckBoxFilter/CheckBoxFilter';
import SliderFilter from './SliderFilter/SliderFilter';
import * as constants from '../../../../../constants';
import { JSON_SECTION_TIRE_FILTERS, loadSection, getTextByKey } from '../../../../../services/languageService';

import * as validationService from '../../../../../services/validationService';
import { useEffect, useState } from 'react';

export function sortObjectByKey(obj) {
    return new Map([...obj].sort((a, b) => String(a[0]).localeCompare(b[0])))
}

export function getAllPossibilities(jsonData, attributesList) {
    const allPossibilities = new Map();

    for (let i = 0; i < jsonData.results.length; i++) {
        var result = jsonData.results[i];
        var current = result;
        var attribute = '';

        var mustHoldOneElement = new Map();
        for (let k = 0; k < attributesList.length; k++) {
            current = result;
            attribute = '';
            var attributes = attributesList[k];

            if (Array.isArray(attributes)) {
                for (let j = 0; j < attributes.length - 1; j++) {
                    if (current[attributes[j]]){
                    current = current[attributes[j]];
                }else{
                    continue;
                }
                }

                attribute = attributes[attributes.length - 1];
            } else {
                attribute = attributes;
            }

            if (validationService.isString(current[attribute]) || validationService.isNumber(current[attribute]) || validationService.isBoolean(current[attribute])) {
                var stringObj = constants.EMPTY + current[attribute];
                mustHoldOneElement.set(stringObj, stringObj);
            }
        }

        if (mustHoldOneElement.size === 1) {
            var stringObj2 = Array.from(mustHoldOneElement)[0][0];
            var currentNumber = allPossibilities.get(stringObj2);
            if (currentNumber) {
                currentNumber = Number(currentNumber) + 1;
            } else {
                currentNumber = 1;
            }
            allPossibilities.set(stringObj2, currentNumber);
        }
    }
    return sortObjectByKey(allPossibilities);
}

function TireResultFilters(props) {

    const [filterValues, setFilterValues] = useState(new Map());
    const [lastSearchTime, setLastSearchTime] = useState(props.tireSearchTime);

    useEffect(() => {
        if (lastSearchTime !== props.tireSearchTime) {
            setFilterValues(new Map());
            setLastSearchTime(props.tireSearchTime);
        }
    }, [props.tireSearchTime]);

    function onTextNotFound(msg) {}

    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, onTextNotFound)
    }

    function createJsonElementsPriceSlider(jsonData) {
        var uniqueName = "filter_price_slider";
        return {
            "type": constants.FILTER_TYPE_SLIDER,
            "title": 'Prix (comptoir)',
            "minValue": jsonData.summary.priceRangeMin,
            "maxValue": jsonData.summary.priceRangeMax,
            "Legend": 'Prix de ${1} à ${2}',
            "uniqueName": uniqueName,
            "dataSource": ['filters',uniqueName],
        }
    }

    function createCheckBoxes(allRows, dataSource, uniqueName) {
        var data = [];

        for (let [key, value] of allRows) {
            data.push(
                {
                    "name": key,
                    "value": key,
                    "quantity": value,
                    "checked": false,
                }
            );
        }

        return {
            "type": constants.FILTER_TYPE_CHECKBOX,
            "title": "",
            "uniqueName": uniqueName,
            "dataSource": dataSource,
            "maxDisplay": -1,
            "maxDisplayText": "",
            "minDisplayText": "",
            "elements": data
        }
    }

    function setTexts(jsonToReturn, uniqueName) {
        jsonToReturn.title = getText(uniqueName);
        var sizeList = txtSection[uniqueName + '_size'];

        if (sizeList) {
            jsonToReturn.maxDisplay = sizeList.value;
            jsonToReturn.maxDisplayText = sizeList.txt_more;
            jsonToReturn.minDisplayText = sizeList.txt_less;
        }

        var choicesList = txtSection[uniqueName + '_choices'];
        var allPossibilities = new Map();
        var other = 'Autres';
        if (choicesList) {
            for (let i = 0; i < choicesList.length; i++) {
                var key = choicesList[i].key;
                var value = choicesList[i].value;
                allPossibilities.set(key, value);
                if (key === 'OTHERS') {
                    other = value;
                }
            }

            if (jsonToReturn.elements) {
                for (let j = 0; j < jsonToReturn.elements.length; j++) {
                    var current = jsonToReturn.elements[j];
                    var key2 = current.name;
                    var value2 = allPossibilities.get(key2)
                    if (value2) {
                        current.name = value2;
                    } else {
                        current.name = other;
                    }
                }
            }
        }
        return jsonToReturn;
    }

    function createJsonElementsCategory(jsonData) {
        var uniqueName = 'filter_checkbox_category';
        var dataSource = [['front', 'raw', 'season'],['back', 'raw', 'season']];
        var toReturn = createCheckBoxes(getAllPossibilities(jsonData, dataSource), ['filters',uniqueName], uniqueName);
        return (setTexts(toReturn, uniqueName));
    }

    function createJsonElementsRunFlat(jsonData) {
        var uniqueName = 'filter_checkbox_runflat';
        var dataSource = [['front', 'raw', 'runflat'],['back', 'raw', 'runflat']];
        var toReturn = createCheckBoxes(getAllPossibilities(jsonData, dataSource), ['filters', uniqueName], uniqueName);
        return (setTexts(toReturn, uniqueName));
    }

    function createJsonElementsBrand(jsonData) {
        var uniqueName = 'filter_checkbox_brand';
        var dataSource = [['front', 'raw', 'brand'],['back', 'raw', 'brand']];
        var toReturn = createCheckBoxes(getAllPossibilities(jsonData, dataSource), ['filters', uniqueName], uniqueName);
        return (setTexts(toReturn, uniqueName));
    }

    function createJsonElementsPerf(jsonData) {
        var dataSource = [['raw', 'TODO'],['raw', 'TODO']];
        //var boxes = createCheckBoxes(getAllPossibilities(jsonData, dataSource), dataSource, "filter_checkbox_category_perf");

        var boxes = [
            {
                "name": "A/T",
                "quantity": 32,
                "checked": false,
            },
            {
                "name": "H/T",
                "quantity": 16,
                "checked": false,
            },
            {
                "name": "Performance",
                "quantity": 2,
                "checked": false,
            },
            {
                "name": "Tourisme",
                "quantity": 2,
                "checked": false,
            }
        ]

        return {
            "type": constants.FILTER_TYPE_CHECKBOX,
            "title": "Catégorie de performance",
            "uniqueName": "filter_checkbox_category_perf",
            "dataSource": dataSource,
            "elements": boxes
        }
    }

    function createJsonElementsNail(jsonData) {
        var uniqueName = 'filter_checkbox_nail_option';
        var dataSource = [['front', 'raw', 'nailable'], ['back', 'raw', 'nailable']];
        var toReturn = createCheckBoxes(getAllPossibilities(jsonData, dataSource), ['filters', uniqueName], uniqueName);
        return (setTexts(toReturn, uniqueName));
    }

    function createJsonElementsGamCharge(jsonData) {
            var uniqueName = 'filter_checkbox_charge';
            var dataSource = [['front', 'raw', 'tireLoadType'], ['back', 'raw', 'tireLoadType']];
            var toReturn = createCheckBoxes(getAllPossibilities(jsonData, dataSource), ['filters', uniqueName], uniqueName);
            return (setTexts(toReturn, uniqueName));


        /*
        var dataSource = [['raw', 'TODO'],['raw', 'TODO']];
        //var boxes = createCheckBoxes(getAllPossibilities(jsonData, dataSource), dataSource, "filter_checkbox_charge");

        var boxes = [
            {
                "name": "SL",
                "quantity": 32,
                "checked": false,
            },
            {
                "name": "XL",
                "quantity": 16,
                "checked": false,
            },
            {
                "name": "D",
                "quantity": 16,
                "checked": false,
            },
            {
                "name": "E",
                "quantity": 16,
                "checked": false,
            }
        ]
        return {
            "type": constants.FILTER_TYPE_CHECKBOX,
            "title": "Gamme de charges",
            "uniqueName": "filter_checkbox_charge",
            "dataSource": dataSource,
            "elements": boxes
        }
*/
    }

    function createJsonElementsMinCharge(jsonData) {
        var uniqueName = 'filter_checkbox_charge_min';
        var dataSource = [['front', 'raw', 'minLoad'], ['back', 'raw', 'minLoad']];
        var toReturn = createCheckBoxes(getAllPossibilities(jsonData, dataSource), ['filters', uniqueName], uniqueName);
        return (setTexts(toReturn, uniqueName));
    }

    function createJsonElementsMinSpeed(jsonData) {
        var uniqueName = 'filter_checkbox_speed_min';
        var dataSource = [['front', 'raw', 'speedIndex'], ['back', 'raw', 'speedIndex']];
        var toReturn = createCheckBoxes(getAllPossibilities(jsonData, dataSource), ['filters', uniqueName], uniqueName);
        return (setTexts(toReturn, uniqueName));
    }

    function createJsonElementsColor(jsonData) {

        var uniqueName = 'filter_checkbox_color';
        var dataSource = [['front', 'raw', 'wall_color'], ['back', 'raw', 'wall_color']];
        var toReturn = createCheckBoxes(getAllPossibilities(jsonData, dataSource), ['filters', uniqueName], uniqueName);
        return (setTexts(toReturn, uniqueName));
    }

    function createJsonElementsStripe(jsonData) {
        var dataSource = [['raw', 'TODO'], ['raw', 'TODO']];
        var boxes = [
            {
                "name": "Asymétrique",
                "quantity": 32,
                "checked": false,
            },
            {
                "name": "Directionnelle",
                "quantity": 16,
                "checked": false,
            },
            {
                "name": "Symétrique",
                "quantity": 16,
                "checked": false,
            }
        ]

        return {
            "type": constants.FILTER_TYPE_CHECKBOX,
            "title": "Bande de roulement",
            "uniqueName": "filter_checkbox_stripe",
            "dataSource": dataSource,
            "elements": boxes
        }

    }

    function createJsonElementsOrigine(jsonData) {
        var dataSource = [['raw', 'TODO'], ['raw', 'TODO']];

        var boxes = [
            {
                "name": "Option 1",
                "quantity": 32,
                "checked": false,
            },
            {
                "name": "Option 2",
                "quantity": 16,
                "checked": false,
            }
        ]

        return {
            "type": constants.FILTER_TYPE_CHECKBOX,
            "title": "Équipement d'origine",
            "uniqueName": "filter_checkbox_origin_equip",
            "dataSource": dataSource,
            "elements": boxes
        }
    }

    function priceSlicerButtonClicked(sliderId, filterType, dataSource, minPrice, maxPrice) {
        processFilters(sliderId, filterType, dataSource, [minPrice, maxPrice]);
    }

    function checkBoxClicked(checkBoxId, filterType, dataSource, selectedValuesArray) {
        processFilters(checkBoxId, filterType, dataSource, selectedValuesArray);
    }

    function processFilters(filterName, filterType, dataSource, selectedValuesArray) {
        var clone = new Map(filterValues)

        var data = {
            "dataSource": dataSource,
            "filterType": filterType,
            "selection": selectedValuesArray
        }

        clone.set(filterName, data);
        setFilterValues(clone);

        props.tireSearchFilterApplied(clone);

    }

    function createJsonElements(jsonData) {
        var filters = [];

        filters.push(createJsonElementsPriceSlider(jsonData)); //OK
        filters.push(createJsonElementsCategory(jsonData)); //OK
        filters.push(createJsonElementsRunFlat(jsonData));
        filters.push(createJsonElementsBrand(jsonData)); //OK
        //filters.push(createJsonElementsPerf(jsonData));
        filters.push(createJsonElementsNail(jsonData));//OK
        filters.push(createJsonElementsGamCharge(jsonData)); //tireLoadType
        filters.push(createJsonElementsMinCharge(jsonData));
        filters.push(createJsonElementsMinSpeed(jsonData));
        filters.push(createJsonElementsColor(jsonData)); //wall_color //flanc
        //filters.push(createJsonElementsStripe(jsonData)); //threading - BANDE DE ROULEMENT
        //filters.push(createJsonElementsOrigine(jsonData)); //oemPart

        return {
            "filters": filters
        }
    }

    if (props.texts) {
        var txtSection = loadSection(JSON_SECTION_TIRE_FILTERS, props.texts, props.securityInfo);

        if (props.jsonData) {
            var jsonElements = createJsonElements(props.jsonData);
            var filters = [];

            if (jsonElements && jsonElements.filters) {
                for (let i = 0; i < jsonElements.filters.length; i++) {
                    var current = jsonElements.filters[i];

                    if (current.type === constants.FILTER_TYPE_CHECKBOX) {
                        filters.push(<CheckBoxFilter tireSearchTime={props.tireSearchTime} action={checkBoxClicked} key={current.uniqueName} firstLine={i === 0} isOpen={true} jsonData={current} />);
                    } else if (current.type === constants.FILTER_TYPE_SLIDER) {
                        filters.push(<SliderFilter tireSearchTime={props.tireSearchTime} action={priceSlicerButtonClicked} key={current.uniqueName} firstLine={i === 0} isOpen={true} jsonData={current} />);
                    }
                }
            }

            return (
                <>
                    {filters}
                </>
            );
        }
    }
}

export default TireResultFilters;