import { useState } from 'react';
import { JSON_SECTION_CONVERTER, loadSection, getTextByKey, getGeneralTextByKey } from '../../../../services/languageService';
import * as backendService from '../../../../services/backend/backEndService';
import * as stringService from '../../../../services/stringService';
import * as constants from '../../../../constants';
import './ComparePage.css';

function ComparePage(props) {

    const [calcResult, setCalcResult] = useState(null);

    const [chosenWidth, setChosenWidth] = useState('205');
    const [chosenThickness, setChosenThickness] = useState('55');
    const [chosenRadius, setChosenRadius] = useState('16');

    const [chosenWidth2, setChosenWidth2] = useState('195');
    const [chosenThickness2, setChosenThickness2] = useState('65');
    const [chosenRadius2, setChosenRadius2] = useState('15');

    const [tireWidth, setTireWidth] = useState(null);
    const [tireThickness, setTireThickness] = useState(null);
    const [tireRadius, setTireRadius] = useState(null);


    function doCalculation() {
        const allFields = new Map();

        allFields.set(constants.MAPPING_CONVERTER_COMP_TIRE_WIDTH, chosenWidth);
        allFields.set(constants.MAPPING_CONVERTER_COMP_TIRE_THINKNESS, chosenThickness);
        allFields.set(constants.MAPPING_CONVERTER_COMP_TIRE_RADIUS, chosenRadius);
        allFields.set(constants.MAPPING_CONVERTER_COMP_TIRE_WIDTH2, chosenWidth2);
        allFields.set(constants.MAPPING_CONVERTER_COMP_TIRE_THINKNESS2, chosenThickness2);
        allFields.set(constants.MAPPING_CONVERTER_COMP_TIRE_RADIUS2, chosenRadius2);

        backendService.doDimensionCompareCalc(props.securityInfo.token, handleCalcSuccess, handleCalcFailed, allFields)
        document.getElementById('spinnerComparer').className = constants.EMPTY;
        document.getElementById('results').className = "tk-display-none";
    }

    function handleCalcSuccess(values) {
        var newValues = {
            "dim1": (stringService.numberToStringWith2Decimals(values.original.totalDiameter) + ' po'),
            "dim2": (stringService.numberToStringWith2Decimals(values.compared.totalDiameter) + ' po'),
            "percDiff": (stringService.numberToStringWith2Decimals(values.delta) + '%'),
            "speedDiff30": stringService.numberToStringWith2Decimals(((30.0 * values.delta / 100.0)) + 30),
            "speedDiff50": stringService.numberToStringWith2Decimals(((50.0 * values.delta / 100.0)) + 50),
            "speedDiff70": stringService.numberToStringWith2Decimals(((70.0 * values.delta / 100.0)) + 70),
            "speedDiff90": stringService.numberToStringWith2Decimals(((90.0 * values.delta / 100.0)) + 90),
            "speedDiff100": stringService.numberToStringWith2Decimals(((100.0 * values.delta / 100.0)) + 100),
            "speedDiff110": stringService.numberToStringWith2Decimals(((110.0 * values.delta / 100.0)) + 110),
            "speedDiff120": stringService.numberToStringWith2Decimals(((120.0 * values.delta / 100.0)) + 120),
            "speedDiff130": stringService.numberToStringWith2Decimals(((130.0 * values.delta / 100.0)) + 130),
        }

        setCalcResult(newValues);
        document.getElementById('spinnerComparer').className = "tk-display-none";
        document.getElementById('results').className = constants.EMPTY;
    }

    function handleCalcFailed() {
        props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_CALCULATION_FAILED']));
        setCalcResult(null);
        document.getElementById('spinnerComparer').className = "tk-display-none";
        document.getElementById('results').className = constants.EMPTY;
    }

    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    function getGeneralText(keyFields) {
        return getGeneralTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    function createOptionsForComboBox(textSection, theSetterMethod, theSetterMethod2) {
        var theList = getGeneralText(['Constants', textSection]);
        var tempoOptions = [];
        for (let i = 0; i < theList.length; i++) {
            var key = constants.EMPTY + i;
            tempoOptions.push(<option key={key} value={theList[i]}>{theList[i]}</option>);
            if (i === 0) {
                //  theSetterMethod(theList[i]);
                // theSetterMethod2(theList[i]);
            }
        }
        return tempoOptions;
    }

    function handleTireRadiusChange(event) {
        setChosenRadius(event.target.value);
    }

    function handleTireWidthChange(event) {
        setChosenWidth(event.target.value);
    }

    function handleTireThicknessChange(event) {
        setChosenThickness(event.target.value);
    }

    function handleTireRadiusChange2(event) {
        setChosenRadius2(event.target.value);
    }

    function handleTireWidthChange2(event) {
        setChosenWidth2(event.target.value);
    }

    function handleTireThicknessChange2(event) {
        setChosenThickness2(event.target.value);
    }

    function displayResult() {
        if (calcResult) {
            return (
                <>
                    <div className="tk-min-height-20 tk-height-20" />
                    <div className="compare-page-gray-line" />
                    <div className="tk-min-height-20 tk-height-20" />
                    <div className="tk-pad-l-20">
                        <label className="compare-page-rl-label">{getText('calcTitle3')}</label>
                        <div className="tk-grid tk-bgcolor-white compare-page-column-DimTire3">
                            <div ></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small">{getText('calcResColumn1')}</label></div>
                            <div></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small">{getText('calcResColumn2')}</label></div>
                            <div></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small">{getText('calcResColumn3')}</label></div>
                        </div>
                        <div className="tk-grid tk-bgcolor-white compare-page-column-DimTire3">
                            <div><label className="compare-page-rl-label compare-page-font-small">{getText('calcDimOut')}</label></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small2 frb-color-gray-87">{calcResult.dim1}</label></div>
                            <div></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small2 frb-color-gray-87">{calcResult.dim2}</label></div>
                            <div></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small2 frb-color-gray-87">{calcResult.percDiff}</label></div>
                        </div>
                        <div className="tk-pad-top-5"><label className="compare-page-rl-label compare-page-font-small">{getText('calcNote')}</label></div>
                    </div>
                    <div className="tk-min-height-20 tk-height-20" />
                    <div className="compare-page-gray-line" />
                    <div className="tk-min-height-20 tk-height-20" />
                    <div className="tk-pad-l-20">
                        <label className="compare-page-rl-label">{getText('calcTitle4')}</label>
                        <div className="tk-pad-top-5"><label className="compare-page-rl-label compare-page-font-small">{getText('calcSubTitle4')}</label></div>
                        <div className="tk-grid tk-bgcolor-white compare-page-dim-result-grid-16-columns">
                            <div><label className="compare-page-rl-label compare-page-font-small">{getText('calcOdo')}</label></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small">{getText('calc30')}</label></div>
                            <div></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small">{getText('calc50')}</label></div>
                            <div></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small">{getText('calc70')}</label></div>
                            <div></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small">{getText('calc90')}</label></div>
                            <div></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small">{getText('calc100')}</label></div>
                            <div></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small">{getText('calc110')}</label></div>
                            <div></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small">{getText('calc120')}</label></div>
                            <div></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small">{getText('calc130')}</label></div>
                        </div>
                        <div className="tk-grid tk-bgcolor-white compare-page-dim-result-grid-16-columns">
                            <div><label className="compare-page-rl-label compare-page-font-small">{getText('calcReel')}</label></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small2 frb-color-gray-87">{calcResult.speedDiff30}</label></div>
                            <div></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small2 frb-color-gray-87">{calcResult.speedDiff50}</label></div>
                            <div></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small2 frb-color-gray-87">{calcResult.speedDiff70}</label></div>
                            <div></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small2 frb-color-gray-87">{calcResult.speedDiff90}</label></div>
                            <div></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small2 frb-color-gray-87">{calcResult.speedDiff100}</label></div>
                            <div></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small2 frb-color-gray-87">{calcResult.speedDiff110}</label></div>
                            <div></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small2 frb-color-gray-87">{calcResult.speedDiff120}</label></div>
                            <div></div>
                            <div className="tk-text-center"><label className="compare-page-rl-label compare-page-font-small2 frb-color-gray-87">{calcResult.speedDiff130}</label></div>
                        </div>
                    </div>
                </>
            );
        }
    }

    if (props.texts) {
        var txtSection = loadSection(JSON_SECTION_CONVERTER, props.texts, props.securityInfo);

        if (tireRadius == null) {
            setTireRadius(createOptionsForComboBox('TIRE_RADIUS', setChosenRadius, setChosenRadius2));
            setTireThickness(createOptionsForComboBox('TIRE_THICKNESS', setChosenThickness, setChosenThickness2));
            setTireWidth(createOptionsForComboBox('TIRE_WIDTH', setChosenWidth, setChosenWidth2));
        }

        return (
            <>
                <div className="tk-pad-l-20">
                    <label className="compare-page-rl-label">{getText('calcTitle1')}</label>
                    <div className="tk-grid tk-bgcolor-white compare-page-column-DimTire">
                        <div className="tk-width-85 tk-float-left">
                            <label htmlFor="tireWidth" className="select-block">
                                <select name="tireWidth" id="tireWidth" value={chosenWidth} onChange={handleTireWidthChange} className="tk-height-30 tk-lh-30">
                                    {tireWidth}
                                </select>
                                <svg className="svg-arrow compare-page-forcetop face-down">
                                    <use xlinkHref="#svg-arrow"></use>
                                </svg>
                            </label>
                        </div>
                        <div className="tk-pad-top-5"><label className="compare-page-rl-label">{getText('slash')}</label></div>
                        <div className="tk-width-85 tk-float-left">
                            <label htmlFor="tireThickness" className="select-block">
                                <select name="tireThickness" id="tireThickness" value={chosenThickness} onChange={handleTireThicknessChange} className="tk-height-30 tk-lh-30">
                                    {tireThickness}
                                </select>
                                <svg className="svg-arrow compare-page-forcetop face-down">
                                    <use xlinkHref="#svg-arrow"></use>
                                </svg>
                            </label>
                        </div>
                        <div className="tk-pad-top-5"><label className="compare-page-rl-label">{getText('r')}</label></div>
                        <div className="tk-width-85 tk-float-left">
                            <label htmlFor="tireRadius" className="select-block">
                                <select name="tireRadius" id="tireRadius" value={chosenRadius} onChange={handleTireRadiusChange} className="tk-height-30 tk-lh-30">
                                    {tireRadius}
                                </select>
                                <svg className="svg-arrow compare-page-forcetop face-down">
                                    <use xlinkHref="#svg-arrow"></use>
                                </svg>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="tk-min-height-20 tk-height-20" />
                <div className="compare-page-gray-line" />
                <div className="tk-min-height-20 tk-height-20" />
                <div className="tk-pad-l-20">
                    <label className="compare-page-rl-label">{getText('calcTitle2')}</label>
                    <div className="tk-grid tk-bgcolor-white compare-page-column-DimTire2">
                        <div className="tk-width-85 tk-float-left">
                            <label htmlFor="tireWidth2" className="select-block">
                                <select name="tireWidth2" id="tireWidth2" value={chosenWidth2} onChange={handleTireWidthChange2} className="tk-height-30 tk-lh-30">
                                    {tireWidth}
                                </select>
                                <svg className="svg-arrow compare-page-forcetop face-down">
                                    <use xlinkHref="#svg-arrow"></use>
                                </svg>
                            </label>
                        </div>
                        <div className="tk-pad-top-5"><label className="compare-page-rl-label">{getText('slash')}</label></div>
                        <div className="tk-width-85 tk-float-left">
                            <label htmlFor="tireThickness2" className="select-block">
                                <select name="tireThickness2" id="tireThickness2" value={chosenThickness2} onChange={handleTireThicknessChange2} className="tk-height-30 tk-lh-30">
                                    {tireThickness}
                                </select>
                                <svg className="svg-arrow compare-page-forcetop face-down">
                                    <use xlinkHref="#svg-arrow"></use>
                                </svg>
                            </label>
                        </div>
                        <div className="tk-pad-top-5"><label className="compare-page-rl-label">{getText('r')}</label></div>
                        <div className="tk-width-85 tk-float-left">
                            <label htmlFor="tireRadius2" className="select-block">
                                <select name="tireRadius2" id="tireRadius2" value={chosenRadius2} onChange={handleTireRadiusChange2} className="tk-height-30 tk-lh-30">
                                    {tireRadius}
                                </select>
                                <svg className="svg-arrow compare-page-forcetop face-down">
                                    <use xlinkHref="#svg-arrow"></use>
                                </svg>
                            </label>
                        </div>
                        <div className="tk-pad-l-20" onClick={doCalculation}> <a className="button compare-page-calc-button tk-cursor-pointer">{getText('calcButton')}</a> </div>
                    </div>
                </div>

                <div id="spinnerComparer" className="tk-display-none">
                    <div className="tk-min-height-20 tk-height-20" />
                    <div className="compare-page-gray-line" />
                    <div className="tk-text-center tk-pad-top-20"><img width="30px" alt="" src={getText('loadingImageUrl')} /><br />
                        <label className="compare-page-rl-label">{getText('calcInProgress')}</label>
                    </div>
                </div>
                <div id="results">
                    {displayResult()}
                </div>

            </>
        );
    }
}
export default ComparePage;