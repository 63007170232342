import { useEffect, useState } from 'react';
import * as constants from '../../../../constants'
import * as backEndService from '../../../../services/backend/backEndService';
import * as validationService from '../../../../services/validationService';
import { getTextByKey, getGeneralTextByKey } from '../../../../services/languageService';

function UserProfile(props) {

  function getText(keyFields) {
    return getTextByKey(theTexts, keyFields, props.onTextNotFound)
  }

  function getGeneralText(keyFields) {
    return getGeneralTextByKey(theTexts, keyFields, props.onTextNotFound)
  }

  var theTexts = props.theTexts;

  useEffect(() => {
    handleUserLoaded(props.loggedUser);
  }, [props.loggedUser]);

  const [originalJsonUser, setOriginalJsonUser] = useState(props.loggedUser);

  const [userCode, setUserCode] = useState(constants.EMPTY);
  const [userName, setUserName] = useState(constants.EMPTY);
  const [email, setEmail] = useState(constants.EMPTY);
  const [password1, setPassword1] = useState(null);
  const [password2, setPassword2] = useState(null);
  const [password3, setPassword3] = useState(null);

  function save() {

    const allFields = new Map();

    if (!validationService.validateStringNotEmpty(userName)) {
      props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_USER_NAME_CANNOT_BE_EMPTY']));
      return;
    }

    if (validationService.validateStringNotEmpty(email)) {
      if (!validationService.validateEmail(email)) {
        props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_INVALID_EMAIL']));
        return;
      }
      allFields.set(constants.MAPPING_USER_EMAIL, email);
    }

    if (validationService.validateStringNotEmpty(password1)) {
      if (!validationService.validatePassword(password1, originalJsonUser.passe)) {
        props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_BAD_OLD_PASSWORD']));
        return;
      }


      if (!validationService.validateStringNotEmpty(password2)) {
        props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_NEW_PASSWORD_EMPTY']));
        return;
      }

      if (!validationService.validatePassword(password2, password3)) {
        props.displayErrorMessage(getGeneralText(['Messages', 'ERROR_NEW_PASSWORD_CONFIRMATION_NOT_SAME']));
        return;
      }

      allFields.set(constants.MAPPING_USER_PASSWORD, password2);
    }

    props.clearMessage();



    allFields.set(constants.MAPPING_USER_USER_NAME, userName);

    props.buttonMethod(allFields, originalJsonUser)
  }

  function handleUserLoaded(responseJsonObj) {
    if (responseJsonObj.userMssql) {
      var originalUser = responseJsonObj.userMssql;
      setOriginalJsonUser(responseJsonObj.userMssql)
      var mapData = backEndService.getFieldsMapFromJsonUser(originalUser);
      load(mapData);
    }
  }

  function load(allFields) {
    setUserCode(allFields.get(constants.MAPPING_USER_USER_CODE));
    setUserName(allFields.get(constants.MAPPING_USER_USER_NAME));
    setEmail(allFields.get(constants.MAPPING_USER_EMAIL));
  }

  function handleChangePassword1(event) {
    setPassword1(event.target.value);
  }

  function handleChangePassword2(event) {
    setPassword2(event.target.value);
  }

  function handleChangePassword3(event) {
    setPassword3(event.target.value);
  }

  return (
    <>
      <div className="section-wrap">
        <div className="section no-title">
          <div className="content right">
            <h2 className="subsection-title">{getText('accountTitle')}</h2>
            <hr className="line-separator" />
            <div className="form-row">
              <div className="half tk-mar-top-35">
                <label htmlFor="fname1" className="rl-label">{getText(['account', 0, 'name'])}</label>
                <input readOnly type="text" id="fname1" name="fname1" placeholder={getText(['account', 0, 'hint'])} value={props.noAccount} />
              </div>
            </div>
            <div className="form-row">
              <div className="half">
                <label htmlFor="fname2" className="rl-label">{getText(['account', 1, 'name'])}</label>
                <input readOnly type="text" id="fname2" name="fname2" placeholder={getText(['account', 1, 'hint'])} value={userCode} />
              </div>
            </div>
            <div className="form-row">
              <div className="half">
                <label htmlFor="fname3" className="rl-label">{getText(['account', 2, 'name'])}</label>
                <input type="text" id="fname3" name="fname3" placeholder={getText(['account', 2, 'hint'])} onChange={(e) => setUserName(e.target.value)} value={userName} />
              </div>
            </div>
            <div className="form-row">
              <div className="half">
                <label htmlFor="fname4" className="rl-label">{getText(['account', 3, 'name'])}</label>
                <input type="text" id="fname4" name="fname4" placeholder={getText(['account', 3, 'hint'])} onChange={(e) => setEmail(e.target.value)} value={email} />
              </div>
            </div>
            <br /><br />
            <h2 className="subsection-title">{getText('passwordTitle')}</h2>
            <hr className="line-separator" />
            <div className="form-row tk-mar-top-35">
              <div className="half">
                <label htmlFor="fname5" className="rl-label required">{getText(['password', 0, 'name'])}</label>
                <input type="password" id="fname5" name="fname5" onChange={handleChangePassword1} placeholder={getText(['password', 0, 'hint'])} />
              </div>
            </div>
            <div className="form-row">
              <div className="half">
                <label htmlFor="fname6" className="rl-label required">{getText(['password', 1, 'name'])}</label>
                <input type="password" id="fname6" name="fname6" onChange={handleChangePassword2} placeholder={getText(['password', 1, 'hint'])} />
              </div>
            </div>
            <div className="form-row">
              <div className="half">
                <label htmlFor="fname7" className="rl-label required">{getText(['password', 2, 'name'])}</label>
                <input type="password" id="fname7" name="fname7" onChange={handleChangePassword3} placeholder={getText(['password', 2, 'hint'])} />
              </div>
            </div>
            <div className="form-row right">
              <button className="button" onClick={save}>{getText('saveButton')}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserProfile;