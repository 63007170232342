import { JSON_SECTION_S6_FOUR_FEATURES, loadSection, getTextByKey } from '../../../services/languageService';
import './S6FourFeatures.css';

function S6FourFeatures(props) {
  function getText(keyFields) {
    return getTextByKey(theTexts, keyFields, props.onTextNotFound)
  }

  if (props.texts) {
    var theTexts = loadSection(JSON_SECTION_S6_FOUR_FEATURES, props.texts, props.securityInfo);

    return (
      <div className="section-wrap">
        <div className="features-section section">
          <h6 className="pretitle">{getText('pretitle')}</h6>
          <img className="s6-four-features-brand-image" src={getText('titleImg')} alt="start01" />
          <h3 className="title large">&nbsp;</h3>
          <hr className="line-separator" />
          <img src={getText('image')} alt="features" className="bg-decoration s6-four-features-max-size-image" />

          <ul className="feature-list small">
            <li className="feature-list-item">
              <svg className="svg-check medium">
                <use xlinkHref="#svg-check"></use>
              </svg>
              <p className="title">{getText(['list', 0, 'title'])}</p>
              <p>{getText(['list', 0, 'desc'])}</p>
            </li>

            <li className="feature-list-item">
              <svg className="svg-check medium">
                <use xlinkHref="#svg-check"></use>
              </svg>
              <p className="title">{getText(['list', 1, 'title'])}</p>
              <p>{getText(['list', 1, 'desc'])}</p>
            </li>

            <li className="feature-list-item">
              <svg className="svg-check medium">
                <use xlinkHref="#svg-check"></use>
              </svg>
              <p className="title">{getText(['list', 2, 'title'])}</p>
              <p>{getText(['list', 2, 'desc'])}</p>
            </li>

            <li className="feature-list-item">
              <svg className="svg-check medium">
                <use xlinkHref="#svg-check"></use>
              </svg>
              <p className="title">{getText(['list', 3, 'title'])}</p>
              <p>{getText(['list', 3, 'desc'])}</p>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default S6FourFeatures;
