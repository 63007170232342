import { JSON_SECTION_S10_FEATURE_PRODUCTS, loadSection } from '../../../services/languageService';
import './S10FeatureProducts.css';
function S10FeatureProducts(props) {

  function callLoginRedirect(url){
    props.loginRedirect(url);
  }

  function createStar(key,filled) {
    if (filled) {
      return (
        <li key={key} className="rating-item filled">
          <svg className="svg-star">
            <use xlinkHref="#svg-star"></use>
          </svg>
        </li>
      );
    } else {
      return (
        <li key={key} className="rating-item">
          <svg className="svg-star">
            <use xlinkHref="#svg-star"></use>
          </svg>
        </li>
      );
    }
  }

  function createStars(key, nbrStars) {

    var toReturn = [];
    for (let i = 0; i < 5; i++) {
      toReturn.push(createStar((key+'_'+i), (i<nbrStars)));
    }
    return toReturn;
  }

  function createColumn(key, product) {

    var stars = createStars(key, product.stars);
    return (
      <div key={key} className="product-list-item column">
        <div className="product-list-item-preview test">
          <figure className="product-img">
            <img src={product.tireImage} alt="product01" />
          </figure>

          <div className="product-list-item-actions">
            <div className="button-list tk-text-center">
              <i onClick={() => callLoginRedirect(product.href)} className="S10-feature-products-button tk-cursor-pointer">
              {product.button}
              </i>
            </div>
          </div>
        </div>
        <div className="product-list-item-info">
          <h6 className="title">{product.title}</h6><br /><br />
          <img className="frbCssS3ThreeProductsBar_BrandImage" src={product.brandImage} alt="start01" />
          <div className="product-list-item-price S10-feature-products-star">
            <div className="S10-feature-products-star">
            <ul className="rating S10-feature-products-ul">
              {stars}
            </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (props.texts) {
    var theTexts = loadSection(JSON_SECTION_S10_FEATURE_PRODUCTS, props.texts, props.securityInfo);

    var theList = [];
    for (let i = 0; i < theTexts.products.length; i++) {
      theList.push(createColumn(i, theTexts.products[i]));
    }

    return (
      <div className="section-wrap">
        <div className="section bottom-short">
          <h6 className="pretitle">{theTexts.title}</h6>
          <h3 className="title large">{theTexts.subTitle}</h3>
          <hr className="line-separator" />

          <div className="product-list column-4-wrap">
            {theList}
          </div>
        </div>
      </div>
    );
  }
}
export default S10FeatureProducts;
