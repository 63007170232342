import { JSON_SECTION_S13_COPYRIGHT, loadSection, getTextByKey, getGeneralTextByKey } from '../../../services/languageService';

function S13CopyRight(props) {
  function getText(keyFields) {
    return getTextByKey(theTexts, keyFields, props.onTextNotFound)
  }

  function getGeneralText(keyFields) {
    return getGeneralTextByKey(theTexts, keyFields, props.onTextNotFound)
  }

  if (props.texts) {
    var theTexts = loadSection(JSON_SECTION_S13_COPYRIGHT, props.texts, props.securityInfo);

    return (
      <div className="widget-line-wrap dark widget-line-copy">
        <div className="widget-line big">
          <p className="copyright"><span className="bold">{getText(['line1'])}</span> {getText(['line2'])}</p>
          <p className="copyright tk-display-none"><span className="bold">{getText(['lineSmall1'])}</span> {getText(['lineSmall2'])}</p>
          <ul className="actions-list right">
            <li className="actions-list-item">
              <div className="social-link">
                <a target="_blank" rel="noreferrer" href={getGeneralText('Linkedin')}>
                  <i className="fa fa-linkedin"></i>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default S13CopyRight;
