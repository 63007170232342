function TabWheel(props) {

    return (
        <>
        Tab Wheel (Phase 2)
        </>            
    );

}

export default TabWheel;