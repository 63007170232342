export default class ApiCache {

    static defaultInstance = null;

    resultMap = new Map();

    /**
    * @returns {ApiCache}
    */
    static getInstance() {
        if (ApiCache.defaultInstance == null) {
            ApiCache.defaultInstance = new ApiCache();
        }

        return this.defaultInstance;
    }

    setInCache(key, value) {
        this.resultMap.set(key, value);
    }

    getFromCache(key) {
        return this.resultMap.get(key);
    }

    isInCache(key) {
        return (this.resultMap.get(key) !== null && this.resultMap.get(key) !== undefined);
    }
}