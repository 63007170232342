import './Partners.css'
import Partner from './Partner';
import * as constants from '../../../../../../constants'
import { JSON_SECTION_TIRE_SEARCH_PAGE, loadSection, getTextByKey } from '../../../../../../services/languageService';

function Partners(props) {

    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, props.onTextNotFound)
      }

    var partnerList = [];

    function generatePartnerLineUI(jsonPartner, index) {
        var theKey = 'Partner_' + index;
        return (
            <Partner key={theKey} id={jsonPartner.brand} img={constants.BRAND_IMAGE_DIR_URL + jsonPartner.imgPath} resetAsked={props.resetAsked} addPartner={props.addPartner} removePartner={props.removePartner} />
        );
    }

    if (props.partnerList) {
        var theList = [];
        for (let i = 0; i < props.partnerList.length; i++) {
            var current = props.partnerList[i];
            theList.push(generatePartnerLineUI(current, i));
        }

        partnerList = theList;
    }

    if (props.texts) {
        var txtSection = loadSection(JSON_SECTION_TIRE_SEARCH_PAGE, props.texts, props.securityInfo);

        return (
            <>
                <div className="partners-div">
                    <div className='tk-grid tk-bgcolor-white partners-grid-1-column'>
                        <div className='partners-line'>
                        {getText('partnerTitleLine1')} <br />{getText('partnerTitleLine2')}
                        </div>
                        <div className='partners-line'>
                            {partnerList}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Partners;