import { JSON_SECTION_S7_THREE_COLOR_BOX, loadSection, getTextByKey } from '../../../services/languageService';

function S7ThreeColorBox(props) {
  function getText(keyFields) {
    return getTextByKey(theTexts, keyFields, props.onTextNotFound)
  }

  if (props.texts) {
    var theTexts = loadSection(JSON_SECTION_S7_THREE_COLOR_BOX, props.texts, props.securityInfo);

    return (
      <div className="service-banners">
        <div className="service-banner main">
          <div className="service-banner-content">
            <i className={getText('sec1_css')}></i>
            <h5 className="title medium">{getText('sec1_line1')}</h5>
            <h3 className="title big">{getText('sec1_line2')}</h3>
            <hr className="line-separator big" />
            <p>{getText('sec1_desc')}</p>
          </div>
        </div>

        <div className="service-banner secondary">
          <div className="service-banner-content">
            <i className={getText('sec2_css')}></i>
            <h5 className="title medium">{getText('sec2_line1')}</h5>
            <h3 className="title big">{getText('sec2_line2')}</h3>
            <hr className="line-separator big" />
            <p>{getText('sec2_desc')}</p>
          </div>
        </div>

        <div className="service-banner tertiary">
          <div className="service-banner-content">
            <i className={getText('sec3_css')}></i>
            <h5 className="title medium">{getText('sec3_line1')}</h5>
            <h3 className="title big">{getText('sec3_line2')}</h3>
            <hr className="line-separator big" />
            <p>{getText('sec3_desc')}</p>
          </div>
        </div>
      </div>
    );
  }
}
export default S7ThreeColorBox;
