import './ComplexSubMenuItem.css';

function ComplexSubMenuItem(props) {

  function internGoToRoute(url) {
    props.goToRoute(url);
  }

  function createElement(id, element) {
    if (element.javascript) {
      return (
        <li key={id} className="link-list-item">
          <div className='tk-cursor-pointer' onClick={() => window[element.javascript]()}>{element.name}</div>
        </li>
      );
    } else if (element.route) {
      return (
        <li key={id} className="link-list-item">
          <div className='complex-sub-menu-item-font tk-cursor-pointer' onClick={() => internGoToRoute(element.route)}>{element.name}</div>
        </li>
      );

    } else {
      return (
        <li key={id} className="link-list-item">
          <a href={element.href}>{element.name}</a>
        </li>
      );
    }
  }

  function createColumns(id, menu) {
    var list = [];

    for (let i = 0; i < menu.menus.length; i++) {
      list.push(createElement(i, menu.menus[i]));
    }

    return (
      <div key={id} className="content-dropdown-column">
        <h6 className="title tiny secondary">{menu.title}</h6>
        <hr className="line-separator" />
        <ul className="link-list simple">
          {list}
        </ul>
      </div>
    );
  }


  var columns = [];

  for (let i = 0; i < props.menus.length; i++) {
    columns.push(createColumns(i, props.menus[i]));
  }

  return (
    <div className="content-dropdown">
      {columns}
    </div>

  );

}

export default ComplexSubMenuItem;