const DEFAULT_OLD_SITE_URL = 'https://dev.pneusfrb.ca/';
const DEFAULT_TIRE_IMAGE_DIR = '/tireImages/';

const { REACT_APP_OLD_SITE_URL } = process.env;
const { REACT_APP_TIRE_BASE_DIR_URL } = process.env;

var oldSiteUrl = REACT_APP_OLD_SITE_URL

if (oldSiteUrl == null) {
  console.log("Cannot found ENV property: REACT_APP_OLD_SITE_URL therefore the application is using: " + DEFAULT_OLD_SITE_URL);
  oldSiteUrl = DEFAULT_OLD_SITE_URL;
}

var tireImageLocation = REACT_APP_TIRE_BASE_DIR_URL

if (tireImageLocation == null) {
  console.log("Cannot found ENV property: REACT_APP_TIRE_BASE_DIR_URL therefore the application is using: " + DEFAULT_TIRE_IMAGE_DIR);
  tireImageLocation = DEFAULT_TIRE_IMAGE_DIR;
}

export const OLD_SITE_URL = oldSiteUrl;
export const TIRE_IMAGE_DIR_URL = tireImageLocation + 'img/pneus/';
export const BRAND_IMAGE_DIR_URL = tireImageLocation + 'img/brands/';
export const PDF_DIR_URL = tireImageLocation + 'promotions/';

export const OLD_SITE_URL_COMMANDES = OLD_SITE_URL + 'historique/commandes/';
export const OLD_SITE_URL_FACTURES = OLD_SITE_URL + 'historique/factures/';
export const OLD_SITE_URL_COMPTE = OLD_SITE_URL + 'historique/compte/';
export const OLD_SITE_URL_ESTIME = OLD_SITE_URL + 'quotes/';
export const OLD_SITE_URL_PARAMETERS = OLD_SITE_URL + 'parametres/pneus';
export const OLD_SITE_URL_PROMOTIONS = OLD_SITE_URL + 'promotions';

export const OLD_SITE_URL_REMISAGE_NEW = OLD_SITE_URL + 'remisage/nouveau/';
export const OLD_SITE_URL_REMISAGE_LIST = OLD_SITE_URL + 'remisage';
export const OLD_SITE_URL_REMISAGE_PRINT = OLD_SITE_URL + 'remisage/imprimer';
export const OLD_SITE_URL_REMISAGE_REPORTS = OLD_SITE_URL + 'remisage/rapports';
export const OLD_SITE_URL_REMISAGE_ON_SITE_NEW = OLD_SITE_URL + 'remisageMaison/nouveau/';
export const OLD_SITE_URL_REMISAGE_ON_SITE_LIST = OLD_SITE_URL + 'remisageMaison';
export const OLD_SITE_URL_REMISAGE_ON_SITE_PRINT = OLD_SITE_URL + 'remisage/imprimer';
export const OLD_SITE_URL_REMISAGE_ON_SITE_REPORTS = OLD_SITE_URL + 'remisage/rapports';

export const REST_URL_LOGIN = 'api/auth/login';
export const REST_URL_NEWSLETTER_ADD_EMAIL = 'newsletter/add';
export const REST_URL_CONTACT_MSG = 'contact/msg';
export const REST_URL_USER_ADD = 'user/add';
export const REST_URL_USER_UPDATE = 'user/update';
export const REST_URL_USER_DELETE = 'user/delete?userToDelete=';
export const REST_URL_USER_GETALL = 'user/getAllUsersAssociatedToMe';
export const REST_URL_USER_GET = 'user/get?trusager=';
export const REST_URL_USER_PROFILE = 'user/getMyself';
export const REST_URL_USER_PARTNERS = 'user/partnerships';
export const REST_URL_DEFAULT_SEASON = 'misc/isWinter';


export const REST_URL_TIRE_EQUIVALENCE_GET = 'tires/equivalence';
export const REST_URL_TIRE_COMPARISON_GET = 'tires/comparison?originalTire={0}&tireCompared={1}';
export const REST_URL_TIRE_ALL_BRANDS = 'tires/brands';
export const REST_URL_TIRE_SEARCH = 'tires/search';

export const REST_URL_CAR_YEAR = 'cars/years';
export const REST_URL_CAR_BRAND = 'cars/makers?year=';
export const REST_URL_CAR_MODEL = 'cars/models?maker={0}&year={1}';
export const REST_URL_CAR_OPTION = 'cars/options?model={0}&maker={1}&year={2}';
export const REST_URL_CAR_DIMENSION = 'cars/dimensions?option={0}&model={1}&maker={2}&year={3}';

export const USER_TYPE_USER = 0;
export const USER_TYPE_ADMIN = 1;
export const USER_TYPE_COMPT = 2;

export const TAB_TIRE_SEARCH_SPEC = '0';
export const TAB_TIRE_SEARCH_VEH = '1';
export const TAB_TIRE_SEARCH_WHEEL = '2';

export const TIRE_SEARCH_DISPLAY_MINIMAL = 'minimal';


export const ROUTE_HOME = "/";
export const ROUTE_JOINUS = "/JoinUs";
export const ROUTE_TIRES = "/Tires";
export const ROUTE_CONVERTER = "/Converter";

export const ROUTE_TIRES_RESULT = "/TireResults";
export const ROUTE_FATAL = "/Fatal";
export const ROUTE_LOGIN = "/Login";
export const ROUTE_LOGOUT = "/Logout";
export const ROUTE_LOGOUT_LOGIN = "/ReLogin";
export const ROUTE_PROFILE = "/Profile";
export const ROUTE_MANAGEUSERS = "/ManageUsers";
export const ROUTE_ADDUSER = "/AddUser";
export const ROUTE_EDITUSER = "/EditUser";
export const SYNTAX_ROUTE_EDITUSER = "/EditUser/:id";
export const SYNTAX_ROUTE_OLDSITE_COMMANDES = "/Commandes";
export const SYNTAX_ROUTE_OLDSITE_BILLS = "/Factures";
export const SYNTAX_ROUTE_OLDSITE_COMPTE = "/Compte";
export const SYNTAX_ROUTE_OLDSITE_ESTIME = "/Estime";
export const SYNTAX_ROUTE_OLDSITE_PARAMETERS = "/Parameters";
export const SYNTAX_ROUTE_OLDSITE_PROMOTIONS = "/Promotions";

export const SYNTAX_ROUTE_OLDSITE_REMISAGE_NEW = "/RemisageNouveau";
export const SYNTAX_ROUTE_OLDSITE_REMISAGE_LIST = "/RemisageListe";
export const SYNTAX_ROUTE_OLDSITE_REMISAGE_ETIQUETTES = "/RemisageEtiquettes";
export const SYNTAX_ROUTE_OLDSITE_REMISAGE_RAPPORTS = "/RemisageRapports";
export const SYNTAX_ROUTE_OLDSITE_REMISAGE_ONSITE_NEW = "/RemisageOnSiteNouveau";
export const SYNTAX_ROUTE_OLDSITE_REMISAGE_ONSITE_LIST = "/RemisageOnSiteListe";
export const SYNTAX_ROUTE_OLDSITE_REMISAGE_ONSITE_ETIQUETTES = "/RemisageOnSiteEtiquettes";
export const SYNTAX_ROUTE_OLDSITE_REMISAGE_ONSITE_RAPPORTS = "/RemisageOnSiteRapports";

export const ROUTE_TODO = "/TODO";
export const ROUTE_TEMPO = "/Tempo";

export const MAPPING_USER_ACCOUNT_NUMBER = "USER_ACCOUNT_NUMBER";
export const MAPPING_USER_USER_CODE = "USER_USER_CODE";
export const MAPPING_USER_USER_NAME = "USER_USER_NAME";
export const MAPPING_USER_EMAIL = "USER_EMAIL";
export const MAPPING_USER_PASSWORD = "USER_PASSWORD";
export const MAPPING_USER_AUTH_CANSEECOST = "USER_AUTH_CANSEECOST";
export const MAPPING_USER_AUTH_CANNOTPASSORDER = "USER_AUTH_CANNOTPASSORDER";
export const MAPPING_USER_AUTH_STORAGE_ONLY = "USER_AUTH_STORAGE_ONLY";
export const MAPPING_USER_AUTH_TIRE_ONLY = "USER_AUTH_TIRE_ONLY";
export const MAPPING_USER_USERTYPE = "USER_USERTYPE";
export const MAPPING_USER_CR0107 = "USER_CR0107";

export const TIRESEARCH_TYPE_SPEC = "SPEC";
export const TIRESEARCH_TYPE_VEH = "VEH";

export const MAPPING_TIRESEARCH_SEARCHTYPE = "MAPPING_TIRESEARCH_SEARCHTYPE";
export const MAPPING_TIRESEARCH_SIZE_FRONT = "MAPPING_TIRESEARCH_SIZE_FRONT";
export const MAPPING_TIRESEARCH_SIZE_BACK = "MAPPING_TIRESEARCH_SIZE_BACK";
export const MAPPING_TIRESEARCH_PRODUCT_CODE = "MAPPING_TIRESEARCH_PRODUCT_CODE";
export const MAPPING_TIRESEARCH_BRAND = "MAPPING_TIRESEARCH_BRAND";
export const MAPPING_TIRESEARCH_DESCR = "MAPPING_TIRESEARCH_DESCR";
export const MAPPING_TIRESEARCH_TYPE = "MAPPING_TIRESEARCH_TYPE";
export const MAPPING_TIRESEARCH_DISCOUNT_ONLY = "MAPPING_TIRESEARCH_DISCOUNT_ONLY";
export const MAPPING_TIRESEARCH_PARTNERS = "MAPPING_TIRESEARCH_PARTNERS";

export const MAPPING_TIRESEARCH_VEH_YEAR = "MAPPING_TIRESEARCH_VEH_YEAR";
export const MAPPING_TIRESEARCH_VEH_BRAND = "MAPPING_TIRESEARCH_VEH_BRAND";
export const MAPPING_TIRESEARCH_VEH_MODEL = "MAPPING_TIRESEARCH_VEH_MODEL";
export const MAPPING_TIRESEARCH_VEH_OPTION_GROUP = "MAPPING_TIRESEARCH_VEH_OPTION_GROUP";

export const MAPPING_CONVERTER_DIM_TIRE_WIDTH = "MAPPING_CONVERTER_DIM_TIRE_WIDTH";
export const MAPPING_CONVERTER_DIM_TIRE_THINKNESS = "MAPPING_CONVERTER_DIM_TIRE_THINKNESS";
export const MAPPING_CONVERTER_DIM_TIRE_RADIUS = "MAPPING_CONVERTER_DIM_TIRE_RADIUS";

export const MAPPING_CONVERTER_COMP_TIRE_WIDTH = "MAPPING_CONVERTER_COMP_TIRE_WIDTH";
export const MAPPING_CONVERTER_COMP_TIRE_THINKNESS = "MAPPING_CONVERTER_COMP_TIRE_THINKNESS";
export const MAPPING_CONVERTER_COMP_TIRE_RADIUS = "MAPPING_CONVERTER_COMP_TIRE_RADIUS";
export const MAPPING_CONVERTER_COMP_TIRE_WIDTH2 = "MAPPING_CONVERTER_COMP_TIRE_WIDTH2";
export const MAPPING_CONVERTER_COMP_TIRE_THINKNESS2 = "MAPPING_CONVERTER_COMP_TIRE_THINKNESS2";
export const MAPPING_CONVERTER_COMP_TIRE_RADIUS2 = "MAPPING_CONVERTER_COMP_TIRE_RADIUS2";

export const LIST_TIRESEARCH_VEH_YEAR = "LIST_TIRESEARCH_VEH_YEAR";
export const LIST_TIRESEARCH_VEH_BRAND = "LIST_TIRESEARCH_VEH_BRAND";
export const LIST_TIRESEARCH_VEH_MODEL = "LIST_TIRESEARCH_VEH_MODEL";
export const LIST_TIRESEARCH_VEH_OPTION_GROUP = "LIST_TIRESEARCH_VEH_OPTION_GROUP";
export const LIST_TIRESEARCH_VEH_SIZE = "LIST_TIRESEARCH_VEH_SIZE";


export const SLASH = '/';
export const EMPTY = '';
export const SPACE = ' ';
export const UNDERSCORE = '_';
export const PIXEL = 'px';
export const STRING_MINUS_ONE = '-1';
export const STRING_NOT_AVAILABLE = 'N/A';
export const STRING_TWO_DASH = '--';
export const DOLLAR = '$';
export const DOLLAR_DOT_ZERO_CENT = '.00$';


export const TIRE_SEARCH_SORT_NO_SORT = '-1';
export const TIRE_SEARCH_SORT_DISPTOTAL_AND_PDSF_ASC = '1';
export const TIRE_SEARCH_SORT_DISPTOTAL_AND_PDSF_DESC = '2';
export const TIRE_SEARCH_SORT_DISPTOTAL_AND_P_ASC = '3';
export const TIRE_SEARCH_SORT_DISPTOTAL_AND_P_DESC = '4';
export const TIRE_SEARCH_SORT_PDSF_ASC = '5';
export const TIRE_SEARCH_SORT_PDSF_DESC = '6';
export const TIRE_SEARCH_SORT_P_ASC = '7';
export const TIRE_SEARCH_SORT_P_DESC = '8';

export const ROLE_LOGGED = 'logged';
export const ROLE_ADMIN = 'admin';
export const ROLE_COMPT = 'compt';

export const TIRE_SEARCH_RESULT_ITEM_PER_PAGE = 10;

export const TIRE_TYPE_SUMMER = '0';
export const TIRE_TYPE_WINTER = '1';
export const TIRE_TYPE_HOMOLOGUED = '2';
export const TIRE_TYPE_4SEASONS = '3';

export const FILTER_TYPE_SLIDER = 'slider';
export const FILTER_TYPE_CHECKBOX = 'checkbox';