import './TireResultPage.css';
import TireSearch from "../../../Pages/Tires/TireSearchPage/TireSearch";
import TireResultSort from './TireResultSort/TireResultSort';
import TireResultFilters from './TireResultFilters/TireResultFilters';
import TireResults from './TireResults/TireResults';
import * as constants from '../../../../constants';
import { JSON_SECTION_SEARCH_RESULT, loadSection, getTextByKey } from '../../../../services/languageService';
import { useState } from 'react';

function TireResultPage(props) {
    const [dollarClicked, setDollarClicked] = useState(false);
    const [eyeClicked, setEyeClicked] = useState(false);

    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    function onButtonClickDollars(){
        setDollarClicked(!dollarClicked);
    }

    function onButtonClickEye(){
        setEyeClicked(!eyeClicked)
    }

    if (props.texts) {
        var txtSection = loadSection(JSON_SECTION_SEARCH_RESULT, props.texts, props.securityInfo);

        var hasResult = false;
        if (props.jsonData && props.jsonData.results && props.jsonData.results.length > 0) {
            hasResult = true;
        }

        var components = constants.EMPTY;

        if (hasResult) {
            components = (
                <div className='section no-title tire-result-page-no-padding-top'>
                    <div className="tk-grid tk-bgcolor-white tire-result-page-grid-3-colums">
                        <div></div>
                        <div></div>
                        <div><TireResultSort sortMethod={props.sortMethod} onSortSelection={props.onSortSelection} onButtonClickEye={onButtonClickEye} onButtonClickDollars={onButtonClickDollars} sort={-1} showDollar={dollarClicked} showEye={eyeClicked} jsonData={props.jsonData} onTextNotFound={props.onTextNotFound} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} securityInfo={props.securityInfo} /></div>
                        <div>
                            <div id='TireResultFiltersMainDiv'>
                                <TireResultFilters tireSearchTime={props.tireSearchTime} tireSearchFilterApplied={props.tireSearchFilterApplied} jsonData={props.jsonData} onTextNotFound={props.onTextNotFound} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} securityInfo={props.securityInfo} />
                            </div>
                        </div>
                        <div></div>
                        <div>
                            <TireResults maskPrice={dollarClicked} hidePrice={eyeClicked} changePageToDisplay={props.changePageToDisplay} jsonData={props.jsonData} onTextNotFound={props.onTextNotFound} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} securityInfo={props.securityInfo} />
                        </div>
                    </div>
                </div>
            );
        } else {
            components = (<div className='tire-result-page-no-result'>{getText('noResult')}</div>);
        }

        return (
            <>
                <TireSearch moveToTop={props.moveToTop} tireSearchOptions={props.tireSearchOptions} tireSearchFunction={props.tireSearchFunction} goToRoute={props.goToRoute} key='Tires' displayType={constants.TIRE_SEARCH_DISPLAY_MINIMAL} selectedType={props.selectedType} onTextNotFound={props.onTextNotFound} displaySuccessMessage={props.displaySuccessMessage} displayErrorMessage={props.displayErrorMessage} texts={props.texts} securityInfo={props.securityInfo} />
                <div id="spinner" className="tk-display-none">
                    <div className="tk-min-height-20 tk-height-20" />
                    <div className="tk-text-center tk-pad-top-50  tk-pad-bot-50"><img width="30px" alt="" src={getText('loadingImageUrl')} /><br />
                        <label className="rl-label">{getText('searchInProgress')}</label>
                    </div>
                </div>
                <div id='results'>
                    {components}
                </div>

            </>
        );
    }
}

export default TireResultPage;