import { JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_RESULT_POPUP, loadSubSection, getTextByKey } from '../../../../../services/languageService';
import './ResultPopupDiscount.css';

function ResultPopupDiscount(props) {

    var theTexts = props.texts;

    function getText(keyFields) {
        return getTextByKey(txtSection, keyFields, props.onTextNotFound)
    }

    if (theTexts) {
        var txtSection = loadSubSection(JSON_SECTION_SEARCH_RESULT, JSON_SUB_SECTION_RESULT_POPUP, theTexts, props.securityInfo);
        return (
            <>
                <div className="tire-result-discount-popup">
                    <div className="tire-result-discount-popup-box frb-bgcolor-orange frb-font-medium-large frb-color-white frb-font-light-bold tk-display-block">
                        <div className='tire-result-discount-popup-content'>
                            <div className='tire-result-discount-popup-content-date'>
                                {getText('from')} {props.jsonData.pDiscountStartDate} {getText('to')} {props.jsonData.pDiscountEndDate}
                            </div>
                            <div className='tk-div-align-content-v-center'>
                                <img width='30px' src={getText('pdfImageUrl')} alt="" /><a className='tire-result-discount-popup-content-download frb-color-orange' target="_blank" rel="noopener noreferrer" href={props.jsonData.pDiscountPdf}>{getText('downloadTxt')}</a>
                            </div>
                        </div>
                        <div className="tire-result-discount-popup-triangle" />
                    </div>
                </div>
            </>
        );
    }
}

export default ResultPopupDiscount;