import * as constants from '../constants';
import * as validationService from './validationService';

export function stringParameters(str, arr) {
    if (typeof str !== 'string' || !(arr instanceof Array)) {
        return constants.EMPTY;
    }

    return str.replace(/({\d})/g, function (i) {
        return arr[i.replace(/{/, '').replace(/}/, '')];
    });
}

export function toDollars(thePrice) {
    var toReturn = constants.STRING_TWO_DASH;
    if (validationService.isNumber(thePrice)) {
        toReturn = thePrice.toFixed(2) + constants.DOLLAR;
    }

    if (toReturn.endsWith(constants.DOLLAR_DOT_ZERO_CENT)) {
        return toReturn.substring(0, toReturn.length - 4) + constants.DOLLAR;
    }

    return toReturn;
}

export function numberToStringWith2Decimals(theNumber) {
    var toReturn = constants.STRING_NOT_AVAILABLE;
    if (validationService.isNumber(theNumber)) {
        toReturn = numberToNumberWith2Decimals(theNumber);

        if (isNaN(toReturn)) {
            toReturn = constants.STRING_NOT_AVAILABLE;
        } else {
            toReturn = toReturn.toFixed(2);
        }
    }
    return toReturn;
}

export function numberToNumberWith2Decimals(theNumber) {
    var toReturn = NaN;
    if (validationService.isNumber(theNumber)) {
        var isNegative = false;
        if (theNumber < 0) {
            isNegative = true;
        }
        toReturn = Math.round((Math.abs(theNumber) + Number.EPSILON) * 100) / 100;

        if (isNegative) {
            toReturn = 0 - toReturn;
        }
    }
    return toReturn;
}

export function getLast2LettersUppercase(word) {
    var toReturn = 'XX';
    if (validationService.isString(word)) {
        word = word.trim();
        if (word.length >= 2) {
            toReturn = word.substring(word.length - 2, word.length).toUpperCase();
        }
    }
    return toReturn;;
}

export function getFirst2LettersUppercase(word) {
    var toReturn = 'XX';
    if (validationService.isString(word)) {
        word = word.trim();
        if (word.length >= 2) {
            toReturn = word.substring(0, 2).toUpperCase();
        }
    }

    return toReturn;
}