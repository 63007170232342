import { JSON_SECTION_S3_THREE_PRODUCTS_BAR, loadSection, getTextByKey } from '../../../services/languageService';
import './S3ThreeProductsBar.css';

function S3ThreeProductsBar(props) {

  function getText(keyFields) {
    return getTextByKey(theTexts, keyFields, props.onTextNotFound)
  }

  function callLoginRedirect(url) {
    props.loginRedirect(url);
  }

  if (props.texts) {
    var theTexts = loadSection(JSON_SECTION_S3_THREE_PRODUCTS_BAR, props.texts, props.securityInfo);

    return (
      <div className="builder-actions column-full-3-wrap">
        <div className="builder-actions-item-wrap column">
          <div className="builder-actions-item">
            <img className="S3-three-products-bar-tireImage" src={getText(['list', 0, 'tireImage'])} alt="start01" />
            <h6 className="title">{getText(['list', 0, 'title'])}</h6>
            <img className="S3-three-products-bar-brandImage" src={getText(['list', 0, 'brandImage'])} alt="start01" />
            <span onClick={() => callLoginRedirect(getText(['list', 0, 'href']))} className="button small tk-cursor-pointer frb-font-bold frb-font-lato">{getText(['list', 0, 'button'])}</span>
          </div>
        </div>

        <div className="builder-actions-item-wrap column">
          <div className="builder-actions-item">
            <img className="S3-three-products-bar-tireImage" src={getText(['list', 1, 'tireImage'])} alt="start02" />
            <h6 className="title">{getText(['list', 1, 'title'])}</h6>
            <img className="S3-three-products-bar-brandImage" src={getText(['list', 1, 'brandImage'])} alt="start01" />
            <span onClick={() => callLoginRedirect(getText(['list', 1, 'href']))} className="button small tk-cursor-pointer frb-font-bold frb-font-lato">{getText(['list', 1, 'button'])}</span>
          </div>
        </div>

        <div className="builder-actions-item-wrap column">
          <div className="builder-actions-item">
            <img className="S3-three-products-bar-tireImage" src={getText(['list', 2, 'tireImage'])} alt="start03" />
            <h6 className="title">{getText(['list', 2, 'title'])}</h6>
            <img className="S3-three-products-bar-brandImage" src={getText(['list', 2, 'brandImage'])} alt="start01" />
            <span onClick={() => callLoginRedirect(getText(['list', 2, 'href']))} className="button small tk-cursor-pointer frb-font-bold frb-font-lato">{getText(['list', 2, 'button'])}</span>
          </div>
        </div>
      </div>
    );
  }
}
export default S3ThreeProductsBar;
