import * as constants from '../../constants';
import * as restService from './restService';
import * as stringService from '../stringService';
import * as validationService from '../validationService';
import BackendUser from '../../object/BackendObject';
import ApiCache from '../../object/ApiCache';

const DEFAULT_TEXT_JSON_URL = '/frb/languages/language_fr.json';
const DEFAULT_BACKEND_URL = 'http://localhost:8080/';

const { REACT_APP_API_URL } = process.env;
const { REACT_APP_TEXT_JSON_URL } = process.env;

var backEndURL = REACT_APP_API_URL

if (backEndURL == null) {
  console.log("Cannot found ENV property: REACT_APP_API_URL therefore the application is using: " + DEFAULT_BACKEND_URL);
  backEndURL = DEFAULT_BACKEND_URL;
}

var jsonTextLocation = REACT_APP_TEXT_JSON_URL

if (jsonTextLocation == null) {
  console.log("Cannot found ENV property: REACT_APP_TEXT_JSON_URL therefore the application is using: " + DEFAULT_TEXT_JSON_URL);
  jsonTextLocation = DEFAULT_TEXT_JSON_URL;
}

function getArrayFromMap(theMap, key) {
  var value = theMap.get(key);
  var toReturn = [];
  if (Array.isArray(value)) {
    toReturn = value;
  }

  return toReturn;
}

function getStringArrayFromMap(theMap, key) {
  var value = getStringFromMap(theMap, key);
  var toReturn = [];
  if (value !== constants.EMPTY) {
    toReturn = [value];
  }

  return toReturn;
}

function getStringFromMap(theMap, key) {
  var toReturn = theMap.get(key);

  if (validationService.validateStringNotEmpty(toReturn)) {
    toReturn = toReturn.trim();
  } else {
    toReturn = constants.EMPTY;
  }

  return toReturn;
}

export function getFieldsMapFromJsonUser(jsonBackendUser) {
  const backEndUser = new BackendUser();
  backEndUser.updateFromJson(jsonBackendUser);
  return backEndUser.getMap();
}



export function login(noClient, userCode, password, handleLogin, handleError) {
  let body = {
    usernameOrEmail: constants.EMPTY,
    no_client: noClient,
    code: userCode,
    password: password
  }
  restService.asyncPost(backEndURL + constants.REST_URL_LOGIN, body, handleLogin, handleError);
}

export function sendContactUsMessage(name, email, msg, successHandler, errorHandler) {
  let body = {
    name: name,
    email: email,
    msg: msg,
  }
  restService.asyncPost(backEndURL + constants.REST_URL_CONTACT_MSG, body, successHandler, errorHandler);
}

export function sendEmailForNewsLetter(email, handleLogin, handleError) {
  let body = {
    email: email,
  }

  restService.asyncPost(backEndURL + constants.REST_URL_NEWSLETTER_ADD_EMAIL, body, handleLogin, handleError);
}


export function logout() { }

export function addUser(accessToken, mapFields, handleLogin, handleError) {
  const toAdd = new BackendUser();
  toAdd.update(mapFields);
  restService.asyncPostSecured(backEndURL + constants.REST_URL_USER_ADD, accessToken, toAdd.getUserMssql(), handleLogin, handleError);
}

export function modifyUser(accessToken, mapFields, originalJsonUser, handleLogin, handleError) {
  const toAdd = new BackendUser();
  toAdd.updateFromJson(originalJsonUser);
  toAdd.update(mapFields);
  restService.asyncPatchSecured(backEndURL + constants.REST_URL_USER_UPDATE, accessToken, toAdd.getUserMssql(), handleLogin, handleError);
}

export function deleteUser(accessToken, userId, successHandler, errorHandler) {
  restService.asyncDeleteSecured(backEndURL + constants.REST_URL_USER_DELETE + userId, accessToken, successHandler, errorHandler);
}

export function getUserList(accessToken, successHandler, errorHandler) {
  restService.asyncGetSecured(backEndURL + constants.REST_URL_USER_GETALL, accessToken, successHandler, errorHandler);
}

export function getUser(accessToken, userId, successHandler, errorHandler) {
  restService.asyncGetSecured(backEndURL + constants.REST_URL_USER_GET + userId, accessToken, successHandler, errorHandler);
}

export function loadVehiculeYears(accessToken, successHandler, errorHandler) {
  restService.asyncGetSecured(backEndURL + constants.REST_URL_CAR_YEAR, accessToken, successHandler, errorHandler, ApiCache.getInstance());
}

export function loadVehiculeBrands(accessToken, successHandler, errorHandler, data) {
  restService.asyncGetSecured(backEndURL + constants.REST_URL_CAR_BRAND + data.year, accessToken, successHandler, errorHandler, ApiCache.getInstance());
}

export function loadVehiculeModels(accessToken, successHandler, errorHandler, data) {
  restService.asyncGetSecured(backEndURL + stringService.stringParameters(constants.REST_URL_CAR_MODEL, [data.brand, data.year]), accessToken, successHandler, errorHandler, ApiCache.getInstance());
}

export function loadVehiculeGroups(accessToken, successHandler, errorHandler, data) {
  restService.asyncGetSecured(backEndURL + stringService.stringParameters(constants.REST_URL_CAR_OPTION, [data.model, data.brand, data.year]), accessToken, successHandler, errorHandler, ApiCache.getInstance());
}



export function searchTires(accessToken, successHandler, errorHandler, data) {
  var jsonToSend = {};

  if (data.get(constants.MAPPING_TIRESEARCH_SEARCHTYPE) === constants.TIRESEARCH_TYPE_SPEC) {

    var brand = getStringArrayFromMap(data, constants.MAPPING_TIRESEARCH_BRAND);
    var partners = getArrayFromMap(data, constants.MAPPING_TIRESEARCH_PARTNERS);

    var allBrands = brand.concat(partners);
    jsonToSend = {
      //private String dimensions;
      "dimensions": getStringFromMap(data, constants.MAPPING_TIRESEARCH_SIZE_FRONT),

      //private String rear_Dimensions;
      "rear_dimensions": getStringFromMap(data, constants.MAPPING_TIRESEARCH_SIZE_BACK),

      //private String product_Code;
      "product_code": getStringFromMap(data, constants.MAPPING_TIRESEARCH_PRODUCT_CODE),

      //private String[] brands;
      "brands": allBrands,

      //private String[] charges;
      //"charges": [],

      //private String[] speeds;
      //"speeds": [],

      //private String description;
      "description": getStringFromMap(data, constants.MAPPING_TIRESEARCH_DESCR),

      //private boolean summer_tires;
      "summer_tires": false, //(data.get(constants.MAPPING_TIRESEARCH_TYPE) === constants.TIRE_TYPE_SUMMER),

      //private boolean winter_tires;
      "winter_tires": (data.get(constants.MAPPING_TIRESEARCH_TYPE) === constants.TIRE_TYPE_WINTER),

      //private boolean approved_tires;
      "approved_tires": (data.get(constants.MAPPING_TIRESEARCH_TYPE) === constants.TIRE_TYPE_HOMOLOGUED),

      //private boolean four_seasons;
      "four_seasons": (data.get(constants.MAPPING_TIRESEARCH_TYPE) === constants.TIRE_TYPE_4SEASONS),

      //private boolean salesOnly;
      "sales_only": (data.get(constants.MAPPING_TIRESEARCH_DISCOUNT_ONLY) === true)

      //private String tire_wall_color;
      //"tire_wall_color": "",

      //private boolean runflat;
      //"runflat": false,

      //private boolean canBeNailed;
      //"canBeNailed": "false",

      //private String minLoad;
      //"minLoad": "",

      //private boolean original_Equipment;
      //"original_Equipment": false,

      //private int threading;
      //"threading": 0
    }
  } else if (data.get(constants.MAPPING_TIRESEARCH_SEARCHTYPE) === constants.TIRESEARCH_TYPE_VEH) {
    jsonToSend = {
      //private String dimensions;
      "dimensions": getStringFromMap(data, constants.MAPPING_TIRESEARCH_SIZE_FRONT),

      //private String rear_Dimensions;
      "rear_dimensions": "",

      //private String product_Code;
      "product_code": "",

      //private String[] brands;
      "brands": [],

      //private String[] charges;
      //"charges": [],

      //private String[] speeds;
      //"speeds": [],

      //private String description;
      "description": "",

      //private boolean summer_tires;
      "summer_tires": (data.get(constants.MAPPING_TIRESEARCH_TYPE) === constants.TIRE_TYPE_SUMMER),

      //private boolean winter_tires;
      "winter_tires": (data.get(constants.MAPPING_TIRESEARCH_TYPE) === constants.TIRE_TYPE_WINTER),

      //private boolean approved_tires;
      "approved_tires": (data.get(constants.MAPPING_TIRESEARCH_TYPE) === constants.TIRE_TYPE_HOMOLOGUED),

      //private boolean four_seasons;
      "four_seasons": (data.get(constants.MAPPING_TIRESEARCH_TYPE) === constants.TIRE_TYPE_4SEASONS),

      //private boolean salesOnly;
      "sales_only": (data.get(constants.MAPPING_TIRESEARCH_DISCOUNT_ONLY) === true)

      //private String tire_wall_color;
      //"tire_wall_color": "",

      //private boolean runflat;
      //"runflat": false,

      //private boolean canBeNailed;
      //"canBeNailed": "false",

      //private String minLoad;
      //"minLoad": "",

      //private boolean original_Equipment;
      //"original_Equipment": false,

      //private int threading;
      //"threading": 0
    }
  }

  restService.asyncPostSecured(backEndURL + constants.REST_URL_TIRE_SEARCH, accessToken, jsonToSend, successHandler, errorHandler);
}

export function loadVehiculeTireSizes(accessToken, successHandler, errorHandler, data) {
  restService.asyncGetSecured(backEndURL + stringService.stringParameters(constants.REST_URL_CAR_DIMENSION, [data.group, data.model, data.brand, data.year]), accessToken, successHandler, errorHandler, ApiCache.getInstance());
}

export function loadTireBrands(accessToken, successHandler, errorHandler) {
  restService.asyncGetSecured(backEndURL + constants.REST_URL_TIRE_ALL_BRANDS, accessToken, successHandler, errorHandler, ApiCache.getInstance());
}

export function doDimensionCompareCalc(accessToken, successHandler, errorHandler, data) {
  var chosenWidth = data.get(constants.MAPPING_CONVERTER_COMP_TIRE_WIDTH);
  var chosenThickness = data.get(constants.MAPPING_CONVERTER_COMP_TIRE_THINKNESS);
  var chosenRadius = data.get(constants.MAPPING_CONVERTER_COMP_TIRE_RADIUS);
  var chosenWidth2 = data.get(constants.MAPPING_CONVERTER_COMP_TIRE_WIDTH2);
  var chosenThickness2 = data.get(constants.MAPPING_CONVERTER_COMP_TIRE_THINKNESS2);
  var chosenRadius2 = data.get(constants.MAPPING_CONVERTER_COMP_TIRE_RADIUS2);

  var size1 = chosenWidth + "/" + chosenThickness + 'R' + chosenRadius;
  var size2 = chosenWidth2 + "/" + chosenThickness2 + 'R' + chosenRadius2;

  var url = stringService.stringParameters(constants.REST_URL_TIRE_COMPARISON_GET, [encodeURIComponent(size1), encodeURIComponent(size2)]);

  restService.asyncGetSecured(backEndURL + url, accessToken, successHandler, errorHandler);
}

export function doDimensionConversionCalc(accessToken, successHandler, errorHandler, data) {
  let body = {
    "width": Number(data.get(constants.MAPPING_CONVERTER_DIM_TIRE_WIDTH)),
    "ratio": Number(data.get(constants.MAPPING_CONVERTER_DIM_TIRE_THINKNESS)),
    "diameter": Number(data.get(constants.MAPPING_CONVERTER_DIM_TIRE_RADIUS)),
  }

  restService.asyncPostSecured(backEndURL + constants.REST_URL_TIRE_EQUIVALENCE_GET, accessToken, body, successHandler, errorHandler);
}

export function loadDefaultTireSearhType(accessToken, successHandler) {
  restService.asyncGetSecured(backEndURL + constants.REST_URL_DEFAULT_SEASON, accessToken, successHandler, doNothing, ApiCache.getInstance());
}

export function doNothing(msg) {
}

export function loadPartners(accessToken, successHandler, errorHandler) {
  restService.asyncGetSecured(backEndURL + constants.REST_URL_USER_PARTNERS, accessToken, successHandler, errorHandler, ApiCache.getInstance());
}

export function getLoggedUser(accessToken, successHandler, errorHandler) {
  restService.asyncGetSecured(backEndURL + constants.REST_URL_USER_PROFILE, accessToken, successHandler, errorHandler);
}

export function loadUITexts(handleText, handleError) {
  restService.asyncGet(jsonTextLocation, handleText, handleError);
}


