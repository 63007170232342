function SubMenuItem(props) {

  function internGoToRoute(url) {
    props.goToRoute(url);
  }

  if (props.click) {
    return (
      <li className="menu-dropdown-item tk-cursor-pointer" onClick={() => window[props.click]()}>
        <a>{props.name}</a>
      </li>
    );
  } else if (props.route) {
    return (
      <li className="menu-dropdown-item tk-cursor-pointer" onClick={() => internGoToRoute(props.route)}>
        <a>{props.name}</a>
      </li>
    );
  } else {
    return (
      <li className="menu-dropdown-item">
        <a href={props.href}>{props.name}</a>
      </li>
    );
  }
}

export default SubMenuItem;