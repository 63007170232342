import './App.css';
import * as constants from './constants';
import './components/BasePage/BasePage';
import BasePage, { ACTION_LOGOUT_LOGIN, ACTION_HOME, ACTION_JOINUS, ACTION_TIRES, ACTION_TIRES_CONVERTER, ACTION_TIRES_RESULT, ACTION_OLDSITE, ACTION_LOGIN, ACTION_LOGOUT, ACTION_MANAGEUSERS_PROFILE, ACTION_MANAGEUSERS_LIST, ACTION_MANAGEUSERS_ADDUSER, ACTION_MANAGEUSERS_EDITUSER, ACTION_404 } from './components/BasePage/BasePage';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Todo from './components/Pages/Todo/Todo';
import Fatal from './components/Pages/Fatal/Fatal';

function App(props) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={constants.ROUTE_HOME} element={<BasePage action={ACTION_HOME} />} />
        <Route path={constants.ROUTE_FATAL} element={<Fatal />} />
        <Route path={constants.ROUTE_JOINUS} element={<BasePage action={ACTION_JOINUS} />} />
        <Route path={constants.ROUTE_TIRES} element={<BasePage action={ACTION_TIRES} />} />
        <Route path={constants.ROUTE_CONVERTER} element={<BasePage action={ACTION_TIRES_CONVERTER} />} />
        <Route path={constants.ROUTE_TIRES_RESULT} element={<BasePage action={ACTION_TIRES_RESULT} />} />
        <Route path={constants.ROUTE_LOGIN} element={<BasePage action={ACTION_LOGIN} />} />
        <Route path={constants.ROUTE_LOGOUT} element={<BasePage action={ACTION_LOGOUT} />} />
        <Route path={constants.ROUTE_LOGOUT_LOGIN} element={<BasePage action={ACTION_LOGOUT_LOGIN} />} />
        <Route path={constants.ROUTE_PROFILE} element={<BasePage action={ACTION_MANAGEUSERS_PROFILE} />} />
        <Route path={constants.ROUTE_MANAGEUSERS} element={<BasePage action={ACTION_MANAGEUSERS_LIST} />} />
        <Route path={constants.ROUTE_ADDUSER} element={<BasePage action={ACTION_MANAGEUSERS_ADDUSER} />} />
        <Route path={constants.SYNTAX_ROUTE_EDITUSER} element={<BasePage action={ACTION_MANAGEUSERS_EDITUSER} />} />

        <Route path={constants.SYNTAX_ROUTE_OLDSITE_COMMANDES} element={<BasePage action={ACTION_OLDSITE} page={constants.OLD_SITE_URL_COMMANDES} />} />
        <Route path={constants.SYNTAX_ROUTE_OLDSITE_BILLS} element={<BasePage action={ACTION_OLDSITE} page={constants.OLD_SITE_URL_FACTURES} />} />
        <Route path={constants.SYNTAX_ROUTE_OLDSITE_COMPTE} element={<BasePage action={ACTION_OLDSITE} page={constants.OLD_SITE_URL_COMPTE} />} />
        <Route path={constants.SYNTAX_ROUTE_OLDSITE_ESTIME} element={<BasePage action={ACTION_OLDSITE} page={constants.OLD_SITE_URL_ESTIME} />} />
        <Route path={constants.SYNTAX_ROUTE_OLDSITE_PARAMETERS} element={<BasePage action={ACTION_OLDSITE} page={constants.OLD_SITE_URL_PARAMETERS} />} />
        <Route path={constants.SYNTAX_ROUTE_OLDSITE_PROMOTIONS} element={<BasePage action={ACTION_OLDSITE} page={constants.OLD_SITE_URL_PROMOTIONS} />} />
        <Route path={constants.SYNTAX_ROUTE_OLDSITE_REMISAGE_NEW} element={<BasePage action={ACTION_OLDSITE} page={constants.OLD_SITE_URL_REMISAGE_NEW} />} />
        <Route path={constants.SYNTAX_ROUTE_OLDSITE_REMISAGE_LIST} element={<BasePage action={ACTION_OLDSITE} page={constants.OLD_SITE_URL_REMISAGE_LIST} />} />
        <Route path={constants.SYNTAX_ROUTE_OLDSITE_REMISAGE_ETIQUETTES} element={<BasePage action={ACTION_OLDSITE} page={constants.OLD_SITE_URL_REMISAGE_PRINT} />} />
        <Route path={constants.SYNTAX_ROUTE_OLDSITE_REMISAGE_RAPPORTS} element={<BasePage action={ACTION_OLDSITE} page={constants.OLD_SITE_URL_REMISAGE_REPORTS} />} />
        <Route path={constants.SYNTAX_ROUTE_OLDSITE_REMISAGE_ONSITE_NEW} element={<BasePage action={ACTION_OLDSITE} page={constants.OLD_SITE_URL_REMISAGE_ON_SITE_NEW} />} />
        <Route path={constants.SYNTAX_ROUTE_OLDSITE_REMISAGE_ONSITE_LIST} element={<BasePage action={ACTION_OLDSITE} page={constants.OLD_SITE_URL_REMISAGE_ON_SITE_LIST} />} />
        <Route path={constants.SYNTAX_ROUTE_OLDSITE_REMISAGE_ONSITE_ETIQUETTES} element={<BasePage action={ACTION_OLDSITE} page={constants.OLD_SITE_URL_REMISAGE_ON_SITE_PRINT} />} />
        <Route path={constants.SYNTAX_ROUTE_OLDSITE_REMISAGE_ONSITE_RAPPORTS} element={<BasePage action={ACTION_OLDSITE} page={constants.OLD_SITE_URL_REMISAGE_ON_SITE_REPORTS} />} />
        <Route path={constants.ROUTE_TODO} element={<Todo />} />
        <Route path="*" element={<BasePage action={ACTION_404} />} />
      </Routes>
    </BrowserRouter>
  );

}

export default App;
