import './InfoPopup.css';

function InfoPopup(props) {
    return (
        <div className="info-popup-popup">
            <div className="info-popup-popup-box frb-bgcolor-orange frb-font-medium-large frb-color-white frb-font-light-bold tk-display-block" style={{ 'left': (props.left ? props.left : '-58px') }}>
                <div className='tk-grid info-popup-popup-detail-content frb-font-medium frb-font-no-bold'>
                    {props.msg}
                </div>
                <div className="info-popup-popup-triangle" />
            </div>
        </div>
    );
}

export default InfoPopup;