import { useState } from 'react';
import * as backEndService from '../../../services/backend/backEndService';
import * as validationService from '../../../services/validationService';
import { JSON_SECTION_S11_NEWSLETTER, loadSection, getTextByKey, getGeneralTextByKey } from '../../../services/languageService';
import * as constants from '../../../constants';

function S11NewsLetter(props) {

  function getText(keyFields) {
    return getTextByKey(theTexts, keyFields, props.onTextNotFound)
  }

  function getGeneralText(keyFields) {
    return getGeneralTextByKey(theTexts, keyFields, props.onTextNotFound)
  }

  const [email, setEmail] = useState(constants.EMPTY);

  function handleUIChangeEmail(event) {
    setEmail(event.target.value);
  }

  function sendEmail() {
    if (!validationService.validateEmail(email)) {
      props.displayErrorMessage(getGeneralText(['Messages','ERROR_INVALID_EMAIL']));
      return;
    }
    backEndService.sendEmailForNewsLetter(email, handleEmailSendSuccess, handleEmailSendFailure);
  }

  function handleEmailSendSuccess() {
    props.displaySuccessMessage(getGeneralText(['Messages','SUCCESS_REQUEST_FOR_NEWS_LETTER']));
  }

  function handleEmailSendFailure(errorCode) {
    if (errorCode === '409'){
      //409 code means that the email is already registered so for security reason, we simply tell the user
      //that the registration was successfull.
      handleEmailSendSuccess();
    }else{
      props.displayErrorMessage(getGeneralText(['Messages','ERROR_REQUEST_FOR_NEWS_LETTER_FAILED']));
    }
    
  }

  if (props.texts) {
    var theTexts = loadSection(JSON_SECTION_S11_NEWSLETTER, props.texts, props.securityInfo);

    return (
      <div className="widget-form-wrap">
        <div className="widget-form">
          <div className="widget-form-info">
            <h6 className="title">{getText('t1l1')}<br />{getText('t1l2')}</h6>
            <p>{getText('t2l1')}<br />{getText('t2l2')}</p>
          </div>
          <form className="widget-form-input">
            <input onChange={handleUIChangeEmail} type="text" name="subscribe_email" placeholder={getText('t3')} />
            <i onClick={sendEmail} className="icon-envelope tk-cursor-pointer"></i>
            <div className="widget-form-info">
              <h6 className="title"></h6>
              <p>{getText('t4')}</p>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default S11NewsLetter;
